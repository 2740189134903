
















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import off from './lifting-station-lg.svg';
import on from './lifting-station-lg-on.svg';

@Component
export default class LiftingStationDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get backgroundImage(): string {
    return this.metrics.all.onoff?.value === 1 ? on : off;
  }
}
