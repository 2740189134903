


















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { EnumProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import bgImage from './heating-lg.svg';

@Component
export default class HeatingDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private backgroundImage = bgImage;

  @EnumProp('DEFAULT', 'RED', 'BLUE')
  private readonly temperatureColor!: string;
}
