


























import DeviceStateIndicator from '@/components/sensor/device-state/DeviceStateIndicator.vue';
import DeviceStateIndicators from '@/components/sensor/device-state/DeviceStateIndicators.vue';
import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import { ContinuousMetricPoint } from '@/features/core/util/metrics';
import bgImage from './act-aqua-pipe-lg.svg';

@Component({ components: { DeviceStateIndicator, DeviceStateIndicators } })
export default class ActAquaPipeDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private backgroundImage = bgImage;
  private formatTemperature(point?: ContinuousMetricPoint): string {
    return point ? point.value.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'XX';
  }

  private isWarningTemperature(point?: ContinuousMetricPoint): boolean {
    return point !== undefined && (point.value < 55 || point.value > 63);
  }
}
