
























import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import spotQuery from '@/features/core/components/spot-metrics-graph-panel-control/spot.gql';
import { MetricDescriptorInput, MetricResolutionAggregator, MetricResolutionInput } from '@/types/iot-portal';
import { isDef } from '@/util/lang';
import { ArrayProp, DateProp, EnumProp, StringProp } from '@/util/prop-decorators';
import { DateTime } from 'luxon';
import moment from 'moment';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Debounce } from '@/util/debounce-decorator';
import { DeviceRoleAggregationMetric } from '../../model';
import { getMetricUnitName } from '../../util/metric-formatters';
import supersetAggregationQuery from '@/hsc-api/queries/SupersetMetricsQuery.gql';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import { SupersetMetricsVariables, SupersetMetrics } from '@/hsc-api/queries/__generated__/SupersetMetrics';
import HeatingSystemMetricsPanelControlTable from './HeatingSystemMetricsPanelControlTable.vue';

import {
  CoreSpotMetricsPanelControlSpotQuery,
  CoreSpotMetricsPanelControlSpotQueryVariables,
} from './__generated__/CoreSpotMetricsPanelControlSpotQuery';

import { Order, RangeInterval, MetricResolutionAggregator as HSCMetricResolutionAggregator } from '@/types/hsc-types';
import { MetricPoint } from './metric-point.entity';

type MetricPointUnion = SupersetMetrics['supersetSpotMetrics']['items'][number]['points'][number];

@Component({
  components: { HeatingSystemMetricsPanelControlTable },
  apollo: {
    spot: {
      query: spotQuery,
      fetchPolicy: 'network-only',
      skip(this: HeatingSystemMetricsPanelControlData) {
        return !this.spotId;
      },
      variables(this: HeatingSystemMetricsPanelControlData): CoreSpotMetricsPanelControlSpotQueryVariables {
        return { spotId: this.spotId };
      },
    },
    supersetSpotMetrics: {
      query: supersetAggregationQuery,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      fetchPolicy: 'no-cache',
      variables(this: HeatingSystemMetricsPanelControlData): SupersetMetricsVariables {
        const startDate = this.descriptorDates.start instanceof Date ? this.descriptorDates.start : null;
        const stopDate = this.descriptorDates.stop instanceof Date ? this.descriptorDates.stop : null;
        const apiDescriptors = [];
        for (const selectedMetricsName of this.selectedMetrics) {
          apiDescriptors.push({
            name: selectedMetricsName,
            resolution: {
              interval: this.aggregationInterval || RangeInterval.HOUR,
              aggregator: (this.aggregator || MetricResolutionAggregator.MEAN) as HSCMetricResolutionAggregator,
            },
            take: this.historySize,
            skip: this.historyPage * this.historySize,
            start: startDate,
            stop: stopDate,
            order: Order.DESC,
          });
        }
        return {
          spotId: this.spotId,
          descriptors: apiDescriptors,
        };
      },
    },
  },

  data() {
    return {
      history: undefined,
      selectedHistoryMetric: undefined,
      aggregation: undefined,
    };
  },
})
export default class HeatingSystemMetricsPanelControlData extends Mixins(DeviceRoleMapMixin) {
  private readonly spot?: CoreSpotMetricsPanelControlSpotQuery['spot'];

  @ArrayProp(() => [])
  private readonly selectedMetrics!: string[];

  @ArrayProp(() => [])
  private readonly selectableMetrics!: string[];

  @DateProp()
  private readonly startDate?: Date;

  @DateProp()
  private readonly stopDate?: Date;

  @StringProp()
  private readonly spotId!: string;

  @StringProp()
  private readonly aggregationInterval?: RangeInterval;

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  private readonly supersetSpotMetrics?: SupersetMetrics['supersetSpotMetrics'];

  private historyPage = 0;
  private historySize = 10;
  private selectedHistoryMetric?: string;
  private more = false;

  private get loading(): boolean {
    return this.$apollo.loading;
  }

  private get descriptorDates(): Pick<MetricDescriptorInput, 'start' | 'stop'> {
    const timezoneOffset = moment(this.startDate).utcOffset();

    const start = moment(this.startDate).utc().add(timezoneOffset, 'minutes').startOf('day').toDate();

    const stop = this.stopDate === undefined ? undefined : moment(this.stopDate).add(1, 'day').startOf('day').toDate();

    return { start, stop };
  }

  private get metrics(): MetricPointUnion[] {
    return this.supersetSpotMetrics?.items.flatMap(({ points }) => points) || [];
  }

  private get totalMetrics(): number {
    return this.supersetSpotMetrics?.items.length || 0;
  }

  private get importantMetricRows(): MetricPoint[] {
    const latestPoints = this.getLatestMetricPoint();

    return this.metricNames
      .filter((name) => name !== 'outsideTemperature')
      .map((name) => {
        const value = latestPoints.get(name)?.value;
        const latestTimestamp = latestPoints.get(name)?.time;
        const timestamp = latestTimestamp ? new Date(latestTimestamp) : undefined;
        return {
          name,
          value,
          timestamp,
        };
      });
  }

  private getLatestMetricPoint(): Map<string, MetricPointUnion> {
    const latestPoints = new Map<string, MetricPointUnion>();

    this.supersetSpotMetrics?.items.forEach(({ name, points }) => {
      if (points.length > 0) {
        latestPoints.set(name, points[0]);
      }
    });

    return latestPoints;
  }

  private get moreMetricRows(): MetricPointUnion[] {
    return this.metrics;
  }

  private get metricNames(): string[] {
    return this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [];
  }

  private get importantMetricNames(): string[] {
    return this.deviceRoleMap[this.spot?.first.role ?? '']?.importantMetricNames ?? [];
  }

  private get aggregationMetricSet(): Set<string> {
    return new Set(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [],
    );

    return new Set();
  }

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get aggregationResolution(): MetricResolutionInput | undefined {
    const metric = this.aggregationMetricMap.get(this.selectedHistoryMetric ?? '');
    if (metric === undefined) {
      return undefined;
    }

    return {
      intervalLength: this.aggregationInterval ?? RangeInterval.MONTH,
      timeSource: metric.timeSource,
      aggregator: this.aggregator,
    };
  }

  private get defaultMetricNames(): string[] {
    const defaultMetricNames = this.deviceRoleMap[this.spot?.first.role ?? '']?.defaultMetricNames ?? [];

    const filteredDefaultMetricNames = defaultMetricNames.filter((defaultMetricName) =>
      this.metricNames.includes(defaultMetricName),
    );

    return this.selectableMetrics.length === 0
      ? filteredDefaultMetricNames
      : filteredDefaultMetricNames.filter((metricName) => this.selectableMetrics.includes(metricName));
  }

  @Watch('metricNames')
  @Debounce(500)
  private updateMetricNames(): void {
    if (this.defaultMetricNames.length !== 0) {
      this.$emit('update:selected-metrics', this.defaultMetricNames);
    }
  }

  private hasMetricAggregators(metricName: string): boolean {
    return (this.aggregationMetricMap.get(metricName)?.aggregators ?? []).length > 0;
  }

  private isImportantMetric(name: string): boolean {
    return this.importantMetricNames.includes(name) || this.importantMetricNames.includes(name.replace(/\d+$/, ''));
  }

  private onHistoryClick(name: string): void {
    this.selectedHistoryMetric = this.selectedHistoryMetric !== name ? name : undefined;
    this.historyPage = 0;
  }

  private selectMetrics(event: boolean, name: string): void {
    const selectedMetrics = this.selectedMetrics;
    if (this.selectedMetrics.length !== 0 && getMetricUnitName(this.selectedMetrics[0]) !== getMetricUnitName(name)) {
      selectedMetrics.length = 0;
    }
    if (event) {
      selectedMetrics.push(name);
    }
    if (!event) {
      selectedMetrics.splice(selectedMetrics.indexOf(name), 1);
    }

    this.$emit('update:selected-metrics', selectedMetrics);
  }

  private filter1thAnd16th(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
    const firstOfMonth = metricPoints.find(({ time }) => {
      return DateTime.fromJSDate(time).hasSame(DateTime.fromJSDate(time).startOf('month'), 'day');
    });

    const sixteenthOfMonth = metricPoints.find(({ time }) => {
      const date = DateTime.fromJSDate(time);

      return DateTime.fromJSDate(time).hasSame(DateTime.utc(date.year, date.month, 16), 'day');
    });

    if (firstOfMonth === undefined && sixteenthOfMonth === undefined) {
      return [];
    }

    return [firstOfMonth, sixteenthOfMonth].filter(isDef);
  }
}
