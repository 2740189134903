





















import { EditTreeNodeInput } from '@/types/iot-portal';
import { RequiredProp } from '@/util/prop-decorators';
import { isNonEmptyString } from '@/util/string';
import { Component, Vue } from 'vue-property-decorator';
import editTreeNode from './edit-tree-node.gql';
import { SidebarTreeNode } from './model';
import {
  AppSmartDeviceTreeNodeBarEditTreeNodeMutation,
  AppSmartDeviceTreeNodeBarEditTreeNodeMutationVariables,
} from './__generated__/AppSmartDeviceTreeNodeBarEditTreeNodeMutation';

type TreeNode = AppSmartDeviceTreeNodeBarEditTreeNodeMutation['editTreeNode']['treeNode'];
type FormData = Pick<EditTreeNodeInput, 'name'>;

@Component
export default class MountAufzugheldenDeviceWizardControl extends Vue {
  @RequiredProp()
  private readonly treeNode!: SidebarTreeNode;

  private readonly isNonEmptyString = isNonEmptyString;

  private get title(): string {
    return `Tree Node "${this.treeNode.name}" bearbeiten`;
  }

  private isValidData(data: FormData): boolean {
    return isNonEmptyString(data.name);
  }

  private async editTreeNode(input: FormData): Promise<TreeNode> {
    const { id } = this.treeNode;
    const { data } = await this.$apollo.mutate<
      AppSmartDeviceTreeNodeBarEditTreeNodeMutation,
      AppSmartDeviceTreeNodeBarEditTreeNodeMutationVariables
    >({
      mutation: editTreeNode,
      variables: { input: { ...input, id } },
    });

    if (!data) {
      throw new Error('Der Tree node konnte nicht bearbeiten werden!');
    }

    return data.editTreeNode.treeNode;
  }

  private async onSubmitted(treeNode: TreeNode, hide: () => Promise<void>): Promise<void> {
    this.$emit('tree-node-edited', { ...this.treeNode, name: treeNode.name });

    await hide();
  }
}
