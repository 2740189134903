import { GroupRole, GroupRolePermission } from '@/types/iot-portal';
import { Component, Vue } from 'vue-property-decorator';
import query from './groups.gql';
import { GroupsMixinQuery } from './__generated__/GroupsMixinQuery';

@Component({
  apollo: {
    me: {
      query,
    },
  },
  data() {
    return { me: undefined };
  },
})
export default class GroupsMixin extends Vue {
  private me?: GroupsMixinQuery['me'];

  protected appRoles = GroupRolePermission;

  protected get groups(): GroupsMixinQuery['me']['groups']['items'] | undefined {
    return this.me?.groups.items;
  }

  protected hasApp(role: GroupRole): boolean {
    return this.groups?.some(({ roles }) => roles.includes(role)) ?? false;
  }

  protected hasAppRole(permission: GroupRolePermission): boolean {
    return this.groups?.some(({ permissions }) => permissions.includes(permission)) ?? false;
  }

  protected get loading(): boolean {
    return this.$apollo.queries.me.loading;
  }
}
