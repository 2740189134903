import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-customer.svg?vue';

export const APP_CUSTOMER = GroupRole.APP_CUSTOMER;

export default option(APPS, async () => {
  return {
    name: APP_CUSTOMER,
    label: 'Customer',
    link: {
      to: { name: 'AppCustomer' },
      icon,
    },
    hiddenForApps: [APP_EED],
    order: 8,
  };
});
