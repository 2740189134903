import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './temperature-lg.svg';
import md from './temperature-md.svg';
import sm from './temperature-sm.svg';
import component from './TemperatureDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.TEMPERATURE,
  label: 'Single Temperatursensor',
  shortLabel: 'Temperatur',
  icons: { sm, md, lg },
  component,
  props: { temperatureColor: 'RED' },
  defaultMetricNames: ['temperature', 'outsideTemperature'],
  importantMetricNames: ['temperature', 'outsideTemperature'],
  aggregationMetricNames: [
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'outsideTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
