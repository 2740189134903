













import { ArrayProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { CorePropertyMapPanelPropertyFragment } from './__generated__/CorePropertyMapPanelPropertyFragment';
import PropertyMarker from './property-marker.svg?vue';

@Component({ components: { PropertyMarker } })
export default class PropertyMapPanel extends Vue {
  @ArrayProp(() => [])
  private properties!: CorePropertyMapPanelPropertyFragment;
}
