


















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { DiscreteMetricPoint } from '@/features/core/util/metrics';
import { Component, Mixins } from 'vue-property-decorator';
import lgImage from './occupancy-detector-lg.svg';
import lgImageOccupied from './occupancy-detector-lg-occupied.svg';
import lgImageVacant from './occupancy-detector-lg-vacant.svg';
import OccupancyIndicator from './OccupancyIndicator.vue';

@Component({ components: { OccupancyIndicator } })
export default class OccupancyDetectorDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get occupancy(): DiscreteMetricPoint | undefined {
    return this.metrics.discrete.occupancy;
  }

  private get backgroundImage(): string {
    return this.occupancy ? (this.occupancy.value === '1' ? lgImageOccupied : lgImageVacant) : lgImage;
  }
}
