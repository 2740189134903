import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './section-meter-electricity-meter-analog-lg.svg';
import md from './section-meter-electricity-meter-analog-md.svg';
import sm from './section-meter-electricity-meter-analog-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.SECTION_METER_ELECTRICITY_METER_ANALOG,
  label: 'Zwischenzähler_Stromzähler (analog)',
  shortLabel: 'Zs_Strom(A)',
  icons: { sm, md, lg },
  component: component('section-meter-electricity-meter-analog/SectionMeterElectricityMeterAnalogDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component(
      'section-meter-electricity-meter-analog/SectionMeterElectricityMeterAnalogAggregationWidget.vue',
    ),
  },
  defaultMetricNames: ['currentEnergy'],
  importantMetricNames: ['currentEnergy'],
  consumption: true,
}));
