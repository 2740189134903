import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './heating-lg.svg';
import md from './heating-md.svg';
import sm from './heating-sm.svg';
import component from './HeatingDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.HEATING,
  label: 'Heizungssensor',
  shortLabel: 'Heizung',
  icons: { sm, md, lg },
  component,
  props: { temperatureColor: 'RED' },
  defaultMetricNames: ['temperature'],
  importantMetricNames: ['temperature', 'batteryLow'],
}));
