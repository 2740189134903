import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './wm-bus-repeater-lg.svg';
import md from './wm-bus-repeater-md.svg';
import sm from './wm-bus-repeater-sm.svg';
import component from './WMBusRepeaterDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.WM_BUS_REPEATER,
  label: 'WM-Bus Repeater',
  shortLabel: 'WM-Bus',
  icons: { sm, md, lg },
  component,
}));
