import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole, TreeNodeType } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-heating-system.svg?vue';

export const APP_HEATING_SYSTEM = GroupRole.APP_HEATING_SYSTEM;
const types = [TreeNodeType.RootDirectory, TreeNodeType.Directory, TreeNodeType.PropertyGroup, TreeNodeType.Property];

export default option(APPS, async () => {
  return {
    name: APP_HEATING_SYSTEM,
    label: 'Heizungssysteme',
    link: {
      to: { name: 'AppHeatingSystem' },
      icon,
    },
    types,
    generateTreeNodeLocation({ __typename, id }, params) {
      return types.includes(TreeNodeType[__typename])
        ? { name: 'AppHeatingSystem/MainView', params: { ...params, treeNodeId: id } }
        : undefined;
    },
    getActiveTreeNodeId({ params }) {
      return params.treeNodeId;
    },
    hiddenForApps: [APP_EED],
    order: 6,
  };
});
