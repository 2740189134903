

















import { TreeNodeNameTreeNode } from '@/features/domain-ui/tree-node-name/model';
import { BooleanProp, ObjectProp } from '@/util/prop-decorators';
import { last } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { App } from '../../model';
import { RootGetter } from '../../store';
import TreeNodeLocationGeneratorMixin from '../mixins/tree-node-location-generator';
import { CoreTreeNodeContentHeaderFragment } from './__generated__/CoreTreeNodeContentHeaderFragment';

interface PathItem {
  treeNode: TreeNodeNameTreeNode;
  route?: RawLocation;
}

@Component
export default class TreeNodeContentHeader extends Mixins(TreeNodeLocationGeneratorMixin) {
  @ObjectProp(true)
  protected readonly treeNode!: CoreTreeNodeContentHeaderFragment;

  @BooleanProp()
  private treeNodeInBreadcrumb!: boolean;

  @RootGetter
  protected readonly app?: App = undefined;

  private get backLinkLocation(): RawLocation | undefined {
    return last(this.path)?.route ?? this.app?.link.to;
  }

  private get path(): PathItem[] {
    const path: PathItem[] = [];

    for (const treeNode of this.treeNode.path.items) {
      path.push({ treeNode, route: this.generateTreeNodeLocation(treeNode) });
    }

    if (this.treeNodeInBreadcrumb) {
      path.push({ treeNode: this.treeNode, route: this.generateTreeNodeLocation(this.treeNode) });
    }

    return path;
  }

  private onBackClick(): void {
    if (this.$listeners.back) {
      this.$emit('back');
      return;
    }
    if (this.backLinkLocation) {
      this.$router.push(this.backLinkLocation);
    }
  }
}
