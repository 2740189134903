import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole, TreeNodeType } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-smoke-detector.svg?vue';

export const APP_SMOKE_DETECTOR = GroupRole.APP_SMOKE_DETECTOR;

export default option(APPS, async () => {
  return {
    name: APP_SMOKE_DETECTOR,
    label: 'Rauchwarnmelder',
    activatableApp: true,
    link: {
      to: { name: 'AppSmokeDetector' },
      icon,
    },
    types: [TreeNodeType.RootDirectory, TreeNodeType.Directory, TreeNodeType.PropertyGroup, TreeNodeType.Property],
    generateTreeNodeLocation({ __typename, id }, params) {
      return __typename === TreeNodeType.RootDirectory || __typename === TreeNodeType.Directory
        ? undefined
        : { name: 'AppSmokeDetector/TreeNode', params: { ...params, treeNodeId: id } };
    },
    getActiveTreeNodeId({ params }) {
      return params.treeNodeId;
    },
    hiddenForApps: [APP_EED],
    order: 7,
  };
});
