








































import { ChartSet, ChartStyle, CHART_STYLE_OPTIONS, LineChartType } from '@/components/charts/model';
import { AufzugheldenMetricNames } from '@/features/device-roles/aufzughelden-device/constants';
import { MetricDescriptorInput, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { isDef } from '@/util/lang';
import { ArrayProp, DateProp, EnumProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import { difference, groupBy, partition } from 'lodash';
import { DateTime } from 'luxon';
import moment from 'moment';
import { AsyncComponent, Component as ComponentInterface } from 'vue';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { DeviceRoleAggregationMetric } from '../../model';
import { APOLLO_CLIENT } from '@/features/core/container/model';
import { formatMetricName, formatMetricValue, isBooleanMetric } from '../../util/metric-formatters';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import spotQuery from '@/features/core/components/spot-metrics-graph-panel-control/spot.gql';
import supersetAggregationQuery from '@/hsc-api/queries/SupersetMetricsQuery.gql';

import {
  SupersetMetricsVariables,
  SupersetMetrics,
  SupersetMetrics_supersetSpotMetrics_items,
  SupersetMetrics_supersetSpotMetrics_items_points,
} from '@/hsc-api/queries/__generated__/SupersetMetrics';
import { Order, MetricResolutionAggregator, RangeInterval, MetricResolutionInput } from '@/types/hsc-types';

import aggregationQuery from '@/features/core/components/spot-metrics-graph-panel-control/aggregation.gql';
import {
  CoreSpotMetricsGraphPanelControlAggregationQuery,
  CoreSpotMetricsGraphPanelControlAggregationQueryVariables,
  CoreSpotMetricsGraphPanelControlAggregationQuery_aggregation_first_metrics,
} from '../spot-metrics-graph-panel-control/__generated__/CoreSpotMetricsGraphPanelControlAggregationQuery';
import {
  CoreSpotMetricsGraphPanelControlSpotQuery,
  CoreSpotMetricsGraphPanelControlSpotQueryVariables,
  CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics,
  CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_ContinuousMetric_points,
  CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_DiscreteMetric_points,
} from '../spot-metrics-graph-panel-control/__generated__/CoreSpotMetricsGraphPanelControlSpotQuery';
import { normalizeMetricPoint } from '../../util/metrics';
import {
  PropertySpotQuery,
  PropertySpotQuery_propertySpot_first_children_first_PropertySpot,
} from '@/features/domain-ui/heating-system-metrics-graph/__generated__/PropertySpotQuery';

type ContinuousMetricPoint = CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_ContinuousMetric_points;
type DiscreteMetricPoint = CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics_DiscreteMetric_points;
type SupersetMetricPoint = SupersetMetrics_supersetSpotMetrics_items_points;
type MetricPointUnion = ContinuousMetricPoint | DiscreteMetricPoint | SupersetMetricPoint;
type PropertySpot = PropertySpotQuery['propertySpot'];

type MergedMetricsUnion =
  | CoreSpotMetricsGraphPanelControlSpotQuery_spot_first_metrics
  | CoreSpotMetricsGraphPanelControlAggregationQuery_aggregation_first_metrics
  | SupersetMetrics_supersetSpotMetrics_items;

@Component({
  apollo: {
    spot: {
      query: spotQuery,
      pollInterval: 10000,
      skip(this: HeatingSystemMetricsGraphPanelControl): boolean {
        return this.sideEffectedMetricNames.length == 0 || !this.spotId;
      },
      variables(this: HeatingSystemMetricsGraphPanelControl): CoreSpotMetricsGraphPanelControlSpotQueryVariables {
        return {
          spotId: this.spotId,
          descriptors: this.sideEffectedMetricNames.map((metricName) => ({
            name: metricName,
            ...this.descriptorDates,
          })),
        };
      },
    },
    supersetSpotMetrics: {
      query: supersetAggregationQuery,
      client: APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT,
      fetchPolicy: 'no-cache',
      pollInterval: 10000,
      skip(this: HeatingSystemMetricsGraphPanelControl): boolean {
        return this.aggregationMetricMap.size == 0;
      },
      variables(this: HeatingSystemMetricsGraphPanelControl): SupersetMetricsVariables {
        return {
          spotId: this.spotId,
          descriptors: Array.from(this.aggregationResolutionMap.entries())
            .filter(([name]) => this.metricNames.includes(name))
            .map(([name, resolution]) => ({
              name,
              resolution: {
                interval: resolution?.interval || RangeInterval.MINUTE,
                aggregator: (resolution?.aggregator || MetricResolutionAggregator.MEAN) as MetricResolutionAggregator,
              },
              take: 100_000,
              skip: 0,
              start: this.startDate,
              stop: this.stopDate,
              order: Order.ASC,
            })),
        };
      },
    },
    aggregation: {
      query: aggregationQuery,
      fetchPolicy: 'no-cache',
      pollInterval: 60000,
      variables(
        this: HeatingSystemMetricsGraphPanelControl,
      ): CoreSpotMetricsGraphPanelControlAggregationQueryVariables {
        return {
          spotId:
            (
              this.propertySpot?.first.children
                .first as PropertySpotQuery_propertySpot_first_children_first_PropertySpot
            ).id ?? '',
          descriptors: [
            {
              name: 'temperature',
              resolution: {
                timeSource: MetricResolutionTimeSource.START,
                intervalLength: this.influxResolutionInput,
                aggregator: MetricResolutionAggregator.MEAN as MetricResolutionAggregator,
              },
              take: 100_000,
              skip: 0,
              start: this.startDate,
              stop: this.stopDate,
              order: Order.ASC,
            },
          ],
        };
      },
    },
  },
  data() {
    return {
      spot: undefined,
      supersetAggregation: undefined,
      chartStyle: undefined,
      aggregation: undefined,
      LineChartType,
    };
  },
})
export default class HeatingSystemMetricsGraphPanelControl extends Mixins(DeviceRoleMapMixin) {
  @StringProp(true)
  private readonly spotId!: string;

  @ObjectProp(true)
  private readonly propertySpot!: PropertySpot;

  @ArrayProp(() => [])
  private readonly metricNames!: string[];

  @ArrayProp(() => [])
  private readonly availableOnGraphMetrics!: string[];

  @DateProp()
  private readonly startDate?: Date;

  @DateProp()
  private readonly stopDate?: Date;

  @EnumProp(false, ...Object.keys(RangeInterval))
  private readonly aggregationInterval?: RangeInterval;

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  private readonly spot?: CoreSpotMetricsGraphPanelControlSpotQuery['spot'];
  private readonly supersetSpotMetrics?: SupersetMetrics['supersetSpotMetrics'];

  private chartStyle!: ChartStyle;
  private LineChartType!: LineChartType;
  private mergedMetrics: MergedMetricsUnion[] = [];

  private readonly aggregation?: CoreSpotMetricsGraphPanelControlAggregationQuery['aggregation'];
  private get graphWidget(): AsyncComponent | ComponentInterface | undefined {
    if (!this.spot) {
      return undefined;
    }
    const graphWidget = this.deviceRoleMap[this.spot.first.role].graphWidget;

    if (graphWidget === undefined || !graphWidget.enabled) {
      return undefined;
    }

    return graphWidget.component;
  }

  private get influxResolutionInput(): string {
    if (this.aggregationInterval) {
      switch (this.aggregationInterval) {
        case RangeInterval.DAY:
          return 'P1D';
        case RangeInterval.MONTH:
          return 'P1M';
        case RangeInterval.WEEK:
          return 'P1W';
        case RangeInterval.HOUR:
          return 'PT1H';
        default:
          return 'PT1M';
      }
    } else {
      return 'PT1H';
    }
  }

  private get graphWidgetProps(): Record<string, unknown> | undefined {
    if (!this.spot) {
      return undefined;
    }

    return this.deviceRoleMap[this.spot.first.role].graphWidget?.props ?? {};
  }

  private get descriptorDates(): Pick<MetricDescriptorInput, 'start' | 'stop'> {
    const timezoneOffset = moment(this.startDate).utcOffset();

    const start =
      this.startDate === undefined
        ? undefined
        : moment(this.startDate).utc().add(timezoneOffset, 'minutes').startOf('day').toDate();
    const stop = this.stopDate === undefined ? undefined : moment(this.stopDate).add(1, 'day').startOf('day').toDate();

    return { start, stop };
  }

  private get aggregationMetricSet(): Set<string> {
    return new Set(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [],
    );
  }

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get aggregationResolutionMap(): Map<string, MetricResolutionInput | undefined> {
    return new Map(
      Array.from(this.aggregationMetricMap.entries())
        .filter((entry) => (this.aggregator ? entry[1].aggregators.includes(this.aggregator) : true))
        .map(([aggregationMetricName, metric]: [string, DeviceRoleAggregationMetric]) => {
          if (metric === undefined) {
            return [aggregationMetricName, undefined];
          }

          return [
            aggregationMetricName,
            {
              interval: this.aggregationInterval ?? RangeInterval.HOUR,
              aggregator: this.aggregator ?? MetricResolutionAggregator.MEAN,
            },
          ];
        }),
    );
  }
  private get metricData(): ChartSet[] {
    const aggregatedMetrics = this.supersetSpotMetrics?.items ?? [];
    const outsideTemperatureMetrics = this.aggregation?.first.metrics ?? [];
    const combinedMetrics = [...aggregatedMetrics, ...outsideTemperatureMetrics];
    if (combinedMetrics.length === 0) {
      return [];
    }

    if (outsideTemperatureMetrics.length === 0 && aggregatedMetrics.length === 0) {
      return [];
    }

    const sets = combinedMetrics.map((metric) => {
      const points = this.getGdprMetricPoints(metric.points)
        .map(normalizeMetricPoint)
        .map(({ time, value }) => {
          let x = time;
          if (
            metric.name === 'outsideTemperature' &&
            this.aggregationInterval !== RangeInterval.HOUR &&
            this.influxResolutionInput !== 'PT1H'
          ) {
            if (typeof time === 'string') {
              const dateObject = DateTime.fromISO(time, { zone: 'utc' }).set({
                hour: 0,
                minute: 0,
                second: 0,
              });
              x = dateObject.toISO();
            }
          }
          return {
            x,
            y: Number(value).toFixed(2),
          };
        });
      return {
        label: this.metricLabel(metric),
        name: metric.name,
        color: metric.name === 'outsideTemperature' ? '#3b3939' : 'color' in metric ? metric?.color : undefined,
        points,
      };
    });

    return sets;
  }

  private metricLabel(metric: MergedMetricsUnion): string {
    const label = this.spot?.first.metricConfigurations.find(({ name }) => name === metric.name)?.label;
    if (label) {
      return label;
    }
    if (this.aggregation?.first?.role === SpotRole.OUTSIDE_TEMPERATURE && metric.name === 'temperature') {
      return 'Außentemperatur';
    }
    return formatMetricName(metric.name);
  }

  private yAxisLabelFormatter(value: string | number, index: number): string {
    if (this.chartStyle === ChartStyle.STEP && this.metricNames.some(isBooleanMetric)) {
      return value === '0' ? 'no/off' : 'yes/on';
    }
    return this.metricNames.length === 0 ? `${value}` : `${this.formatValue(value, this.metricNames[0]) ?? value}`;
  }

  private formatValue(value: string | number, name: string): string {
    // Any metric with temperature in it will have Celsius unit
    if (name.toLowerCase().includes('temperature')) {
      return formatMetricValue('temperature', value);
    }
    return formatMetricValue(this.metricNames.find((v) => v === name) ?? '', value);
  }

  private chartStyleOptions = CHART_STYLE_OPTIONS;

  @Watch('metricNames')
  private updateSelectedMetrics(): void {
    this.metricNamesSideEffect();
  }

  @Watch('aggregationInterval')
  private onAggregationIntervalUpdate(): void {
    if (this.supersetSpotMetrics) {
      this.supersetSpotMetrics.items = [];
    }
    if (this.aggregation) {
      this.aggregation.first.metrics = [];
    }
  }

  private get sideEffectedMetricNames(): string[] {
    if (this.metricNames.includes(AufzugheldenMetricNames.DoorCycles)) {
      return difference(
        [...this.metricNames, AufzugheldenMetricNames.DoorOpenings, AufzugheldenMetricNames.DoorClosings],
        Array.from(this.aggregationMetricSet),
      );
    }
    return difference(this.metricNames, Array.from(this.aggregationMetricSet));
  }

  private metricNamesSideEffect(): void {
    if (
      this.spot?.first.role === SpotRole.AUFZUGHELDEN_DEVICE &&
      this.metricNames.includes(AufzugheldenMetricNames.DoorCycles)
    ) {
      this.chartStyle = ChartStyle.BAR;
    }

    for (const metricName of this.metricNames) {
      if (isBooleanMetric(metricName)) {
        this.chartStyle = ChartStyle.STEP;
        this.$emit('update:selected-metrics', this.metricNames.filter(isBooleanMetric));
        return;
      }
    }

    this.chartStyle = ChartStyle.LINE;
  }

  private getGdprMetricPoints(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
    if (
      ![SpotRole.HEAT_COST_ALLOCATOR, SpotRole.HEAT_METER_COUNTER, SpotRole.WATER_METER_HOT].includes(
        this.spot?.first.role ?? SpotRole.UNKNOWN,
      )
    ) {
      return metricPoints;
    }
    const now = DateTime.utc();

    const [newerThan2Weeks, olderThan2Weeks] = partition(
      metricPoints,
      ({ time }) => DateTime.fromISO(time.toString()).plus({ weeks: 2 }) >= now,
    );

    const pointsGroupedByYearMonth = groupBy(
      olderThan2Weeks,
      ({ time }) => `${DateTime.fromISO(time.toString()).year}-${DateTime.fromISO(time.toString()).month}`,
    );

    const filteredPointsOlderThan2Weeks = Object.values(pointsGroupedByYearMonth).flatMap((points) =>
      this.filter1thAnd16th(points),
    );

    const gdprPoints = [...newerThan2Weeks, ...filteredPointsOlderThan2Weeks].sort(
      (a, b) => new Date(a.time).getTime() - new Date(b.time).getTime(),
    );

    return gdprPoints;
  }

  private filter1thAnd16th(metricPoints: MetricPointUnion[]): MetricPointUnion[] {
    const firstOfMonth = metricPoints.find(({ time }) => {
      return DateTime.fromISO(time.toString()).hasSame(DateTime.fromISO(time.toString()).startOf('month'), 'day');
    });

    const sixteenthOfMonth = metricPoints.find(({ time }) => {
      const date = DateTime.fromISO(time.toString());

      return DateTime.fromISO(time.toString()).hasSame(DateTime.utc(date.year, date.month, 16), 'day');
    });

    if (firstOfMonth === undefined && sixteenthOfMonth === undefined) {
      return [];
    }

    return [firstOfMonth, sixteenthOfMonth].filter(isDef);
  }

  private get yAxisDimensions(): Array<string | number> {
    if (this.chartStyle === ChartStyle.STEP) {
      return ['0', '1'];
    }

    return [];
  }

  private mounted(): void {
    this.metricNamesSideEffect();
  }
}
