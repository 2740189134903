import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './viessmann-heating-system-lg.svg';
import md from './viessmann-heating-system-md.svg';
import sm from './viessmann-heating-system-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.VIESSMANN_HEATING_SYSTEM,
  label: 'Viessmann Heizungssystem',
  shortLabel: 'Heizung',
  icons: { sm, md, lg },
  component: component('viessmann-heating-system/ViessmannHeatingSystemSpotWidget.vue'),
  defaultMetricNames: ['boilerTemperature'],
  importantMetricNames: [
    'boilerTemperature',
    'circuitSupplyTemperature',
    'outsideTemperature',
    'dhwHotWaterStorageTemperature',
  ],
  aggregationMetricNames: [
    {
      name: 'boilerTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'circuitSupplyTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'outsideTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'dhwHotWaterStorageTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
