






import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppBanner extends Vue {
  @StringProp()
  private readonly message!: string;
}
