










import { Component, Vue } from 'vue-property-decorator';
import { News } from '../../util/fetch-news';
import { ObjectProp } from '@/util/prop-decorators';

@Component
export default class NewsItem extends Vue {
  @ObjectProp()
  private readonly news!: News;

  private open(): void {
    window.open(this.news.link, '_blank');
  }
}
