





































import historyIcon from '@/assets/images/history.png';
import { ConnectionStatus, BatteryStatus } from '@/components/sensor/prop-decorators';
import SensorAction from '@/components/sensor/SensorAction.vue';
import SensorProperty from '@/components/sensor/SensorProperty.vue';
import { StringProp, BooleanProp } from '@/util/prop-decorators';
import { FetchResult } from 'apollo-link';
import { Component, Mixins } from 'vue-property-decorator';
import { RootGetter } from '../../store';
import { Metrics } from '../../util/metrics';
import { DeviceRole } from '../../model';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import {
  CoreSpotWidgetControlMetricsSubscription,
  CoreSpotWidgetControlMetricsSubscriptionVariables,
} from './__generated__/CoreSpotWidgetControlMetricsSubscription';
import {
  CoreSpotWidgetControlQuery,
  CoreSpotWidgetControlQueryVariables,
} from './__generated__/CoreSpotWidgetControlQuery';
import {
  CoreSpotWidgetControlRemoveSpotMutation,
  CoreSpotWidgetControlRemoveSpotMutationVariables,
} from './__generated__/CoreSpotWidgetControlRemoveSpotMutation';
import {
  CoreSpotWidgetControlUnmountDeviceMutation,
  CoreSpotWidgetControlUnmountDeviceMutationVariables,
} from './__generated__/CoreSpotWidgetControlUnmountDeviceMutation';
import metricsSubscription from './metrics.gql';
import spotQuery from './spot.gql';
import removeSpotMutation from './remove-spot.gql';
import unmountDeviceMutation from './unmount-device.gql';
import qrIcon from './qr.svg';
import unmountIcon from './unmount.svg';
import removeIcon from './remove.svg';

@Component({
  components: { SensorAction, SensorProperty },
  apollo: {
    spot: {
      query: spotQuery,
      variables(this: SpotWidgetControl): CoreSpotWidgetControlQueryVariables {
        return { spotId: this.spotId };
      },
    },
    $subscribe: {
      metrics: {
        query: metricsSubscription,
        variables(this: SpotWidgetControl): CoreSpotWidgetControlMetricsSubscriptionVariables {
          return { spotId: this.spotId };
        },
        result(this: SpotWidgetControl, { data }: FetchResult<CoreSpotWidgetControlMetricsSubscription>) {
          if (data) {
            this.metrics.pushLatest(data.point);
          }
        },
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
      } as any, // FIXME apollo-client outdated typings
    },
  },
  data() {
    return { spot: undefined, metrics: new Metrics() };
  },
})
export default class SpotWidgetControl extends Mixins(DeviceRoleMapMixin) {
  @BooleanProp()
  private readonly hideDetailsAction!: boolean;

  @StringProp(true)
  private readonly spotId!: string;

  @RootGetter
  private readonly admin!: boolean;

  @RootGetter
  private readonly technician!: boolean;

  private readonly spot!: CoreSpotWidgetControlQuery['spot'];

  private metrics!: Metrics;

  private readonly historyIcon = historyIcon;
  private readonly unmountIcon = unmountIcon;
  private readonly removeIcon = removeIcon;
  private readonly qrIcon = qrIcon;

  private get role(): DeviceRole | undefined {
    return this.spot ? this.deviceRoleMap[this.spot.first.role] : undefined;
  }

  private get connectionStateIndicator(): ConnectionStatus {
    return 'NONE';
  }

  private get batteryStateIndicator(): BatteryStatus {
    return 'NONE';
  }

  private async onUnmountClick(): Promise<void> {
    const mount = this.spot.first.activeDeviceMount;
    if (!mount) {
      return;
    }

    if (!window.confirm('Möchten sie dieses Gerät wirklich entfernen?')) {
      return;
    }

    await this.$apollo.mutate<
      CoreSpotWidgetControlUnmountDeviceMutation,
      CoreSpotWidgetControlUnmountDeviceMutationVariables
    >({
      mutation: unmountDeviceMutation,
      variables: { input: { id: mount.id } },
    });

    this.$apollo.queries.spot.refetch();

    this.$emit('unmounted', mount);
  }

  private async onRemoveClick(): Promise<void> {
    if (
      !window.confirm(
        'Dieses Widget wirklich entfernen? Bisher gesammelte Daten für zugeordnete Geräte gehen verloren!',
      )
    ) {
      return;
    }

    await this.$apollo.mutate<
      CoreSpotWidgetControlRemoveSpotMutation,
      CoreSpotWidgetControlRemoveSpotMutationVariables
    >({
      mutation: removeSpotMutation,
      variables: { input: { id: this.spotId } },
    });

    this.$emit('removed');
  }
}
