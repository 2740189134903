import {
  EnterpriseProjectObjectProduct,
  EnterpriseProjectObjectUseCase,
  NewProjectData,
  ProjectBuilding,
} from './enterpriseProjectCreateModule';
import {
  DatabaseProjectDraft,
  ProjectDraftMainBuilding,
  ProjectDraftBuilding,
  ProjectDraftCompleted,
} from '@/features/core/store/page-modules/enterprise-project-create/create-module-types';

export function cleanLatLong(val: number): number {
  const strVal = val.toString();
  return Number(strVal.replace(',', ''));
}

export function sortProjectDraftBuildings(buildings: ProjectBuilding[]): ProjectBuilding[] {
  // Helper function to extract number from a string
  // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
  const extractNumber = (str: string) => parseInt(/\d+/.exec(str)?.[0] || '0', 10);
  return buildings.sort((a, b) => {
    // Split the building name into parts
    const [streetA, houseNumberA, addressSupplementA] = a.name.split(' ');
    const [streetB, houseNumberB, addressSupplementB] = b.name.split(' ');

    // First, compare by street
    const streetComparison = streetA.localeCompare(streetB);
    if (streetComparison !== 0) return streetComparison;

    // Then, compare by house number
    const numA = extractNumber(houseNumberA);
    const numB = extractNumber(houseNumberB);
    if (numA !== numB) return numA - numB;

    // Finally, compare by addressSupplement
    return (addressSupplementA || '').localeCompare(addressSupplementB || '');
  });
}

// Convert the useCase monetary values to numbers
export function convertUseCaseMonetaryValues(useCases: ProjectBuilding['useCases']): ProjectBuilding['useCases'] {
  if (!useCases) return [];
  return useCases.map((useCase) => {
    return {
      ...useCase,
      monthlyPriceInEuros: useCase.monthlyPriceInEuros ? parseFloat(useCase.monthlyPriceInEuros.toString()) : null,
      oneTimePriceInEuros: useCase.oneTimePriceInEuros ? parseFloat(useCase.oneTimePriceInEuros.toString()) : null,
    };
  });
}

export function transformStateForSaving(object: NewProjectData): DatabaseProjectDraft | null {
  const mainBuildings: ProjectDraftMainBuilding[] = [];
  const buildings: ProjectDraftBuilding[] = [];

  // First we create the main buildings (only properties)
  for (let i = 0; i < object.buildings.length; i++) {
    const building = object.buildings[i];
    if (building.liegenshaft) {
      // Convert the useCase monetary values to numbers
      building.useCases = convertUseCaseMonetaryValues(building.useCases);
      const mainBuilding: ProjectDraftMainBuilding = {
        id: building.buildingId,
        name: building.name,
        cityAddress: building.cityAddress,
        houseAddress: building.houseAddress,
        houseAddressSuffix: building.houseAddressSuffix,
        streetAddress: building.streetAddress,
        postCodeAddress: building.postCodeAddress,
        locationLongitude: cleanLatLong(building.locationLongitude),
        locationLatitude: cleanLatLong(building.locationLatitude),
        numberOfApartments: building.numberOfApartments,
        customerContact: building.liegenshaftInformation,
        mainService: building.mainService,
        products: building.products ?? [],
        useCases: building.useCases ?? [],
      };
      mainBuildings.push(mainBuilding);
    }
  }

  // Then we create the buildings (only buildings)
  for (let i = 0; i < object.buildings.length; i++) {
    const building = object.buildings[i];
    if (!building.liegenshaft) {
      const newBuilding: ProjectDraftBuilding = {
        id: building.buildingId,
        mainBuildingId: building.parentProperty ? building.parentProperty : null,
        name: building.name,
        cityAddress: building.cityAddress,
        houseAddress: building.houseAddress,
        houseAddressSuffix: building.houseAddressSuffix,
        streetAddress: building.streetAddress,
        postCodeAddress: building.postCodeAddress,
        locationLongitude: cleanLatLong(building.locationLongitude),
        locationLatitude: cleanLatLong(building.locationLatitude),
        numberOfApartments: building.numberOfApartments,
      };
      buildings.push(newBuilding);
    }
  }

  if (object.id && object.projectReference) {
    return {
      id: object.id,
      projectReference: object.projectReference,
      general: object.general,
      customerInfo: {
        customerName: object.general.customerName,
        customerNumber: object.general.customerNumber,
        contactPerson: object.general.partnerName,
      },
      mainBuildings,
      buildings,
    };
  }

  return null;
}

export function transformDbObjectForState(
  object: DatabaseProjectDraft & ProjectDraftCompleted,
): NewProjectData & ProjectDraftCompleted {
  let buildings: ProjectBuilding[] = [];

  for (let i = 0; i < object.buildings.length; i++) {
    buildings.push({
      id: object.buildings[i].id,
      buildingId: object.buildings[i].id,
      name: object.buildings[i].name,
      cityAddress: object.buildings[i].cityAddress,
      houseAddress: object.buildings[i].houseAddress,
      houseAddressSuffix: object.buildings[i].houseAddressSuffix,
      streetAddress: object.buildings[i].streetAddress,
      postCodeAddress: object.buildings[i].postCodeAddress,
      locationLongitude: object.buildings[i].locationLongitude,
      locationLatitude: object.buildings[i].locationLatitude,
      numberOfApartments: object.buildings[i].numberOfApartments,
      liegenshaft: false,
      liegenshaftInformation: undefined,
      parentProperty: object.buildings[i].mainBuildingId,
    });
  }

  for (let i = 0; i < object.mainBuildings.length; i++) {
    buildings.push({
      id: object.mainBuildings[i].id,
      buildingId: object.mainBuildings[i].id,
      name: object.mainBuildings[i].name,
      cityAddress: object.mainBuildings[i].cityAddress,
      houseAddress: object.mainBuildings[i].houseAddress,
      houseAddressSuffix: object.mainBuildings[i].houseAddressSuffix,
      streetAddress: object.mainBuildings[i].streetAddress,
      postCodeAddress: object.mainBuildings[i].postCodeAddress,
      locationLongitude: object.mainBuildings[i].locationLongitude,
      locationLatitude: object.mainBuildings[i].locationLatitude,
      numberOfApartments: object.mainBuildings[i].numberOfApartments,
      liegenshaft: true,
      liegenshaftInformation: object.mainBuildings[i].customerContact,
      mainService: object.mainBuildings[i].mainService,
      products: object.mainBuildings[i].products || [
        {
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          productType: EnterpriseProjectObjectProduct.INFRASTRUCTURE_VARIANT,
          option: null,
        },
        {
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          productType: EnterpriseProjectObjectProduct.IW_CLOUD,
          option: null,
        },
      ],
      useCases: object.mainBuildings[i].useCases || [
        {
          label: 'Heizung',
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: null,
        },
        {
          label: 'Aufzug',
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: null,
        },
        {
          label: 'Digitalisierung TGA',
          checked: false,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.TGA_DIGITIZATION,
        },
        {
          label: 'TGA Monitoring',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.TGA_MONITORING,
        },
        {
          label: 'TGA Integration',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.TGA_INTEGRATION,
        },
        {
          label: 'Zählerfernauslesung (ZFA)',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.REMOTE_METER_READING,
        },
        {
          label: 'Wettbewerbliche Messstellenbetrieb (SMG)',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.SMG,
        },
        {
          label: 'Dienstleistungsmanagement',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.SERVICE_MANAGEMENT,
        },
        {
          label: 'Verbrauchsdatenerfassung (VDE)',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.CONSUMPTION_DATA_ACQUISITION,
        },
        {
          label: 'Unterjährige Verbrauchsinformation (UVI)',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.CONSUMPTION_INFORMATION_DURING_THE_YEAR,
        },
        {
          label: 'Rauchwarnmeldermonitoring',
          checked: false,
          monthlyPriceInEuros: null,
          oneTimePriceInEuros: null,
          useCaseType: EnterpriseProjectObjectUseCase.SMOKE_ALARM_MONITORING,
        },
      ],
    });
  }

  // Sort buildings by street, house number, and addressSupplement
  buildings = sortProjectDraftBuildings(buildings);

  return {
    id: object.id,
    projectReference: object.projectReference,
    general: object.general,
    customerInfo: object.customerInfo,
    buildings,
    generalInformationCompleted: object.generalInformationCompleted ? object.generalInformationCompleted : false,
    buildingsImportCompleted: object.buildingsImportCompleted ? object.buildingsImportCompleted : false,
    propertyInformationCompleted: object.propertyInformationCompleted ? object.propertyInformationCompleted : false,
    propertyAssociationCompleted: object.propertyAssociationCompleted ? object.propertyAssociationCompleted : false,
  };
}

// Helper function to compare arrays
function arraysEqual(a: any[], b: any[]): boolean {
  if (a.length !== b.length) return false;
  for (let i = 0; i < a.length; i++) {
    if (!deepEqual(a[i], b[i])) {
      return false;
    }
  }
  return true;
}

// Helper function to handle deep equality check
function deepEqual(a: any, b: any): boolean {
  if (a === b) return true;

  // Check if both are arrays
  if (Array.isArray(a) && Array.isArray(b)) {
    return arraysEqual(a, b); // Compare arrays
  }

  // Check if both are objects
  if (typeof a === 'object' && typeof b === 'object' && a !== null && b !== null) {
    return !hasObjectChanged(a, b); // Recursively compare objects
  }

  return false; // Primitive values or type mismatch
}

// Main function to compare objects
export function hasObjectChanged<T>(original: T, updated: Partial<T>): boolean {
  // Compare all keys in the original object
  for (const key in original) {
    if (Object.prototype.hasOwnProperty.call(original, key)) {
      if (!deepEqual(original[key], updated[key])) {
        return true; // Change detected
      }
    }
  }

  // Compare all keys in the updated object
  for (const key in updated) {
    if (Object.prototype.hasOwnProperty.call(updated, key)) {
      if (!deepEqual(original[key], updated[key])) {
        return true; // Change detected
      }
    }
  }

  return false; // No differences found
}
