import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './monoxid-sensor-lg.svg';
import md from './monoxid-sensor-md.svg';
import sm from './monoxid-sensor-sm.svg';
import component from './MonoxidSensorDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.MONOXID_SENSOR,
  label: 'Kohlenmonoxid',
  shortLabel: 'Kohlenmonoxid',
  icons: { sm, md, lg },
  component,
}));
