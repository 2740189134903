import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './quad-heating-lg.svg';
import md from './quad-heating-md.svg';
import sm from './quad-heating-sm.svg';
import component from './QuadHeatingDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.QUAD_HEATING,
  label: 'Quad Heizungssensor',
  shortLabel: 'Heizung',
  icons: { sm, md, lg },
  defaultMetricNames: ['temperature1'],
  component,
}));
