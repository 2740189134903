import { Component, Mixins } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { APP_MAP } from '../../container';
import { App } from '../../model';
import { CoreTreeNodeLocationGeneratorFragment } from '../../router/__generated__/CoreTreeNodeLocationGeneratorFragment';
import { RootGetter } from '../../store';
import ContainerMixin from './container';

@Component
export default class TreeNodeLocationGeneratorMixin extends Mixins(ContainerMixin) {
  @RootGetter
  protected readonly app?: App;

  protected get appMap(): Record<string, App> {
    return this.container(APP_MAP);
  }

  protected generateTreeNodeLocation(
    node: CoreTreeNodeLocationGeneratorFragment,
    params?: Record<string, unknown>,
  ): RawLocation | undefined {
    return this.doGenerate(this.app, node, params);
  }

  protected generateAppTreeNodeLocation(
    appName: string,
    node: CoreTreeNodeLocationGeneratorFragment,
    params?: Record<string, unknown>,
  ): RawLocation | undefined {
    return this.doGenerate(this.appMap[appName], node, params);
  }

  protected pushTreeNodeLocation(node: CoreTreeNodeLocationGeneratorFragment, params?: Record<string, unknown>): void {
    this.doPush(this.app, node, params);
  }

  protected pushAppTreeNodeLocation(
    appName: string,
    node: CoreTreeNodeLocationGeneratorFragment,
    params?: Record<string, unknown>,
  ): void {
    this.doPush(this.appMap[appName], node, params);
  }

  private doGenerate(
    app: App | undefined,
    node: CoreTreeNodeLocationGeneratorFragment,
    params?: Record<string, unknown>,
  ): RawLocation | undefined {
    return app?.generateTreeNodeLocation?.(node, params) ?? undefined;
  }

  private doPush(
    app: App | undefined,
    node: CoreTreeNodeLocationGeneratorFragment,
    params?: Record<string, unknown>,
  ): void {
    const location = this.doGenerate(app, node, params);

    if (location === undefined) {
      return;
    }

    void this.$router.push(location);
  }
}
