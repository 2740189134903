import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_CUSTOMER } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/customer/:customerId/vendor-apis/ivm-notification-api/:vendorApiId',
      component: component('views/notification-api/NotificationApiView.vue'),
      props: true,
      meta: { app: APP_CUSTOMER, key: (): string => `AppCustomer/VendorApis/IvmNotificationApi` },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/ivm-notification-api/:vendorApiId',
          name: 'AppCustomer/VendorApis/IvmNotificationApi',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/IvmNotificationApi/Settings', params }),
        },
        {
          path: 'settings',
          name: 'AppCustomer/VendorApis/IvmNotificationApi/Settings',
          component: component('views/notification-api/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
  ];
});
