import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './light-switch-lg.svg';
import md from './light-switch-md.svg';
import sm from './light-switch-sm.svg';
import component from './LightSwitchDevicePanelControl.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.LIGHT_SWITCH,
  label: 'Lichtschalter',
  shortLabel: 'Licht',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['onoff'],
  importantMetricNames: ['onoff', 'online'],
}));
