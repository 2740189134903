












































import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import InputSelect from '@/features/ui/inputs/InputSelect.global.vue';
import { INPUT_DATE_PICKER_MODE_META, Option } from '@/features/ui/inputs/model';
import { MetricResolutionAggregator } from '@/types/iot-portal';
import { DeviceRoleAggregationMetric } from '../../model';
import { DateProp, EnumProp, StringProp } from '@/util/prop-decorators';
import moment, { Moment } from 'moment';
import { intersection, union } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import spotQuery from './spot.gql';
import {
  CoreSpotMetricsPanelControlSpotQuery,
  CoreSpotMetricsPanelControlSpotQueryVariables,
} from './__generated__/CoreSpotMetricsPanelControlSpotQuery';

const getAggregatorLabel: (aggregator: MetricResolutionAggregator) => string = (aggregator) => {
  switch (aggregator) {
    case MetricResolutionAggregator.MIN:
      return 'Minimum';
    case MetricResolutionAggregator.MAX:
      return 'Maximum';
    case MetricResolutionAggregator.MEAN:
      return 'Durchschnitt';
    case MetricResolutionAggregator.SUM:
      return 'Summe';
    default:
      return '';
  }
};

@Component({
  apollo: {
    spot: {
      query: spotQuery,
      variables(this: SpotMetricsPanelControl): CoreSpotMetricsPanelControlSpotQueryVariables {
        return { spotId: this.spotId };
      },
    },
  },
})
export default class SpotMetricsPanelControl extends Mixins(DeviceRoleMapMixin) {
  @DateProp()
  private readonly startDate?: Date;

  @DateProp()
  private readonly stopDate?: Date;

  @StringProp()
  private readonly aggregationInterval?: Duration;

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  @StringProp()
  private readonly spotId!: string;

  public $refs!: { aggregationIntervalRef: InputSelect };

  private readonly spot?: CoreSpotMetricsPanelControlSpotQuery['spot'];

  private readonly aggregationIntervalOptions: Option[] = [
    { label: 'Monatlich', value: 'P1M' },
    { label: 'Wöchentlich', value: 'P1W' },
    { label: 'Täglich', value: 'P1D' },
    { label: 'Stündlich', value: 'PT1H' },
  ];

  private get inputCellBasis(): number {
    return window.innerWidth < 1920 ? 0.5 : 0.25;
  }

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get possibleMetricAggregators(): MetricResolutionAggregator[] {
    return intersection<MetricResolutionAggregator>(
      union<MetricResolutionAggregator>(
        ...Array.from(this.aggregationMetricMap.values()).map(({ aggregators }) => aggregators),
      ),
      [
        MetricResolutionAggregator.MIN,
        MetricResolutionAggregator.MAX,
        MetricResolutionAggregator.MEAN,
        MetricResolutionAggregator.SUM,
      ],
    );
  }

  private get metricAggregatorOptions(): Option<MetricResolutionAggregator>[] {
    return this.possibleMetricAggregators.map((aggregator) => ({
      value: aggregator,
      label: getAggregatorLabel(aggregator),
    }));
  }

  private get datePickerStartAttrs(): JSONObject {
    const attrs: JSONObject = {};

    const mode =
      this.aggregationInterval === 'P1M' ? INPUT_DATE_PICKER_MODE_META.MONTH : INPUT_DATE_PICKER_MODE_META.DAY;

    attrs.disabledDates = {
      from:
        this.stopDate && this.aggregationInterval !== 'P1W'
          ? moment(this.stopDate).subtract(1, mode.duration).toDate()
          : this.now().toDate(),
      to: this.spot?.first?.creationDate
        ? moment(this.spot?.first?.creationDate).subtract(1, 'day').toDate()
        : undefined,
    };

    // enable only month view
    if (this.aggregationInterval === 'P1M') {
      attrs.minimumView = mode.duration;
      attrs.maximumView = mode.duration;
    }

    return attrs;
  }
  private get datePickerStopAttrs(): JSONObject {
    const attrs: JSONObject = {};
    const mode =
      this.aggregationInterval === 'P1M' ? INPUT_DATE_PICKER_MODE_META.MONTH : INPUT_DATE_PICKER_MODE_META.DAY;

    const datePickerStartDate = this.startDate ? moment(this.startDate).toDate() : undefined;

    attrs.disabledDates = {
      from: this.now().add(1, mode.duration).toDate(),
      to:
        this.aggregationInterval === 'P1M'
          ? moment(datePickerStartDate).add(1, mode.duration).toDate()
          : datePickerStartDate,
    };

    // enable only month view
    if (mode !== INPUT_DATE_PICKER_MODE_META.DAY) {
      attrs.minimumView = mode.duration;
      attrs.maximumView = mode.duration;
    }

    return attrs;
  }

  private onUpdateStartDate(date: Date): void {
    if (this.aggregationInterval === 'P1W') {
      date = moment(date).startOf('isoWeek').toDate();
      this.onUpdateStopDate(moment(date).endOf('isoWeek').toDate());
    }
    this.$emit('update:start-date', date);
  }

  private onUpdateStopDate(date: Date | undefined): void {
    let updatedDate = moment().toDate();
    if (date) {
      updatedDate = moment(date)
        .endOf(this.aggregationInterval === 'P1M' ? 'month' : 'day')
        .toDate();
    }

    this.$emit('update:stop-date', updatedDate);
  }

  private now(): Moment {
    return moment();
  }

  private updateAggregator(aggregator: MetricResolutionAggregator): void {
    this.$emit('update:aggregator', aggregator === null ? undefined : aggregator);

    if (aggregator !== undefined && aggregator !== null) {
      this.$nextTick(() => {
        this.$refs.aggregationIntervalRef.focus();
      });
    }
  }

  private updateAggregationInterval(interval: Duration): void {
    this.onUpdateStopDate(undefined);
    this.$emit('update:aggregation-interval', interval === null ? undefined : interval);
  }
}
