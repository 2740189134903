import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_CUSTOMER } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/customer/:customerId/vendor-apis/ivm/:vendorApiId',
      component: component('views/ivm-api/IvmApiView.vue'),
      props: true,
      meta: { app: APP_CUSTOMER, key: (): string => `AppCustomer/VendorApis/Ivm` },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/ivm/:vendorApiId',
          name: 'AppCustomer/VendorApis/IvmApi',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/IvmApi/Settings', params }),
        },
        {
          path: 'settings',
          name: 'AppCustomer/VendorApis/IvmApi/Settings',
          component: component('views/ivm-api/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
  ];
});
