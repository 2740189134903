import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './pump-lg.svg';
import md from './pump-md.svg';
import sm from './pump-sm.svg';
import component from './PumpDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.PUMP,
  label: 'Pumpe',
  shortLabel: 'Pumpe',
  icons: { sm, md, lg },
  component,
}));
