/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * The supported alert types
 */
export enum AlertType {
  EMERGENCY = "EMERGENCY",
  NOOP = "NOOP",
  STANDARD = "STANDARD",
}

/**
 * The supported callout time day
 */
export enum CalloutTimeDay {
  FRIDAY = "FRIDAY",
  HOLIDAY = "HOLIDAY",
  MONDAY = "MONDAY",
  SATURDAY = "SATURDAY",
  SUNDAY = "SUNDAY",
  THURSDAY = "THURSDAY",
  TUESDAY = "TUESDAY",
  WEDNESDAY = "WEDNESDAY",
}

/**
 * The supported comparison value
 */
export enum ComparisonValue {
  EQUAL = "EQUAL",
  GREATER = "GREATER",
  LOWER = "LOWER",
}

export enum MetricResolutionAggregator {
  MAX = "MAX",
  MEAN = "MEAN",
  MIN = "MIN",
  SUM = "SUM",
}

export enum Order {
  ASC = "ASC",
  DESC = "DESC",
}

export enum RangeInterval {
  DAY = "DAY",
  HOUR = "HOUR",
  MILLI_SECOND = "MILLI_SECOND",
  MINUTE = "MINUTE",
  MONTH = "MONTH",
  SECOND = "SECOND",
  WEEK = "WEEK",
  YEAR = "YEAR",
}

/**
 * status of the ticket
 */
export enum TicketStatus {
  CLOSED = "CLOSED",
  OPENED = "OPENED",
}

export interface AlertHeatingSystemRulesInput {
  customerId: string;
  outsideTemperatureConfiguration?: OutsideTemperatureInput | null;
  heatingPeriodConfiguration?: HeatingPeriodInput | null;
  releaseDurationConfiguration?: ReleaseDurationInput | null;
  calloutTimeConfiguration?: CalloutTimeInput[] | null;
}

export interface CalloutTimeInput {
  isActive?: boolean | null;
  day?: CalloutTimeDay | null;
  from?: string | null;
  to?: string | null;
}

export interface CreateAlertCustomerDefaultRulesInput {
  customerId: string;
  componentType: string;
  systemComponentTypeName: string;
}

export interface CreateAlertDefaultMeasurementRulesInput {
  measurementType?: string | null;
  metricName: string;
  metricLabel: string;
  measurementPointsCount?: string | null;
  comparisonValue: ComparisonValue;
  toCompareValue: number;
  alertType: AlertType;
}

export interface CreateVirtualAlertDefaultMeasurementRulesInput {
  alertCustomerDefaultRules: CreateAlertCustomerDefaultRulesInput;
  alertDefaultMeasurementRulesInput: CreateAlertDefaultMeasurementRulesInput[];
}

export interface HeatingPeriodInput {
  isActive?: boolean | null;
  from?: string | null;
  to?: string | null;
}

/**
 * The following fields only affect the `Metric.points` field:
 * `resolution`, `order`, `skip`, `take`.
 */
export interface MetricDescriptorInput {
  skip: number;
  take: number;
  name?: string | null;
  reference?: string | null;
  start?: DateTime | null;
  stop?: DateTime | null;
  resolution?: MetricResolutionInput | null;
  order: Order;
}

export interface MetricResolutionInput {
  interval?: RangeInterval | null;
  aggregator?: MetricResolutionAggregator | null;
}

export interface OutsideTemperatureInput {
  isActive?: boolean | null;
  threshold?: number | null;
}

export interface ReleaseDurationInput {
  isActive?: boolean | null;
  value?: number | null;
}

export interface UpdateAlertDefaultMeasurementRulesInput {
  id: string;
  alertCustomerDefaultRulesId: string;
  measurementType?: string | null;
  metricName: string;
  metricLabel: string;
  measurementPointsCount?: string | null;
  comparisonValue?: ComparisonValue | null;
  toCompareValue?: number | null;
  alertType?: AlertType | null;
}

export interface UpdateAlertMeasurementRulesInput {
  id?: string | null;
  heatingSystemMeasurementId: string;
  comparisonValue?: ComparisonValue | null;
  toCompareValue?: number | null;
  alertType?: AlertType | null;
}

export interface UpdateAlertServiceProviderRulesInput {
  id?: string | null;
  heatingSystemId: string;
  companyName?: string | null;
  contactPerson?: string | null;
  mainProviderEmail?: string | null;
  emergencyProviderName?: string | null;
  emergencyProviderEmail?: string | null;
  isEmergencyActive?: boolean | null;
  technicianName?: string | null;
  technicianEmail?: string | null;
  isTechnicianContact?: boolean | null;
  messageShouldBeSent?: boolean | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
