import { DeviceRole, TreeNodeIdFilter } from '@/features/core/model';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { CoreSpotRoleAggregationWidgetAdapterFragment } from './__generated__/CoreSpotRoleAggregationWidgetAdapterFragment';

@Component
export class SpotRoleAggregationWidgetAdapterPropsMixin extends Vue {
  @ObjectProp(true)
  protected readonly aggregation!: CoreSpotRoleAggregationWidgetAdapterFragment;

  @ObjectProp(true)
  protected readonly role!: DeviceRole;

  @ObjectProp(() => ({}))
  protected readonly idFilter!: TreeNodeIdFilter;

  protected get widgetProps(): Record<string, unknown> {
    const { aggregation, role } = this;

    return { aggregation, role };
  }
}
