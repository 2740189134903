
































import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { EnumProp, BooleanProp } from '@/util/prop-decorators';
import { ToggleButton } from 'vue-js-toggle-button';
import { Component, Mixins } from 'vue-property-decorator';
import { TargetState } from './util';
import lampOnImage from './light-switch-on-lg.svg';
import lampOffImage from './light-switch-off-lg.svg';

@Component({
  components: { ToggleButton },
  model: { prop: 'targetState', event: 'update:target-state' },
})
export default class LampSensorPanel extends Mixins(SpotWidgetDataMixin) {
  @EnumProp(true, 'ON', 'OFF')
  private readonly targetState!: TargetState;

  @BooleanProp()
  private readonly switchDisabled!: boolean;

  private get state(): TargetState | undefined {
    const onoff = this.metrics.discrete.onoff;

    return onoff ? (onoff.value === '1' ? 'ON' : 'OFF') : undefined;
  }

  private get backgroundImage(): string {
    if (this.state === 'ON') {
      return lampOnImage;
    }

    return lampOffImage;
  }
}
