













































import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import arrowUp from './arrow-up.svg';
import arrowDown from './arrow-down.svg';
import weightNormal from './weight-normal.svg';
import weightWarning from './weight-warning.svg';

@Component({
  data() {
    return {
      weightNormal,
      weightWarning,
    };
  },
})
export default class KoneLiftDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private getMovingDirectionImage(carB: boolean): string | null {
    const metric = carB ? this.metrics.all.carBMovingDirection : this.metrics.all.carAMovingDirection;
    if (!metric) {
      return null;
    }
    switch (metric.value) {
      case 'up':
        return arrowUp;
      case 'down':
        return arrowDown;
      default:
        return null;
    }
  }
}
