import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './zigbee-repeater-lg.svg';
import md from './zigbee-repeater-md.svg';
import sm from './zigbee-repeater-sm.svg';
import component from './ZigbeeRepeaterDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.ZIGBEE_REPEATER,
  label: 'Zigbee Verstärker',
  shortLabel: 'Verstärker',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['online'],
  importantMetricNames: ['online'],
}));
