














import { floorShortLabel } from '@/features/domain-ui/property-floor/label';
import { Input } from '@/features/ui/inputs/model';
import InputTreeSelect from '@/features/ui/inputs/tree-select/InputTreeSelect.global.vue';
import { TreeModel } from '@/features/ui/inputs/tree-select/model';
import { TreeNodeType } from '@/types/iot-portal';
import { ArrayProp, BooleanProp, FunctionProp } from '@/util/prop-decorators';
import { isArray, xor } from 'lodash';
import { Component, Model, Vue, Watch } from 'vue-property-decorator';
import pathQuery from './path.gql';
import rootsQuery from './roots.gql';
import {
  CoreInputTreeNodeSelectControlPathQuery,
  CoreInputTreeNodeSelectControlPathQueryVariables,
} from './__generated__/CoreInputTreeNodeSelectControlPathQuery';
import {
  CoreInputTreeNodeSelectControlRootsQuery,
  CoreInputTreeNodeSelectControlRootsQueryVariables,
} from './__generated__/CoreInputTreeNodeSelectControlRootsQuery';
import { CoreInputTreeNodeSelectControlTreeNodeFragment } from './__generated__/CoreInputTreeNodeSelectControlTreeNodeFragment';

type TreeNode = CoreInputTreeNodeSelectControlTreeNodeFragment;
type AddableTreeNode = TreeNode & { children?: { items: AddableTreeNode[] } };

function levelOf(treeNode: TreeNode): number {
  return treeNode.__typename === 'PropertySubdivision' ? treeNode.floor.level : 0;
}

@Component({
  apollo: {
    roots: {
      query: rootsQuery,
      variables(this: InputTreeNodeSelectControl): CoreInputTreeNodeSelectControlRootsQueryVariables {
        return { ids: this.rootIds, roots: this.rootIds === undefined, types: this.types };
      },
    },
  },
  inheritAttrs: false,
  data() {
    return { inputRef: undefined, treeNodes: {}, treeNodeLoaded: {}, treeNodeChildren: {}, treeNodeParents: {} };
  },
})
export default class InputTreeNodeSelectControl extends Vue implements Input, TreeModel<TreeNode> {
  @Model('update')
  private readonly value?: TreeNode[] | null;

  @BooleanProp()
  private readonly implicit!: boolean;

  @BooleanProp()
  private readonly openSelected!: boolean;

  @BooleanProp()
  private readonly openRoots!: boolean;

  @ArrayProp()
  private readonly rootIds?: string[];

  @ArrayProp()
  private readonly types?: TreeNodeType[];

  @ArrayProp()
  private readonly selectableTypes?: TreeNodeType[];

  @FunctionProp(() => true)
  private readonly filter!: (...args: unknown[]) => boolean;

  private readonly roots?: CoreInputTreeNodeSelectControlRootsQuery['roots'];

  private treeNodes!: Record<string, TreeNode>;
  private treeNodeLoaded!: Record<string, boolean>;
  private treeNodeParents!: Record<string, string>;
  private treeNodeChildren!: Record<string, string[]>;

  public readonly $refs!: { input: InputTreeSelect<TreeNode> };

  private inputRef?: InputTreeSelect<TreeNode>;

  private get self(): this {
    return this;
  }

  private get model(): TreeNode[] {
    if (!isArray(this.value)) {
      return [];
    }

    const treeNodes = this.value.map((treeNode) => this.addTreeNode(treeNode));

    for (const treeNode of treeNodes) {
      this.loadNodePath(treeNode);
    }

    return treeNodes;
  }

  private set model(value: TreeNode[]) {
    this.$emit('update', value);
  }

  public get normalizedValue(): TreeNode[] {
    return this.inputRef?.normalizedValue ?? [];
  }

  public get empty(): boolean {
    return this.inputRef?.empty ?? true;
  }

  public get pristine(): boolean {
    return this.inputRef?.pristine ?? true;
  }

  public get rootNodes(): TreeNode[] {
    return this.roots?.items.map((treeNode) => this.addTreeNode(treeNode)).filter(this.filter) ?? [];
  }

  private mounted(): void {
    this.inputRef = this.$refs.input;
  }

  private updated(): void {
    this.inputRef = this.$refs.input;
  }

  public isNodeSelectable({ __typename }: TreeNode): boolean {
    return this.selectableTypes?.includes(TreeNodeType[__typename]) ?? true;
  }

  public idOf({ id }: TreeNode): string {
    return id;
  }

  public childrenOf({ id }: TreeNode): TreeNode[] {
    return this.treeNodeChildren[id]?.map((childId) => this.treeNodes[childId]).filter(this.filter) ?? [];
  }

  public parentOf({ id }: TreeNode): TreeNode | undefined {
    const parentId = this.treeNodeParents[id];
    if (!parentId) return undefined;
    return this.treeNodes[parentId];
  }

  public labelOf(treeNode: TreeNode): string {
    return treeNode.__typename === 'PropertySubdivision'
      ? `${treeNode.name} (${floorShortLabel(treeNode.floor)})`
      : treeNode.name;
  }

  public compare(a: TreeNode, b: TreeNode): number {
    return levelOf(b) - levelOf(a) || a.order - b.order || a.name.localeCompare(b.name);
  }

  public async loadNodePath({ id }: TreeNode): Promise<void> {
    if (id === undefined || this.treeNodeLoaded[id]) {
      return;
    }

    const { data } = await this.$apollo.query<
      CoreInputTreeNodeSelectControlPathQuery,
      CoreInputTreeNodeSelectControlPathQueryVariables
    >({ query: pathQuery, variables: { id, types: this.types } });

    for (const treeNode of data.treeNodes.items) {
      this.$set(this.treeNodeLoaded, id, true);
      this.addTreeNode(treeNode);
    }
  }

  private addTreeNode(newTreeNode: AddableTreeNode): TreeNode {
    const { id } = newTreeNode;
    const treeNode = this.treeNodes[id] ?? this.$set(this.treeNodes, id, newTreeNode);
    Object.assign(treeNode, newTreeNode);

    if (newTreeNode.children !== undefined) {
      const childIds: string[] = [];
      for (const child of newTreeNode.children.items) {
        this.$set(this.treeNodeParents, child.id, id);
        childIds.push(child.id);
        this.addTreeNode(child);
      }
      this.$set(this.treeNodeChildren, id, childIds);
    }

    return treeNode;
  }

  @Watch('rootIds')
  @Watch('types')
  private resetTree(newArray: unknown[], oldArray: unknown[]): void {
    if (xor(newArray, oldArray).length === 0) {
      return; // sets didnt change
    }

    this.treeNodes = {};
    this.treeNodeLoaded = {};
    this.treeNodeChildren = {};
    this.inputRef?.resetTree();

    const treeNodes = this.normalizedValue.map((treeNode) => this.addTreeNode(treeNode));
    for (const treeNode of treeNodes) {
      this.loadNodePath(treeNode);
    }
  }
}
