import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './smoke-detector-lg.svg';
import md from './smoke-detector-md.svg';
import sm from './smoke-detector-sm.svg';
import component from './SmokeDetectorDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.SMOKE_DETECTOR,
  label: 'Rauchmelder',
  shortLabel: 'Rauchmelder',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['batteryLow'],
  importantMetricNames: ['alarm', 'batteryLow', 'lastAlarm'],
  aggregationMetricNames: [
    {
      name: 'alarm',
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
