









import { Component, Vue } from 'vue-property-decorator';
import { RequiredProp } from '@/util/prop-decorators';

@Component
export default class NodeMenu extends Vue {
  @RequiredProp()
  private node!: unknown;

  @RequiredProp()
  private items!: string[];

  private dropdown = false;

  private onClickOutside(): void {
    if (this.dropdown) {
      this.dropdown = false;
    }
  }
}
