import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './temperature-humidity-lg.svg';
import md from './temperature-humidity-md.svg';
import sm from './temperature-humidity-sm.svg';
import component from './TemperatureHumidityDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.TEMPERATURE_HUMIDITY,
  label: 'Hygrometer',
  shortLabel: 'Hygrometer',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['temperature'],
  importantMetricNames: ['temperature', 'humidity', 'online'],
  aggregationMetricNames: [
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'humidity',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
