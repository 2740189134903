



























import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { ContinuousMetricPoint } from '@/features/core/util/metrics';
import { Component, Mixins } from 'vue-property-decorator';
import bgImage from './air-quality-lg.svg';

@Component
export default class AirQualityDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private backgroundImage = bgImage;
  private formatTemperatureHumidity(point?: ContinuousMetricPoint): string {
    return point ? point.value.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'XX';
  }
}
