import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import component from '../temperature/TemperatureDevicePanel.vue';
import lg from './supply-flow-lg.svg';
import md from './supply-flow-md.svg';
import sm from './supply-flow-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.THERMOCOUPLES_SUPPLY_FLOW,
  label: 'Vorlauftemperatur',
  shortLabel: 'Vorlauf',
  icons: { sm, md, lg },
  component,
  props: { temperatureColor: 'RED' },
  defaultMetricNames: ['temperature'],
  importantMetricNames: ['temperature', 'batteryLow'],
  aggregationMetricNames: [
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
