import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS, ROUTER } from '@/features/core/container';
import { GroupRole, TreeNodeType } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-export.svg?vue';

export const APP_EXPORT = GroupRole.APP_EXPORT;

export default option(APPS, async (get) => {
  const router = await get(ROUTER);
  return {
    name: APP_EXPORT,
    label: 'Übersicht & Export Verbrauchs- und Gerätedaten',
    link: {
      to: { name: 'AppExport' },
      icon,
    },
    types: [TreeNodeType.RootDirectory, TreeNodeType.Directory, TreeNodeType.PropertyGroup, TreeNodeType.Property],
    generateTreeNodeLocation({ id }, params) {
      const [name, query] = router.currentRoute.name?.startsWith('AppExport/TreeNode')
        ? [router.currentRoute.name, router.currentRoute.query]
        : ['AppExport/TreeNode/Spots', {}];

      return { name, params: { ...params, treeNodeId: id }, query };
    },
    getActiveTreeNodeId({ params }) {
      return params.treeNodeId;
    },
    hiddenForApps: [APP_EED],
    order: 10,
  };
});
