









import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import UiClickable from '@/components/clickables/Clickable.global.vue';

@Component({ components: { UiClickable }, inheritAttrs: false })
export default class SensorAction extends Vue {
  @StringProp(true)
  public icon!: string;
}
