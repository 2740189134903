
















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import bg from './monoxid-sensor-lg.svg';
import bgBad from './monoxid-sensor-lg-bad.svg';
import bgOk from './monoxid-sensor-lg-ok.svg';

@Component
export default class MonoxidSensorDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get ok(): boolean | undefined {
    const monoxid = this.metrics.discrete.monoxid;

    return monoxid ? monoxid.value !== '1' : undefined;
  }

  private get backgroundImage(): string {
    const monoxid = this.metrics.discrete.monoxid;

    return monoxid ? (monoxid.value === '1' ? bgBad : bgOk) : bg;
  }
}
