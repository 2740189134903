import xlsx from 'xlsx';
import { Row, ProjectInfo } from './models';

interface Column {
  columnFieldName: string;
}

interface FieldTuple {
  name: string;
  value: string | undefined;
}

const useCaseCodesMap = new Map<string, string>([
  ['Digitalisierung TGA (Montage/Material)', 'DTGA'],
  ['Heizungs- monitoring', 'HzM'],
  ['Heizungs-profi', 'HzP'],
  ['TGA Monitoring', 'TGAM'],
  ['TGA Integration', 'TGAI'],
  ['Wettbewerbliche Messstellen-betrieb (SMG)', 'SMG'],
  ['Dienstleistungs-management', 'DLM'],
  ['Verbrauchs-datenerfassung (VDE)', 'VDE'],
  ['Unterjährige Verbrauchsinformation (UVI)', 'UVI'],
  ['Rauchwarn-melder-monitoring', 'RWM'],
  ['Aufzugs-monitoring', 'AzM'],
  ['Smarte Aufzugswartung', 'SAM'],
]);

const columnMap: Map<string, Column> = new Map([
  ['customerId', { columnFieldName: 'Kundennummer' }],
  ['salesContactUserName', { columnFieldName: 'ASP Vertrieb' }],
  ['itemName', { columnFieldName: 'unknown' }],
  ['buildingReferenceId', { columnFieldName: 'Referenz-nummer Liegen-schaft' }],
  ['productUsageGroup', { columnFieldName: 'Produktnutzungsgruppe' }],
  ['mainBuildingAddress', { columnFieldName: 'Liegenschaft-Adresse (Hauptgebäude)' }],
  ['buildingAddress', { columnFieldName: 'Gebäude-Adresse' }],
  ['customizedId', { columnFieldName: 'Wirtschafts-einheit (WIE) der Liegenschaft' }],
  ['accessType', { columnFieldName: 'Anbindung' }],
  ['numberOfApartments', { columnFieldName: 'Anzahl Wohn-ein-heiten' }],
  ['customerContact', { columnFieldName: 'Anspr.partner Vor-Ort  / Technische/r Ansprech-partner/in' }],
  ['productType', { columnFieldName: 'Infra-struktur-variante' }],
  ['oneTimePriceInEuros', { columnFieldName: 'Einmal-preis in EUR' }],
  ['digitizationUseCase', { columnFieldName: 'Digitalisierung TGA (Montage/Material)' }],
  ['heatingMonitoringUseCase', { columnFieldName: 'Heizungs- monitoring' }],
  ['heatingProfessionalUseCase', { columnFieldName: 'Heizungs-profi' }],
  ['TGAMonitoringUseCase', { columnFieldName: 'TGA Monitoring' }],
  ['TGAIntegrationUseCase', { columnFieldName: 'TGA Integration' }],
  ['SMGUseCase', { columnFieldName: 'Wettbewerbliche Messstellen-betrieb (SMG)' }],
  ['serviceManagementUseCase', { columnFieldName: 'Dienstleistungs-management' }],
  ['VDEUseCase', { columnFieldName: 'Verbrauchs-datenerfassung (VDE)' }],
  ['UVIUseCase', { columnFieldName: 'Unterjährige Verbrauchsinformation (UVI)' }],
  ['smokeDetectorMonitoringUseCase', { columnFieldName: 'Rauchwarn-melder-monitoring' }],
  ['elevatorMonitoringUseCase', { columnFieldName: 'Aufzugs-monitoring' }],
  ['smartElevatorMaintenanceUseCase', { columnFieldName: 'Smarte Aufzugswartung' }],
]);

/* eslint-disable @typescript-eslint/no-unsafe-member-access*/

/* eslint-disable @typescript-eslint/no-explicit-any*/

/* eslint-disable @typescript-eslint/no-unsafe-assignment */

/* eslint-disable @typescript-eslint/no-unsafe-return */

const CUSTOMER_ID_FIXED_JSON_FIELD_NAME = '__EMPTY_1';
const SALES_CONTACT_USER_NAME_FIXED_JSON_FIELD_NAME = '__EMPTY_1';
const DEFAULT_ROW_ITEM_NAME_COLUMN_FIELD_NAME = 'Standortliste GK4.0';
const USE_CASE_CONFIRMATION_KEYWORD = 'ja';
const NEW_LINE_REGEX = /(?:\r\n|\r|\n)/g;
const JSON_FIELD_PREFIX = '__EMPTY_';

type Value = any;

export default class XlsxImporter {
  private dynamicColumnMap: Map<string, string>;

  private dynamicSubheaderColumnMap: Map<string, string[]>;

  private customerFieldRead = false;
  private salesContactPersonFieldRead = false;

  public constructor() {
    this.dynamicColumnMap = new Map<string, string>();
    this.dynamicSubheaderColumnMap = new Map<string, string[]>();
  }

  public import(buffer: ArrayBuffer): ProjectInfo {
    const spreadSheetContent = xlsx.read(buffer);

    if (spreadSheetContent.SheetNames.length < 1) {
      throw new Error('No sheets contained in the document.');
    }

    const firstSheet = spreadSheetContent.Sheets[spreadSheetContent.SheetNames[0]];
    const jsonSheetContent = xlsx.utils.sheet_to_json(firstSheet);

    const rows: Row[] = [];
    let externalCustomerId: string | null = null;
    let salesContactUserName: string | null = null;

    this.resetImportState();

    for (const row of jsonSheetContent) {
      const parsedRow = row as Value;

      if (!this.isHeaderInfoComplete()) {
        if (this.isColumnInfoRow(row)) {
          this.populateColumnMap(row);
        }
        if (this.isCustomerIdRow(row)) {
          const rawExternalCustomerId = parsedRow[CUSTOMER_ID_FIXED_JSON_FIELD_NAME];

          if (rawExternalCustomerId) {
            externalCustomerId = String(rawExternalCustomerId).trim();
            this.customerFieldRead = true;
          }
        } else if (this.isSalesPersonRow(row)) {
          salesContactUserName = parsedRow[SALES_CONTACT_USER_NAME_FIXED_JSON_FIELD_NAME];
          this.salesContactPersonFieldRead = true;
        }

        continue;
      }

      const buildingReferenceId = this.parseCellValue(parsedRow, this.getJsonFieldName('buildingReferenceId'));

      if (!buildingReferenceId || isNaN(+buildingReferenceId)) {
        //skip for non-numeric reference numbers
        continue;
      }

      const itemName = this.parseCellValue(parsedRow, DEFAULT_ROW_ITEM_NAME_COLUMN_FIELD_NAME);
      const productUsageGroup = this.parseCellValue(parsedRow, this.getJsonFieldName('productUsageGroup'));
      const numberOfApartments = this.parseCellValue(parsedRow, this.getJsonFieldName('numberOfApartments'));
      const mainBuildingAddress = this.parseCellValue(parsedRow, this.getJsonFieldName('mainBuildingAddress'));
      const buildingAddress = this.parseCellValue(parsedRow, this.getJsonFieldName('buildingAddress'));
      const customizedId = this.parseCellValue(parsedRow, this.getJsonFieldName('customizedId'));
      const accessType = this.parseCellValue(parsedRow, this.getJsonFieldName('accessType'));
      const productType = this.parseCellValue(parsedRow, this.getJsonFieldName('productType'));
      const customerContactName = this.parseCellValue(parsedRow, this.getJsonFieldName('customerContact'));
      const customerContactTelephoneNumber = this.parseCellValue(
        parsedRow,
        this.getJsonFieldName('customerContact', 1),
      );
      const customerContactEmailAddress = this.parseCellValue(parsedRow, this.getJsonFieldName('customerContact', 2));

      const useCase = this.parseUsecaseFields(parsedRow);

      const mandatoryFields = [itemName, buildingReferenceId];

      if (itemName && buildingReferenceId) {
        // only include rows that are not missing mandatory fields
        rows.push({
          itemName,
          productUsageGroup,
          numberOfApartments,
          buildingReferenceId,
          mainBuildingAddress,
          buildingAddress,
          customizedId,
          accessType,
          productType,
          useCase,
          customerContactName,
          customerContactTelephoneNumber,
          customerContactEmailAddress,
        });
      }
    }

    return { externalCustomerId, salesContactUserName, rows };
  }

  private isHeaderInfoComplete(): boolean {
    return this.dynamicColumnMap.size > 0 && this.customerFieldRead;
  }

  private resetImportState(): void {
    this.dynamicColumnMap = new Map<string, string>();
    this.dynamicSubheaderColumnMap = new Map<string, string[]>();
    this.customerFieldRead = false;
    this.salesContactPersonFieldRead = false;
  }

  private parseCellValue(row: Value, fieldName: string | undefined): string | undefined {
    if (!fieldName) {
      return undefined;
    }

    const rawValue = row[fieldName];

    if (!rawValue) {
      return undefined;
    }

    const value = String(rawValue).trim();

    return value;
  }

  private parseUsecaseFields(row: Value): string {
    const digitizationUseCase = this.getFieldTuple('digitizationUseCase', row);
    const heatingMonitoringUseCase = this.getFieldTuple('heatingMonitoringUseCase', row);
    const heatingProfessionalUseCase = this.getFieldTuple('heatingProfessionalUseCase', row);
    const tgaMonitoringUseCase = this.getFieldTuple('TGAMonitoringUseCase', row);
    const tgaIntegrationUseCase = this.getFieldTuple('TGAIntegrationUseCase', row);
    const smgUseCase = this.getFieldTuple('SMGUseCase', row);
    const serviceManagementUseCase = this.getFieldTuple('serviceManagementUseCase', row);
    const vdeUseCase = this.getFieldTuple('VDEUseCase', row);
    const uviUseCase = this.getFieldTuple('UVIUseCase', row);
    const smokeDetectorMonitoringUseCase = this.getFieldTuple('smokeDetectorMonitoringUseCase', row);
    const elevatorMonitoringUseCase = this.getFieldTuple('elevatorMonitoringUseCase', row);
    const smartElevatorMaintenanceUseCase = this.getFieldTuple('smartElevatorMaintenanceUseCase', row);

    const useCaseTuples: (FieldTuple | null)[] = [
      digitizationUseCase,
      heatingMonitoringUseCase,
      heatingProfessionalUseCase,
      tgaMonitoringUseCase,
      tgaIntegrationUseCase,
      smgUseCase,
      serviceManagementUseCase,
      vdeUseCase,
      uviUseCase,
      smokeDetectorMonitoringUseCase,
      elevatorMonitoringUseCase,
      smartElevatorMaintenanceUseCase,
    ];

    const confirmedUseCaseCodes: string[] = [];

    for (const tuple of useCaseTuples) {
      if (tuple != null && tuple.value == USE_CASE_CONFIRMATION_KEYWORD) {
        const useCaseCode = useCaseCodesMap.get(tuple.name);

        if (useCaseCode) {
          confirmedUseCaseCodes.push(useCaseCode);
        }
      }
    }

    return confirmedUseCaseCodes.join('.');
  }

  private getFieldTuple(fieldName: string, row: Value): FieldTuple | null {
    const columnFieldName = columnMap.get(fieldName)?.columnFieldName;

    if (!columnFieldName) {
      return null;
    }

    return { name: columnFieldName, value: this.parseCellValue(row, this.getJsonFieldName(fieldName)) };
  }

  private isColumnInfoRow(row: Value): boolean {
    return this.isColumnInfo(row, columnMap.get('buildingReferenceId')!.columnFieldName);
  }

  private isSubColumnInfoRow(row: Value): boolean {
    return this.isColumnInfo(row, columnMap.get('oneTimePriceInEuros')!.columnFieldName);
  }

  private isCustomerIdRow(row: Value): boolean {
    const customerInfoPresent: boolean =
      row[DEFAULT_ROW_ITEM_NAME_COLUMN_FIELD_NAME] &&
      row[DEFAULT_ROW_ITEM_NAME_COLUMN_FIELD_NAME] == columnMap.get('customerId')!.columnFieldName;

    return customerInfoPresent;
  }

  private isSalesPersonRow(row: Value): boolean {
    const salesPersonInfoPresent: boolean =
      row[DEFAULT_ROW_ITEM_NAME_COLUMN_FIELD_NAME] &&
      row[DEFAULT_ROW_ITEM_NAME_COLUMN_FIELD_NAME] == columnMap.get('salesContactUserName')!.columnFieldName;

    return salesPersonInfoPresent;
  }

  private isColumnInfo(row: Value, columnFieldName: string): boolean {
    const columnFound = Object.values(row).find((r) => {
      if (typeof r === 'string') {
        const columnNameSimplified = r.replaceAll(NEW_LINE_REGEX, '');

        return columnNameSimplified == columnFieldName;
      }

      return false;
    });

    return !!columnFound;
  }

  private populateColumnMap(row: Value): void {
    const dynamicColumnMap = new Map<string, string>(
      Object.keys(row).map((key) => {
        const value = row[key] as string;
        const columnName = `${value}`;
        const columnNameSimplified = columnName.replaceAll(NEW_LINE_REGEX, '').trim();
        return [columnNameSimplified, key];
      }),
    );

    this.dynamicColumnMap = dynamicColumnMap;
  }

  private populateSubheaderColumnLists(row: Value): void {
    const dynamicSubheaderColumnMap = new Map<string, string[]>();

    for (const key of Object.keys(row)) {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
      const columnNameSimplified = (row[key] as string).replaceAll('\n', '').trim();

      const existingList = dynamicSubheaderColumnMap.get(columnNameSimplified);
      if (existingList) {
        existingList.push(key);
        dynamicSubheaderColumnMap.set(columnNameSimplified, existingList);
      } else {
        dynamicSubheaderColumnMap.set(columnNameSimplified, []);
      }
    }

    this.dynamicSubheaderColumnMap = dynamicSubheaderColumnMap;
  }

  private getJsonFieldName(columnName: string, increment?: number): string | undefined {
    const column: Column | undefined = columnMap.get(columnName);

    if (!column) {
      return undefined;
    }

    const jsonFieldName = this.dynamicColumnMap.get(column.columnFieldName);

    if (!jsonFieldName) {
      return undefined;
    }

    if (!increment) {
      return jsonFieldName;
    }

    const fieldDigitPart = parseInt(jsonFieldName.replace(JSON_FIELD_PREFIX, ''));

    if (isNaN(fieldDigitPart)) {
      return jsonFieldName;
    }

    const digitSuffix = fieldDigitPart + increment;
    const resolvedJsonFieldName = `${JSON_FIELD_PREFIX}${digitSuffix}`;

    return resolvedJsonFieldName;
  }

  private getJsonFieldNameList(columnName: string): string[] {
    const column: Column | undefined = columnMap.get(columnName);

    if (!column) {
      return [];
    }

    const jsonFieldNames = this.dynamicSubheaderColumnMap.get(column.columnFieldName) ?? [];

    return jsonFieldNames;
  }
}
