import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './general-meter-electricity-meter-analog-lg.svg';
import md from './general-meter-electricity-meter-analog-md.svg';
import sm from './general-meter-electricity-meter-analog-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.GENERAL_METER_ELECTRICITY_METER_ANALOG,
  label: 'Allgemeinzähler_Stromzähler (analog)',
  shortLabel: 'ALLSTROM(A)',
  icons: { sm, md, lg },
  component: component('general-meter-electricity-meter-analog/GeneralMeterElectricityMeterAnalogDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component(
      'general-meter-electricity-meter-analog/GeneralMeterElectricityMeterAnalogAggregationWidget.vue',
    ),
  },
  defaultMetricNames: ['currentEnergy'],
  importantMetricNames: ['currentEnergy'],
  consumption: true,
}));
