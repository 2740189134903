import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './dual-temperature-lg.svg';
import md from './dual-temperature-md.svg';
import sm from './dual-temperature-sm.svg';
import component from './DualTemperatureDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.DUAL_TEMPERATURE,
  label: 'Dualer Temperatursensor',
  shortLabel: 'DualTemp',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['temperature1', 'temperature2', 'outsideTemperature'],
  importantMetricNames: ['temperature1', 'temperature2', 'outsideTemperature'],
  aggregationMetricNames: [
    {
      name: 'temperature1',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'temperature2',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'outsideTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
