

































import { Input } from '@/features/ui/inputs/model';
import { BooleanProp, StringProp } from '@/util/prop-decorators';
import { differenceWith } from 'lodash';
import { Component, Model, Vue } from 'vue-property-decorator';
import { AttributeDefinition } from './model';
import query from './query.gql';
import {
  CoreInputAttributeDefinitionsSelectorQuery,
  CoreInputAttributeDefinitionsSelectorQueryVariables,
} from './__generated__/CoreInputAttributeDefinitionsSelectorQuery';

@Component({
  apollo: {
    customers: {
      query,
      fetchPolicy: 'cache-and-network',
      variables(this: InputAttributeDefinitionsSelectorFilter): CoreInputAttributeDefinitionsSelectorQueryVariables {
        return { customerId: this.customerId, take: this.take };
      },
    },
  },
  data() {
    return { customers: undefined };
  },
})
export default class InputAttributeDefinitionsSelectorFilter extends Vue implements Input {
  @Model('update', { default: () => [] })
  private value!: AttributeDefinition[];

  @StringProp(true)
  private readonly customerId!: string;

  @BooleanProp()
  private readonly order!: boolean;

  private readonly customers?: CoreInputAttributeDefinitionsSelectorQuery['customers'];

  private get selectedAttributeDefinitions(): AttributeDefinition[] {
    return this.value;
  }

  private get attributeDefinitions(): AttributeDefinition[] {
    return this.customers?.first.attributeDefinitions.items ?? [];
  }

  private get availableAttributeDefinitions(): AttributeDefinition[] {
    return differenceWith(this.attributeDefinitions, this.selectedAttributeDefinitions, (a, b) => a.id === b.id);
  }

  private get take(): number {
    return 1000;
  }

  public get normalizedValue(): AttributeDefinition[] {
    return this.value;
  }

  public get empty(): boolean {
    return this.normalizedValue.length === 0;
  }

  public get pristine(): boolean {
    return true;
  }

  public removeAttributeDefinition(attributeDefinitionId: string): void {
    this.$emit(
      'update',
      this.value.filter(({ id }) => attributeDefinitionId !== id),
    );
  }

  public addAttributeDefinition(attributeDefinition: AttributeDefinition): void {
    this.$emit('update', this.value.concat(attributeDefinition));
  }

  private getOptionLabel({ configuration }: AttributeDefinition): string {
    return configuration.description === null
      ? configuration.name
      : `${configuration.name} (${configuration.description})`;
  }

  private moveUp(index: number): void {
    const array = [...this.selectedAttributeDefinitions];
    [array[index], array[index - 1]] = [array[index - 1], array[index]];
    this.$emit('update', array);
  }

  private moveDown(index: number): void {
    const array = [...this.selectedAttributeDefinitions];
    [array[index], array[index + 1]] = [array[index + 1], array[index]];
    this.$emit('update', array);
  }
}
