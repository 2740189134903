


























import DeviceStateIndicator from '@/components/sensor/device-state/DeviceStateIndicator.vue';
import DeviceStateIndicators from '@/components/sensor/device-state/DeviceStateIndicators.vue';
import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';

type StateIndicator = 'RED' | 'GREEN' | 'GRAY';

@Component({ components: { DeviceStateIndicator, DeviceStateIndicators } })
export default class SmokeDetectorDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get alarmStateIndicator(): StateIndicator {
    return 'GREEN';
  }

  private get pollutionStateIndicator(): StateIndicator {
    const dustLevel = this.metrics.continuous.dustLevel;

    return dustLevel ? (dustLevel.value < 28 ? 'GREEN' : 'RED') : 'GRAY';
  }

  private get mountingStateIndicator(): StateIndicator {
    const removed = this.metrics.discrete.removed;

    return removed ? (removed.value === '1' ? 'RED' : 'GREEN') : 'GRAY';
  }
}
