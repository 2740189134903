























import { isDef } from '@/util/lang';
import { ArrayProp } from '@/util/prop-decorators';
import { MetricSpotRoleAggregationDescriptorInput } from '@/types/iot-portal';
import { identity, keyBy } from 'lodash';
import moment, { Moment } from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import { getMetricUnit, formatMetricName, formatMetricValue, getConverter } from '../../util/metric-formatters';
import { SpotRoleAggregationWidgetAdapterPropsMixin } from '../spot-role-aggregation-widget-adapter/mixin';
import {
  CoreSpotRoleAggregationWidgetCounterControlQuery,
  CoreSpotRoleAggregationWidgetCounterControlQueryVariables,
} from './__generated__/CoreSpotRoleAggregationWidgetCounterControlQuery';
import query from './metric-aggregations.gql';
import { SpotRoleAggregationCounterConfig } from './model';

type AggregationPoint = Exclude<
  CoreSpotRoleAggregationWidgetCounterControlQuery['spots']['roleAggregations'][number]['metricAggregations'][number]['latest'],
  null
>;

interface Aggregations {
  name: string;
  current: AggregationPoint;
  comparism?: AggregationPoint;
}

@Component({
  apollo: {
    spots: {
      query,
      skip(this: SpotRoleAggregationWidgetCounterControl): boolean {
        return this.currentDescriptor === undefined || this.manualOnly;
      },
      variables(
        this: SpotRoleAggregationWidgetCounterControl,
      ): CoreSpotRoleAggregationWidgetCounterControlQueryVariables {
        return {
          ...this.idFilter,
          role: this.role.name,
          descriptors: [this.currentDescriptor, this.comparismDescriptor].filter(isDef),
        };
      },
    },
  },
  data() {
    return { spots: undefined, now: moment() };
  },
})
export default class SpotRoleAggregationWidgetCounterControl extends Mixins(
  SpotRoleAggregationWidgetAdapterPropsMixin,
) {
  @ArrayProp(() => [])
  private readonly configs!: SpotRoleAggregationCounterConfig[];

  private readonly spots?: CoreSpotRoleAggregationWidgetCounterControlQuery['spots'];
  private now!: Moment;
  private selectedConfigIndex = 0;

  private get manualOnly(): boolean {
    return this.aggregation.count === this.aggregation.manualCount;
  }

  private get selectedConfig(): SpotRoleAggregationCounterConfig | undefined {
    return this.configs[this.selectedConfigIndex];
  }

  private get referenceDate(): string {
    return this.selectedConfig?.referenceDate(this.now).toISOString() ?? this.now.toISOString();
  }

  private get currentDescriptor(): MetricSpotRoleAggregationDescriptorInput | undefined {
    return this.selectedConfig?.currentDescriptor(this.role.name, moment(this.referenceDate));
  }

  private get comparismDescriptor(): MetricSpotRoleAggregationDescriptorInput | undefined {
    return this.selectedConfig?.comparismDescriptor(this.role.name, moment(this.referenceDate));
  }

  private get counterProps(): Record<string, unknown> | undefined {
    return this.selectedConfig?.counterProps;
  }

  private get aggregations(): Aggregations | undefined {
    const aggregations = keyBy(this.spots?.roleAggregations[0]?.metricAggregations ?? [], 'reference');
    const current = aggregations[this.currentDescriptor?.reference ?? '']?.latest ?? undefined;
    const comparism = aggregations[this.comparismDescriptor?.reference ?? '']?.latest ?? undefined;
    if (current === undefined) {
      return undefined; // may happen, if no metrics are available or no config is selected
    }

    return { name: aggregations[this.currentDescriptor?.reference ?? ''].name, current, comparism };
  }

  private get counterValue(): number | undefined {
    const value = this.aggregations?.current.value;
    const convert = getConverter(this.aggregations?.name ?? '') ?? identity;

    return typeof value === 'number' ? convert(value) : undefined;
  }

  private get counterLabel(): string | undefined {
    const { selectedConfig } = this;
    if (selectedConfig === undefined) {
      return undefined;
    }

    const metricName = formatMetricName(selectedConfig.name);
    const label = selectedConfig.label(moment(this.referenceDate));
    const unit = getMetricUnit(selectedConfig.name);

    return unit === '' ? `${metricName} ${label}` : `${metricName} ${label} (${unit})`;
  }

  private get counterTime(): DateTime | undefined {
    return this.comparismDescriptor === undefined ? this.aggregations?.current.time : undefined;
  }

  private get counterComparism(): string | undefined {
    if (this.comparismDescriptor === undefined || this.aggregations === undefined) {
      return undefined;
    }

    const currentValue = this.aggregations.current.value;
    const comparismValue = this.aggregations.comparism?.value;
    if (comparismValue === undefined) {
      return '\u00a0';
    }

    const absolute = formatMetricValue(this.aggregations.name, currentValue - comparismValue);
    const relative = formatMetricValue('relativeChange', currentValue / comparismValue - 1);

    return `${absolute} (${relative})`;
  }

  private mounted(): void {
    const interval = setInterval(() => void (this.now = moment()), 60_000);
    this.$on('hook:beforeDestroy', () => void clearInterval(interval));
  }

  private changeSelectedConfigIndex(diff: number): void {
    this.selectedConfigIndex = (this.selectedConfigIndex + this.configs.length + diff) % this.configs.length;
  }
}
