import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './smart-climate-sensor-lg.svg';
import md from './smart-climate-sensor-md.svg';
import sm from './smart-climate-sensor-sm.svg';
import { component } from '../component';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.SMART_CLIMATE_SENSOR,
  label: 'Smart Climate Sensor',
  shortLabel: 'Smart Climate Sensor',
  icons: { sm, md, lg },
  component: component('smart-climate-sensor/SmartClimateSensorDevicePanel.vue'),
  defaultMetricNames: ['humidity'],
  importantMetricNames: ['humidity', 'temperature'],
  aggregationMetricNames: [
    {
      name: 'humidity',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
