






















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class LeakageDetectorDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get latestAlertTime(): Date | undefined {
    return this.spot.alerts.items[0] ? this.spot.alerts.items[0].startDate : undefined;
  }
}
