import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole, TreeNodeType } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/apps/act-aqua.svg?vue';

export const APP_ACT_AQUA = GroupRole.APP_ACT_AQUA;

export default option(APPS, async () => {
  return {
    name: APP_ACT_AQUA,
    label: 'ActAqua',
    activatableApp: true,
    link: {
      to: { name: 'AppActAqua' },
      icon,
    },
    types: [TreeNodeType.RootDirectory, TreeNodeType.Directory, TreeNodeType.PropertyGroup, TreeNodeType.Property],
    generateTreeNodeLocation({ __typename, id }, params) {
      return __typename === TreeNodeType.Property || __typename === TreeNodeType.PropertyGroup
        ? { name: 'AppActAqua/TreeNode', params: { ...params, treeNodeId: id } }
        : undefined;
    },
    getActiveTreeNodeId({ params }) {
      return params.treeNodeId;
    },
    hiddenForApps: [APP_EED],
    order: 3,
  };
});
