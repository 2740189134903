import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_ADMIN } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/admin',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin' },
      children: [
        {
          path: '/admin',
          name: 'AppAdmin',
          beforeEnter: (to, from, next) => next({ name: 'AppAdmin/Users' }),
        },
        {
          path: 'users',
          name: 'AppAdmin/Users',
          component: component('views/app/users/UsersView.vue'),
          meta: { app: APP_ADMIN },
        },
        {
          path: 'customers',
          name: 'AppAdmin/Customers',
          component: component('views/app/customers/CustomersView.vue'),
          meta: { app: APP_ADMIN },
        },
        {
          path: 'contracts',
          name: 'AppAdmin/Contracts',
          component: component('views/app/contracts/ContractsView.vue'),
          meta: { app: APP_ADMIN },
        },
        {
          path: 'alert-rules',
          name: 'AppAdmin/AlertRules',
          component: component('views/app/alert-rules/AlertRulesView.vue'),
          meta: { app: APP_ADMIN },
        },
        {
          path: 'attribute-definition-templates',
          name: 'AppAdmin/AttributeDefinitionTemplates',
          component: component('views/app/attribute-definition-templates/AttributeDefinitionTemplatesView.vue'),
          meta: { app: APP_ADMIN },
        },
        {
          path: 'flat-codes',
          name: 'AppAdmin/FlatCodes',
          component: component('views/app/flat-codes/FlatCodesView.vue'),
          meta: { app: APP_ADMIN },
        },
        {
          path: 'groups',
          name: 'AppAdmin/Groups',
          component: component('views/app/groups/GroupsView.vue'),
          meta: { app: APP_ADMIN },
        },
        {
          path: 'audit-logs',
          name: 'AppAdmin/DataConsumptionPoll',
          component: component('views/app/data-consumption-poll/DataConsumptionPollAppView.vue'),
          meta: { app: APP_ADMIN },
        },
      ],
    },
    {
      path: '/admin/users/:userId',
      component: component('views/user/UserView.vue'),
      props: true,
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin/User' },
      children: [
        {
          path: '/admin/users/:userId',
          name: 'AppAdmin/User',
          component: component('views/user/general/GeneralView.vue'),
          props: true,
          meta: { app: APP_ADMIN },
        },
        {
          path: 'permissions',
          name: 'AppAdmin/User/Permissions',
          component: component('views/user/permissions/PermissionsView.vue'),
          props: true,
          meta: { app: APP_ADMIN },
        },
      ],
    },
    {
      path: '/admin/customers/:customerId',
      component: component('views/customer/CustomerView.vue'),
      props: true,
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin/Customer' },
      children: [
        {
          path: '/admin/customers/:customerId',
          name: 'AppAdmin/Customer',
          component: component('views/customer/general/GeneralView.vue'),
          props: true,
          meta: { app: APP_ADMIN },
        },
        {
          path: 'contracts',
          name: 'AppAdmin/Customer/Contracts',
          component: component('views/customer/contracts/ContractsView.vue'),
          props: true,
          meta: { app: APP_ADMIN },
        },
        {
          path: 'manage-properties',
          name: 'AppAdmin/Customer/ManageProperties',
          component: component('views/customer/manage-properties/ManagePropertiesView.vue'),
          props: true,
          meta: { app: APP_ADMIN },
        },
        {
          path: 'export-presets',
          name: 'AppAdmin/Customer/ExportPresets',
          component: component('views/customer/export-presets/ExportPresetsView.vue'),
          props: true,
          meta: { app: APP_ADMIN },
        },
      ],
    },
    {
      path: '/admin/customers/:customerId/export-preset/:exportPresetId',
      name: 'AppAdmin/CustomerExportPreset',
      component: component('views/customer-export-preset/CustomerExportPresetView.vue'),
      props: true,
      meta: { app: APP_ADMIN },
    },
    {
      path: '/admin/contracts/:contractId',
      component: component('views/contract/ContractView.vue'),
      props: true,
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin/Contract' },
      children: [
        {
          path: '/admin/contracts/:contractId',
          name: 'AppAdmin/Contract',
          component: component('views/contract/general/GeneralView.vue'),
          props: true,
          meta: { app: APP_ADMIN },
        },
      ],
    },
    {
      path: '/admin/alert-rules/:alertRuleId',
      name: 'AppAdmin/AlertRule',
      component: component('views/alert-rule/AlertRuleView.vue'),
      props: true,
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin/AlertRule' },
    },
    {
      path: '/admin/attribute-definition-templates/:attributeDefinitionTemplateId',
      name: 'AppAdmin/AttributeDefinitionTemplate',
      component: component('views/attribute-definition-template/AttributeDefinitionTemplateView.vue'),
      props: true,
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin/AttributeDefinitionTemplate' },
    },
    {
      path: '/admin/groups/:groupId',
      name: 'AppAdmin/Group',
      component: component('views/group/GroupView.vue'),
      props: true,
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin/Group' },
    },
    {
      path: '/admin/flat-codes/:flatCodeId',
      name: 'AppAdmin/FlatCode',
      component: component('views/flat-code/FlatCodeView.vue'),
      props: true,
      meta: { app: APP_ADMIN, key: (): string => 'AppAdmin/FlatCode' },
    },
  ];
});
