

















import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { PropertyTooltipContentFragment } from './__generated__/PropertyTooltipContentFragment';

@Component
export default class PropertyTooltipContent extends Vue {
  @ObjectProp(true)
  private property!: PropertyTooltipContentFragment;
}
