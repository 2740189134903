










import { TreeNodeType } from '@/types/iot-portal';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Mixins, Watch } from 'vue-property-decorator';
import { Component as ComponentType } from 'vue';
import { RawLocation } from 'vue-router';
import { Action, RootAction, RootGetter } from '../../store';
import TreeNodeLocationGeneratorMixin from '../mixins/tree-node-location-generator';
import { TREE_NODE_BAR_CONTROL, TREE_NODE_LIST_ROOTS_REFETCH } from './model';
import pathQuery from './path.gql';
import rootsQuery from './roots.gql';
import searchQuery from './search.gql';
import TreeNodeBar from './TreeNodeBar.vue';
import {
  CoreTreeNodeBarControlPathQuery,
  CoreTreeNodeBarControlPathQueryVariables,
} from './__generated__/CoreTreeNodeBarControlPathQuery';
import {
  CoreTreeNodeBarControlRootsQuery,
  CoreTreeNodeBarControlRootsQueryVariables,
} from './__generated__/CoreTreeNodeBarControlRootsQuery';
import {
  CoreTreeNodeBarControlSearchQuery,
  CoreTreeNodeBarControlSearchQueryVariables,
} from './__generated__/CoreTreeNodeBarControlSearchQuery';
import { CoreTreeNodeBarControlSpotFragment } from './__generated__/CoreTreeNodeBarControlSpotFragment';
import { CoreTreeNodeBarControlTreeNodeFragment } from './__generated__/CoreTreeNodeBarControlTreeNodeFragment';
import { AppCustomerPushCustomer } from '@/features/core/store/app-customer';

type AddableTreeNode = CoreTreeNodeBarControlTreeNodeFragment & {
  children?: { items: AddableTreeNode[] };
  spots?: { items: CoreTreeNodeBarControlSpotFragment[] };
};

function levelOf(treeNode: CoreTreeNodeBarControlTreeNodeFragment | CoreTreeNodeBarControlSpotFragment): number {
  if (treeNode.__typename === 'PropertySubdivision') {
    return treeNode.floor.level;
  }

  if (treeNode.__typename === 'Spot') {
    return 1000;
  }

  return 0;
}

@Component({
  apollo: {
    roots: {
      query: rootsQuery,
      manual: true,
      variables(this: TreeNodeBarControl): CoreTreeNodeBarControlRootsQueryVariables {
        return { take: 1000 };
      },
      result(this: TreeNodeBarControl, { data }: ApolloQueryResult<CoreTreeNodeBarControlRootsQuery>): void {
        this.rootTreeNodes = data.treeNodes.items.map((treeNode) => this.addTreeNode(treeNode));
      },
    },
    search: {
      query: searchQuery,
      skip(this: TreeNodeBarControl): boolean {
        return this.nextSearchQuery === '';
      },
      variables(this: TreeNodeBarControl): CoreTreeNodeBarControlSearchQueryVariables {
        return { take: 250, searchQuery: this.searchQuery };
      },
      manual: true,
      result(this: TreeNodeBarControl, { data }: ApolloQueryResult<CoreTreeNodeBarControlSearchQuery>): void {
        this.searchTreeNodes = data.searchTreeNodes.items.map(({ treeNode }) => this.addTreeNode(treeNode, false));
        this.searchQuery = this.nextSearchQuery;
      },
    },
  },
  data() {
    return { rootTreeNodes: [], searchTreeNodes: [], treeNodes: {}, treeNodeOpennesses: {}, treeNodeChildren: {} };
  },
  provide() {
    return {
      [TREE_NODE_BAR_CONTROL]: this,
      [TREE_NODE_LIST_ROOTS_REFETCH]: async () => {
        await this.$apollo.queries.roots.refetch();

        return;
      },
    };
  },
})
export default class TreeNodeBarControl extends Mixins(TreeNodeLocationGeneratorMixin) {
  @RootGetter
  private readonly activeTreeNodeId?: string;

  @RootAction
  private readonly appCustomerPushCustomer!: Action<AppCustomerPushCustomer, void>;

  private rootTreeNodes!: CoreTreeNodeBarControlTreeNodeFragment[];
  private searchTreeNodes!: CoreTreeNodeBarControlTreeNodeFragment[];
  private searchQuery = '';
  private nextSearchQuery = '';
  private readonly treeNodes!: Record<string, CoreTreeNodeBarControlTreeNodeFragment>;
  private readonly treeNodeChildren!: Record<string, string[]>;
  private readonly treeNodeOpennesses!: Record<string, boolean>;

  public readonly $refs!: { bar: TreeNodeBar };

  private get typeSet(): Set<TreeNodeType> {
    return new Set(this.app?.types ?? Object.values(TreeNodeType));
  }

  public isTreeNodeActive({ id }: CoreTreeNodeBarControlTreeNodeFragment): boolean {
    return id === this.activeTreeNodeId;
  }

  public isTreeNodeOpen({ id }: CoreTreeNodeBarControlTreeNodeFragment): boolean {
    return this.treeNodeOpennesses[id] ?? false;
  }

  public generateLocationOf(treeNode: CoreTreeNodeBarControlTreeNodeFragment): RawLocation | undefined {
    return this.generateTreeNodeLocation(treeNode);
  }

  private get treeNodeBarComponent(): ComponentType {
    if (this.app?.treeNodeBarComponent) {
      return this.app.treeNodeBarComponent as ComponentType;
    }

    return TreeNodeBar;
  }

  public childrenOf({ id }: CoreTreeNodeBarControlTreeNodeFragment): CoreTreeNodeBarControlTreeNodeFragment[] {
    const children = this.treeNodeChildren[id]?.map((childId) => this.treeNodes[childId]) ?? [];
    const { typeSet } = this;

    return children
      .filter(({ __typename }) => typeSet.has(TreeNodeType[__typename]))
      .sort((a, b) => levelOf(b) - levelOf(a) || a.order - b.order || a.name.localeCompare(b.name));
  }

  public toggle(treeNode: CoreTreeNodeBarControlTreeNodeFragment): void {
    if (this.childrenOf(treeNode).length > 0) {
      const currentOpenness = this.treeNodeOpennesses[treeNode.id] ?? false;
      this.$set(this.treeNodeOpennesses, treeNode.id, !currentOpenness);
    }

    if (this.treeNodeOpennesses[treeNode.id]) {
      this.loadTreeNodePath(treeNode.id);
    }
  }

  private addTreeNode(newTreeNode: AddableTreeNode, open?: boolean): CoreTreeNodeBarControlTreeNodeFragment {
    const { id } = newTreeNode;
    const treeNode = this.treeNodes[id] ?? this.$set(this.treeNodes, id, newTreeNode);
    treeNode.name = newTreeNode.name;

    if (newTreeNode.children !== undefined) {
      const childIds: string[] = [];
      for (const child of newTreeNode.children.items) {
        childIds.push(child.id);
        this.addTreeNode(child);
      }
      this.$set(this.treeNodeChildren, id, childIds);
    }

    if (open !== undefined) {
      this.$set(this.treeNodeOpennesses, treeNode.id, open);
    }

    return treeNode;
  }

  @Watch('nextSearchQuery')
  private updateSearchQuery(): void {
    if (this.$apollo.queries.search.loading) {
      return;
    }

    this.searchQuery = this.nextSearchQuery;
  }

  @Watch('activeTreeNodeId', { immediate: true })
  private async loadTreeNodePath(id: string | undefined): Promise<void> {
    if (id === undefined) {
      return;
    }

    const { data } = await this.$apollo.query<
      CoreTreeNodeBarControlPathQuery,
      CoreTreeNodeBarControlPathQueryVariables
    >({ query: pathQuery, variables: { id } });

    // This is so that the customer id is always available in the components
    if (data?.treeNodes?.items && data?.treeNodes?.items.length > 0) {
      await this.appCustomerPushCustomer({ customerId: data?.treeNodes?.items[0].customer.id });
    }

    for (const treeNode of [...data.treeNodes.items].reverse()) {
      this.addTreeNode(treeNode, true);
    }
  }
}
