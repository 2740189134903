import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './smart-plug-lg.svg';
import md from './smart-plug-md.svg';
import sm from './smart-plug-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.SMART_PLUG,
  label: 'Smart plug',
  shortLabel: 'Smart plug',
  icons: { sm, md, lg },
  component: component('smart-plug/SmartPlugDevicePanel.vue'),
  defaultMetricNames: ['con'],
  importantMetricNames: ['con', 'i_avg', 'i_max', 'i_min', 'v_avg', 'v_max', 'v_min'],
  aggregationWidget: {
    enabled: true,
    component: component('smart-plug/SmartPlugAllocatorAggregationWidget.vue'),
  },
  aggregationMetricNames: [
    {
      name: 'con',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'i_avg',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'i_max',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'i_min',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'v_avg',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'v_max',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'v_min',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  consumption: true,
}));
