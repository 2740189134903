import { render, staticRenderFns } from "./NodeMenu.vue?vue&type=template&id=5b07a2ee&scoped=true&lang=pug&"
import script from "./NodeMenu.vue?vue&type=script&lang=ts&"
export * from "./NodeMenu.vue?vue&type=script&lang=ts&"
import style0 from "./NodeMenu.vue?vue&type=style&index=0&id=5b07a2ee&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5b07a2ee",
  null
  
)

export default component.exports