import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './section-meter-electricity-meter-digital-lg.svg';
import md from './section-meter-electricity-meter-digital-md.svg';
import sm from './section-meter-electricity-meter-digital-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.SECTION_METER_ELECTRICITY_METER_DIGITAL,
  label: 'Zwischenzähler Stromzähler (digital)',
  shortLabel: 'Zs_Strom(D)',
  icons: { sm, md, lg },
  component: component('section-meter-electricity-meter-digital/SectionMeterElectricityMeterDigitalDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component(
      'section-meter-electricity-meter-digital/SectionMeterElectricityMeterDigitalAggregationWidget.vue',
    ),
  },
  defaultMetricNames: ['currentEnergy', 'receivedEnergy'],
  importantMetricNames: ['currentEnergy', 'receivedEnergy'],
  aggregationMetricNames: [
    {
      name: 'currentEnergy',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'receivedEnergy',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  consumption: true,
}));
