import { ObjectProp } from '@/util/prop-decorators';
import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { CoreMetricsContinuousMetricPointFragment } from './__generated__/CoreMetricsContinuousMetricPointFragment';
import { CoreMetricsDiscreteMetricPointFragment } from './__generated__/CoreMetricsDiscreteMetricPointFragment';
import { CoreMetricsMetricPointFragment } from './__generated__/CoreMetricsMetricPointFragment';

export type ContinuousMetricPoint = CoreMetricsContinuousMetricPointFragment;
export type DiscreteMetricPoint = CoreMetricsDiscreteMetricPointFragment;
export type MetricPoint = ContinuousMetricPoint | DiscreteMetricPoint;
export type MetricsRecord<T extends MetricPoint = MetricPoint> = Record<string, T | undefined>;

export type MetricPointExtra = MetricPoint & {
  unit?: string;
  continuous?: number;
};

const CONTINUOUS: ContinuousMetricPoint['__typename'] = 'ContinuousMetricPoint';
const DISCRETE: DiscreteMetricPoint['__typename'] = 'DiscreteMetricPoint';

export class Metrics {
  public static create(points: (MetricPoint | CoreMetricsMetricPointFragment)[]): Metrics {
    const metrics = new Metrics();
    for (const point of points) {
      metrics.push(point);
    }

    return metrics;
  }

  private data: {
    all: MetricsRecord;
    [CONTINUOUS]: MetricsRecord<ContinuousMetricPoint>;
    [DISCRETE]: MetricsRecord<DiscreteMetricPoint>;
  } = Vue.observable({
    all: {},
    [CONTINUOUS]: {},
    [DISCRETE]: {},
  });

  public get all(): MetricsRecord {
    return this.data.all;
  }

  public get continuous(): MetricsRecord<ContinuousMetricPoint> {
    return this.data[CONTINUOUS];
  }

  public get discrete(): MetricsRecord<DiscreteMetricPoint> {
    return this.data[DISCRETE];
  }

  public get latest(): MetricPoint | undefined {
    return Object.values(this.data.all as Record<string, MetricPoint>)
      .sort((a, b) => +a.time - +b.time)
      .pop();
  }

  public get latestTime(): string | undefined {
    return this.latest?.time;
  }

  public get names(): string[] {
    return Object.keys(this.data.all);
  }

  public pushLatest(point: MetricPoint | CoreMetricsMetricPointFragment): boolean {
    const currentPoint = this.data.all[point.name];

    if (currentPoint && currentPoint.time > point.time) {
      return false;
    }

    this.push(point);

    return true;
  }

  public push(point: MetricPoint | CoreMetricsMetricPointFragment): void {
    const normalized = normalizeMetricPoint(point);
    Vue.set(this.data.all, point.name, normalized);

    Vue.set(this.data[normalized.__typename], point.name, normalized);
  }

  public clear(): void {
    this.data.all = {};
    this.data[CONTINUOUS] = {};
    this.data[DISCRETE] = {};
  }
}

@Component
export class MetricsMixin extends Vue {
  @ObjectProp(() => new Metrics())
  protected readonly metrics!: Metrics;

  protected get latestMetricTime(): string | undefined {
    return this.metrics.latest?.time;
  }
}

type WithValue = { value: MetricPoint['value'] };
type WithContinuous = { __typename: typeof CONTINUOUS; continuous: CoreMetricsContinuousMetricPointFragment['value'] };
type WithDiscrete = { __typename: typeof DISCRETE; discrete: CoreMetricsDiscreteMetricPointFragment['value'] };

export function normalizeMetricPoint<T>(point: T & (WithValue | WithContinuous | WithDiscrete)): T & WithValue {
  if ('value' in point) {
    return point;
  }

  return { ...point, value: point.__typename === 'ContinuousMetricPoint' ? point.continuous : point.discrete };
}
