import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import component from './OutsideTemperatureDevicePanel.vue';
import lg from './outside-temperature-lg.svg';
import md from './outside-temperature-md.svg';
import sm from './outside-temperature-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.OUTSIDE_TEMPERATURE,
  label: 'Außentemperatur',
  shortLabel: 'Außentemperatur',
  icons: { sm, md, lg },
  component,
  props: { temperatureColor: 'RED' },
  defaultMetricNames: ['temperature'],
  importantMetricNames: ['temperature'],
  aggregationMetricNames: [
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
