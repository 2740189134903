





import { BatteryStatus, BatteryStatusProp } from '@/components/sensor/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import Battery from './images/battery.svg?vue';

@Component({ components: { Battery } })
export default class SensorBatteryStatus extends Vue {
  @BatteryStatusProp(true)
  public status!: BatteryStatus;
}
