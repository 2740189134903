






import { PREZI_LINK } from '@/env';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class AppView extends Vue {
  private readonly src = PREZI_LINK;
}
