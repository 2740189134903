import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_EXPORT } from './app.builder';

const uuidCheck = '([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';

export default option(ROUTES, async () => {
  return [
    {
      path: '/export',
      name: 'AppExport',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_EXPORT },
    },
    {
      path: `/export/:treeNodeId${uuidCheck}`,
      component: component('views/tree-node/TreeNodeView.vue'),
      props: true,
      meta: { app: APP_EXPORT, key: (): string => 'AppExport' },
      children: [
        {
          path: `/export/:treeNodeId${uuidCheck}`,
          name: 'AppExport/TreeNode',
          beforeEnter: (to, from, next) => next({ name: 'AppExport/TreeNode/Spots' }),
          meta: { app: APP_EXPORT },
        },
        {
          path: 'spots',
          name: 'AppExport/TreeNode/Spots',
          component: component('views/tree-node/spots/SpotsView.vue'),
          props: true,
          meta: { app: APP_EXPORT },
        },
        {
          path: 'create-export',
          name: 'AppExport/TreeNode/CreateExport',
          component: component('views/tree-node/create-export/CreateExportView.vue'),
          props: true,
          meta: { app: APP_EXPORT },
        },
      ],
    },
  ];
});
