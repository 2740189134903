









import TreeNodeBarMixin from '@/features/core/components/tree-node-bar-control/TreeNodeBarMixin';
import { Component } from 'vue-property-decorator';
import TreeNodeList from './TreeNodeList.vue';

@Component({ components: { TreeNodeList } })
export default class TreeNodeBar extends TreeNodeBarMixin {
  private onSearch(query: string): void {
    (query.length >= 3 || query.length === 0) && this.$emit('update:searchQuery', query);
  }
}
