import { AsyncComponent } from 'vue';

export function component(path: string): AsyncComponent {
  return () =>
    import(
      /* webpackChunkName: "spot-roles" */
      /* webpackInclude: /\.vue$/ */
      /* webpackMode: "lazy" */
      /* webpackPrefetch: true */
      `./${path}`
    );
}
