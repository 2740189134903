var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-spot-widget',_vm._b({scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"properties",fn:function(){return [_vm._t("properties")]},proxy:true},{key:"overlay",fn:function(){return [_vm._t("overlay")]},proxy:true},{key:"footer",fn:function(){return [(_vm.gatewaySerial !== undefined)?[_c('ui-spot-widget-data',[_c('ui-form',{attrs:{"action":_vm.switchLamp},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
return [_c('ui-wizard-button',{attrs:{"busy":busy},on:{"click":function($event){return submit()}}},[_vm._v("Einschalten")])]}}],null,false,1276058756)})],1),_c('ui-spot-widget-data',[_c('ui-form',{attrs:{"action":_vm.switchLamp},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var busy = ref.busy;
var submit = ref.submit;
return [_c('ui-link',{attrs:{"disabled":busy},on:{"click":function($event){return submit(true)}}},[_vm._v("Nur Einzelleuchte einschalten")])]}}],null,false,3313905161)})],1)]:_c('ui-spot-widget-data',[_vm._v("Gateway nicht eingetragen")]),(_vm.metrics.all.onoff)?_c('ui-spot-widget-data',[_c('ui-relative-time',{attrs:{"date":_vm.metrics.all.onoff.time},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var displayDate = ref.displayDate;
return [_vm._v(_vm._s(displayDate)+" "+_vm._s(_vm.metrics.all.onoff.value == 1 ? 'eingeschaltet' : 'ausgeschaltet'))]}}],null,false,1180481680)})],1):_c('ui-spot-widget-data',[_vm._v("Keine Daten verfügbar")])]},proxy:true}],null,true)},'ui-spot-widget',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }