







import { BooleanProp, RequiredProp } from '@/util/prop-decorators';
import { Component as ComponentType } from 'vue';
import { Component, Vue } from 'vue-property-decorator';

@Component({ inheritAttrs: false })
export default class AppLink extends Vue {
  @RequiredProp()
  private icon!: string | ComponentType;

  // this prop is mostly used for testing in storybook
  @BooleanProp()
  private active!: boolean;
}
