import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_ACT_AQUA } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/act-aqua',
      name: 'AppActAqua',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_ACT_AQUA },
    },
    {
      path: '/act-aqua/:treeNodeId',
      name: 'AppActAqua/TreeNode',
      component: component('views/tree-node/TreeNodeView.vue'),
      props: true,
      meta: { app: APP_ACT_AQUA },
    },
  ];
});
