import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './electricity-meter-digital-lg.svg';
import md from './electricity-meter-digital-md.svg';
import sm from './electricity-meter-digital-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.ELECTRICITY_METER_DIGITAL,
  label: 'Stromzähler (digital)',
  shortLabel: 'Strom',
  icons: { sm, md, lg },
  component: component('electricity-meter-digital/ElectricityMeterDigitalDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component('electricity-meter-digital/ElectricityMeterDigitalAggregationWidget.vue'),
  },
  defaultMetricNames: ['currentEnergy', 'receivedEnergy'],
  importantMetricNames: ['currentEnergy', 'receivedEnergy'],
  aggregationMetricNames: [
    {
      name: 'currentEnergy',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'receivedEnergy',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  consumption: true,
}));
