import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole, TreeNodeType } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-enterprise-project.svg?vue';

export const APP_ENTERPRISE_PROJECT = GroupRole.APP_ENTERPRISE_PROJECT;
const types = [TreeNodeType.RootDirectory];

export default option(APPS, async () => {
  return {
    name: APP_ENTERPRISE_PROJECT,
    label: 'Unternehmensprojekte',
    link: {
      to: { name: 'AppEnterpriseProject' },
      icon,
    },
    hiddenForApps: [APP_EED],
    order: Number.POSITIVE_INFINITY,
  };
});
