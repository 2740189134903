import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './network-analyzer-lg.svg';
import md from './network-analyzer-md.svg';
import sm from './network-analyzer-sm.svg';
import component from './NetworkAnalyzerDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.NETWORK_ANALYZER,
  label: 'Network Analyzer',
  shortLabel: 'Network Analyzer',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['batteryVoltage'],
  importantMetricNames: [
    'batteryVoltage',
    'imei',
    'rsrpNB',
    'resrqNB',
    'sinrNB',
    'rsrpLTE',
    'resrqLTE',
    'sinrLTE',
    'rssiGSM',
  ],
}));
