import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_HOME } from './app.builder';

export default option(ROUTES, async () => [
  {
    path: '/',
    name: 'AppHome',
    component: component('views/app/AppView.vue'),
    meta: { app: APP_HOME, key: () => 'AppHome' },
  },
]);
