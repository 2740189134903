

















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { DiscreteMetricPoint } from '@/features/core/util/metrics';
import { Component, Mixins } from 'vue-property-decorator';
import LightSwitchDevicePanel from './LightSwitchDevicePanel.vue';
import { TargetState } from './util';

@Component({ components: { LightSwitchDevicePanel } })
export default class LightSwitchDevicePanelControl extends Mixins(SpotWidgetDataMixin) {
  private switchDisabled = false;
  private transientTargetState: TargetState = 'OFF';
  private transientTargetStateDate = new Date(0);

  private get onoff(): DiscreteMetricPoint | undefined {
    return this.metrics.discrete.onoff;
  }

  private get targetState(): TargetState {
    if (this.onoff && this.transientTargetStateDate.toISOString() < this.onoff.time) {
      return this.onoff.value === '1' ? 'ON' : 'OFF';
    }

    return this.transientTargetState;
  }

  private set targetState(value: TargetState) {
    this.switchLamp(value);
  }

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  private async switchLamp(state: TargetState): Promise<void> {
    alert('Not implemented');
  }
}
