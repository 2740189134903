import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-sales-canvas.svg?vue';

export const APP_SALES = GroupRole.APP_SALES;

export default option(APPS, async () => {
  return {
    name: APP_SALES,
    label: 'Sales',
    link: {
      to: { name: 'AppSales' },
      icon,
    },
    hiddenForApps: [APP_EED],
    order: Number.POSITIVE_INFINITY,
  };
});
