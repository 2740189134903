import { from, map, mergeMap, Observable } from 'rxjs';
import { HttpRequest, IHttpInterceptor, RxJSHttpClient } from 'rxjs-http-client';
import { LoginRequired } from '../model';

export class RestClient {
  public constructor(private client: RxJSHttpClient, private baseUrl: string) {}

  public get<T>(uri: string): Observable<T> {
    return this.client
      .get(`${this.baseUrl}${uri}`)
      .pipe(mergeMap((response) => response.json()))
      .pipe(map((jsonResponse) => jsonResponse as T));
  }

  public post<R, T>(uri: string, body: R): Observable<T> {
    return this.client
      .post(`${this.baseUrl}${uri}`, { body })
      .pipe(mergeMap((response) => response.json()))
      .pipe(map((jsonResponse) => jsonResponse as T));
  }

  public put<R>(uri: string, body: R): Observable<string> {
    return this.client.put(`${this.baseUrl}${uri}`, { body }).pipe(mergeMap((response) => response.text()));
  }

  public delete<R>(uri: string, body?: R): Observable<string> {
    return this.client.delete(`${this.baseUrl}${uri}`, { body }).pipe(mergeMap((response) => response.text()));
  }
}

export async function createRestClient(keycloak: Keycloak.KeycloakInstance, baseUrl: string): Promise<RestClient> {
  const keycloakInterceptor: IHttpInterceptor<HttpRequest> = {
    intercept(data: HttpRequest): HttpRequest {
      try {
        from(keycloak.updateToken(10));
      } catch (e) {
        // eslint-disable-next-line no-console -- dont swallow errors
        console.error(e);
      }

      if (keycloak.token === undefined) {
        throw new LoginRequired();
      }

      data.headers['Authorization'] = `Bearer ${keycloak.token}`;

      return data;
    },
  };

  const client: RxJSHttpClient = new RxJSHttpClient([keycloakInterceptor]);

  return Promise.resolve<RestClient>(new RestClient(client, baseUrl));
}
