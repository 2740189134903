import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './ceiling-lamp-lg.svg';
import md from './ceiling-lamp-md.svg';
import sm from './ceiling-lamp-sm.svg';
import component from './CeilingLampDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.CEILING_LAMP,
  label: 'Deckenleuchte',
  shortLabel: 'Deckenleuchte',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['onoff'],
  importantMetricNames: ['online', 'onoff'],
}));
