import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './individual-meter-electricity-meter-analog-lg.svg';
import md from './individual-meter-electricity-meter-analog-md.svg';
import sm from './individual-meter-electricity-meter-analog-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.INDIVIDUAL_METER_ELECTRICITY_METER_ANALOG,
  label: 'Individueller_Stromzähler (analog)',
  shortLabel: 'INDSTROM(A) (analog)',
  icons: { sm, md, lg },
  component: component(
    'individual-meter-electricity-meter-analog/IndividualMeterElectricityMeterAnalogDevicePanel.vue',
  ),
  aggregationWidget: {
    enabled: true,
    component: component(
      'individual-meter-electricity-meter-analog/IndividualMeterElectricityMeterAnalogAggregationWidget.vue',
    ),
  },
  defaultMetricNames: ['currentEnergy'],
  importantMetricNames: ['currentEnergy'],
  consumption: true,
}));
