









































import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import {
  DormakabaComponentCreateDormakabaVisitMutation,
  DormakabaComponentCreateDormakabaVisitMutationVariables,
} from './__generated__/DormakabaComponentCreateDormakabaVisitMutation';
import {
  DormakabaComponentUnlockDormakabaComponentMutation,
  DormakabaComponentUnlockDormakabaComponentMutationVariables,
} from './__generated__/DormakabaComponentUnlockDormakabaComponentMutation';
import iconSend from './icon-send.svg';
import createDormakabaVisitMutation from './create-dormakaba-visit.gql';
import unlockDormakabaComponentMutation from './unlock-dormakaba-component.gql';
import { CreateDormakabaVisitInput } from '../../../types/iot-portal';

@Component
export default class DormakabaComponentSpotWidgetControl extends Mixins(SpotWidgetDataMixin) {
  private readonly iconSend = iconSend;

  private get generatedCode(): string {
    return String(1000 + Math.floor(Math.random() * 998999));
  }

  private async unlockDormakabaComponent(): Promise<void> {
    await this.$apollo.mutate<
      DormakabaComponentUnlockDormakabaComponentMutation,
      DormakabaComponentUnlockDormakabaComponentMutationVariables
    >({
      mutation: unlockDormakabaComponentMutation,
      variables: { input: { spotId: this.spot.id } },
    });
  }

  private async createDormakabaVisit(input: Omit<CreateDormakabaVisitInput, 'spotId'>): Promise<void> {
    await this.$apollo.mutate<
      DormakabaComponentCreateDormakabaVisitMutation,
      DormakabaComponentCreateDormakabaVisitMutationVariables
    >({
      mutation: createDormakabaVisitMutation,
      variables: { input: { ...input, spotId: this.spot.id } },
    });
  }
}
