

















import LayoutEngine from '@/features/ui/layout/layout-engine';
import { Component, Mixins } from 'vue-property-decorator';
import { KEYCLOAK } from '../container';
import { LoginRequired } from '../model';
import ContainerMixin from './mixins/container';

@Component({ components: { LayoutEngine } })
export default class App extends Mixins(ContainerMixin) {
  private loginRequired = false;

  private get routerViewKey(): string {
    const key = this.$route.matched[0]?.meta?.key;

    return typeof key === 'function' ? key(this.$route) : this.$route.path;
  }

  private created(): void {
    this.container(KEYCLOAK).onAuthLogout = (): void => void (this.loginRequired = true);
  }

  private errorCaptured(e: Error): false | void {
    if (e instanceof LoginRequired) {
      this.loginRequired = true;

      return false;
    }
  }

  private onLoginClick(): void {
    this.container(KEYCLOAK).login();
  }
}
