import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './quad-water-lg.svg';
import md from './quad-water-md.svg';
import sm from './quad-water-sm.svg';
import component from './QuadWaterDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.QUAD_WATER,
  label: 'Quad Warmwassersensor',
  shortLabel: 'Warmwasser',
  icons: { sm, md, lg },
  defaultMetricNames: ['temperature1'],
  component,
}));
