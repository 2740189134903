import { builder } from '@/util/container';
import Vuex from 'vuex';
import { APP_MAP, KEYCLOAK, STORE, VUE } from '.';
import { createStore, setupIdTokenSync, setupRealmRolesSync } from '../store';

export default builder(({ configure, provide }) => {
  provide(STORE, async (get) => createStore(await get(APP_MAP)));
  configure(async (get) => void (await get(VUE)).use(Vuex));
  configure(async (get) => void setupIdTokenSync(await get(STORE), await get(KEYCLOAK)));
  configure(async (get) => void setupRealmRolesSync(await get(STORE), await get(KEYCLOAK)));
});
