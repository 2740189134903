






















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { ContinuousMetricPoint } from '@/features/core/util/metrics';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class TemperatureHumidityDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private formatTemperatureHumidity(point?: ContinuousMetricPoint): string {
    return point ? point.value.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'XX';
  }
}
