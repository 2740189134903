import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './relais-lg.svg';
import md from './relais-md.svg';
import sm from './relais-sm.svg';
import component from './RelaisDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.SCHALTKONTAKT,
  label: 'Potentialfreier Schaltkontakt',
  shortLabel: 'Schaltkontakt',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['opened1', 'opened2'],
  importantMetricNames: ['opened1', 'opened2'],
}));
