import { ActionContext, GetterTree, Module, MutationTree } from 'vuex';
import { RootStore } from '@/features/core/store';
import { HeatingSystemSummary } from '@/features/domain-ui/heating-system-list-table/model';
import { ORIGIN_ENDPOINT } from '@/env';

export type HeatingSystem = HeatingSystemSummary & {
  active: boolean;
  metadata?: HeatingSystemMetadata;
};
export type HeatingSystemComponent = {
  label: string;
  value: string;
  active: boolean;
  type: string;
};
export type HeatingSystemMetadata = {
  // kessel
  condensing: boolean | null;
  wall_mounted: boolean | null;
  return_temp_uplift: boolean | null;
  safety_temp_guard: number | null;
  safety_temp_limiter: number | null;
  cascade: boolean | null;
  is_modulating: boolean | null;
  has_own_pump: boolean | null;
  has_regulating_valve: boolean | null;
  has_check_valve: boolean | null;
  system_separation: boolean | null;
  power_max_out: number | null;
  power_min_out: number | null;
  // warm heating
  type_of_pwh: string | null;
  potable_water_target_temp: number | null;
  potable_water_temperature_tolerance: number | null;
  circulation_target_temp: number | null;
  circulation_temperature_tolerance: number | null;
};

export enum WaterHeatingTypes {
  FRESH_WATER_STATION = 'FRESH_WATER_STATION',
  STORAGE_TANK_CHARGING_SYSTEM = 'STORAGE_TANK_CHARGING_SYSTEM',
  HEATING_COIL_STORAGE_TANK = 'HEATING_COIL_STORAGE_TANK',
}

export type AddMetadataPayload = {
  heatingSystemId: string;
  componentType: string;
  metadata: {
    type_of_pwh?: string;
    potable_water_target_temp?: number;
    potable_water_temperature_tolerance?: number;
    circulation_target_temp?: number;
    circulation_temperature_tolerance?: number;
  };
};

export type HeatingSystemsMetadataState = {
  openModal: boolean;
  openApplySettings: boolean;
  heatingSystems: HeatingSystem[];
  heatingSystemsComponents: HeatingSystemComponent[];
  formData: HeatingSystemMetadata | null;
};

const state: HeatingSystemsMetadataState = {
  openModal: false,
  openApplySettings: false,
  heatingSystems: [],
  heatingSystemsComponents: [],
  formData: null,
};
const getters: GetterTree<HeatingSystemsMetadataState, RootStore> = {
  openModal: (state) => state.openModal,
  openApplySettings: (state) => state.openApplySettings,
  heatingSystems: (state) => state.heatingSystems,
  heatingSystemsComponents: (state) => state.heatingSystemsComponents,
  activeHeatingSystem: (state) => state.heatingSystems.find((item) => item.active === true),
  activeHeatingSystemComponent: (state) => state.heatingSystemsComponents.find((item) => item.active === true),
  formData: (state) => state.formData,
};
const mutations: MutationTree<HeatingSystemsMetadataState> = {
  openModal(state, value: boolean) {
    state.openModal = value;
  },
  openApplySettings(state, value: boolean) {
    state.openApplySettings = value;
  },
  setHeatingSystems(state, value: HeatingSystem[]) {
    state.heatingSystems = value;
  },
  setHeatingSystemsComponents(state, value: HeatingSystemComponent[]) {
    state.heatingSystemsComponents = value;
  },
  setFormData(state, value: HeatingSystemMetadata) {
    state.formData = value;
  },
  setHeatingSystemMetadata(state, payload: { id: string; metadata: HeatingSystemMetadata }) {
    const heatingSystem = state.heatingSystems.find((heatingSystem) => heatingSystem.components.id === payload.id);
    if (heatingSystem) {
      heatingSystem.metadata = payload.metadata;
    }
  },
};
const actions = {
  async addHeatingSystemMetadata(
    { commit }: ActionContext<HeatingSystemsMetadataState, HeatingSystemsMetadataState>,
    payload: AddMetadataPayload,
  ): Promise<Response> {
    return await fetch(`${ORIGIN_ENDPOINT}/api/v1/daedalus-connector/metadata`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(payload),
    });
  },
};

export const HeatingSystemsMetadataModule: Module<HeatingSystemsMetadataState, any> = {
  state,
  getters,
  mutations,
  actions,
};
