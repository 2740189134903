import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './heat-meter-counter-lg.svg';
import md from './heat-meter-counter-md.svg';
import sm from './heat-meter-counter-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.HEAT_METER_COUNTER,
  label: 'Wärmemengenzähler',
  shortLabel: 'WMZ',
  icons: { sm, md, lg },
  component: component('heat-meter-counter/HeatMeterCounterDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component('heat-meter-counter/HeatMeterCounterAggregationWidget.vue'),
  },
  defaultMetricNames: ['currentEnergy'],
  importantMetricNames: ['currentEnergy', 'currentFlow', 'currentVolume'],
  consumption: true,
  aggregationMetricNames: [
    {
      name: 'currentEnergy',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'currentFlow',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
