





import FlexChildStyle from '@/features/ui/mixins/flex-child-style';
import { BooleanProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';

@Component
export default class Cell extends Mixins(FlexChildStyle) {
  @BooleanProp()
  private readonly pushRight!: boolean;

  @BooleanProp()
  private readonly textOverflow!: boolean;
}
