import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './water-meter-hot-lg.svg';
import md from './water-meter-hot-md.svg';
import sm from './water-meter-hot-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.WATER_METER_HOT,
  label: 'Warmwasserzähler',
  shortLabel: 'Warmwasser',
  icons: { sm, md, lg },
  component: component('water-meter-cold/WaterMeterDevicePanel.vue'),
  props: { volumeColor: 'RED' },
  aggregationWidget: {
    enabled: true,
    component: component('water-meter-cold/WaterMeterAggregationWidget.vue'),
    props: { digitColor: 'RED' },
  },
  defaultMetricNames: ['currentVolume'],
  importantMetricNames: ['currentVolume', 'currentFlow'],
  aggregationMetricNames: [
    {
      name: 'currentVolume',
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  consumption: true,
}));
