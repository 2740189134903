import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole, TreeNodeType } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-smart-device.svg?vue';
import TreeNodeBar from '../components/tree-node-bar/TreeNodeBar.vue';

export const APP_SMART_DEVICE = GroupRole.APP_SMART_DEVICES; //should have been renamed to APP_SMART_DEVICE

export default option(APPS, async () => {
  return {
    name: APP_SMART_DEVICE,
    label: 'Smart Device',
    link: {
      to: { name: 'AppSmartDevice' },
      icon,
      exact: true,
    },
    types: [TreeNodeType.RootDirectory, TreeNodeType.Property, TreeNodeType.Spot],
    treeNodeBarComponent: TreeNodeBar,
    generateTreeNodeLocation({ __typename, id, parent }, params) {
      return __typename === TreeNodeType.Spot
        ? { name: 'AppSmartDevice/Spot', params: { ...params, treeNodeId: parent?.id ?? '', spotId: id } }
        : { name: 'AppSmartDevice/TreeNode', params: { ...params, treeNodeId: id } };
    },
    getActiveTreeNodeId({ params }) {
      return params.treeNodeId;
    },
    hiddenForApps: [APP_EED],
    order: 11,
  };
});
