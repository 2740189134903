import { DurationInputArg2, Moment } from 'moment';

export interface Input {
  readonly normalizedValue: unknown;
  readonly empty?: boolean;
  readonly pristine?: boolean;
}

export interface Option<T = unknown> {
  value: T;
  label: string;
}

export interface DatePreset {
  label: string;
  date: (now: () => Moment) => Moment | undefined;
}

export enum InputTextType {
  TEXT = 'text',
  PASSWORD = 'password',
}

export type InputDatePickerDuration = { duration: DurationInputArg2 };

export const INPUT_DATE_PICKER_MODE_META: {
  readonly [k in string]: Readonly<InputDatePickerDuration>;
} = Object.freeze({
  DAY: { duration: 'day' },
  MONTH: { duration: 'month' },
});

export interface TimepickerModel {
  HH: string;
  mm: string;
  ss?: string;
}
