import { isFunction } from '@/util/lang';
import { debounce } from 'lodash';

export function Debounce(wait = 0, leading = true, trailing = true): MethodDecorator {
  // eslint-disable-next-line @typescript-eslint/ban-types -- lookup map for instances
  const instanceMap = new WeakMap<object, (...args: unknown[]) => unknown>();

  return (target, propertyKey, descriptor: PropertyDescriptor) => {
    const method: unknown = descriptor.value;

    if (!isFunction(method)) {
      return;
    }

    delete descriptor.writable;
    delete descriptor.value;
    descriptor.get = function () {
      const debounced = instanceMap.get(this) ?? debounce(method, wait, { leading, trailing }).bind(this);

      instanceMap.set(this, debounced);

      return debounced;
    };
  };
}
