












import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import backgroundImage from './fronius-inverter-lg.svg';

@Component
export default class FroniusInverterDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private readonly backgroundImage = backgroundImage;
}
