















import { ObjectProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { AppSupportPropertyWidgetFragment } from './__generated__/AppSupportPropertyWidgetFragment';
import ArrowRightOutline from './arrow-right-outline.svg?vue';
import PropertyMarker from './property-marker.svg?vue';

@Component({ components: { ArrowRightOutline, PropertyMarker } })
export default class PropertyWidget extends Vue {
  @ObjectProp(true)
  private readonly property!: AppSupportPropertyWidgetFragment;
}
