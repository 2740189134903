import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_MAINTENANCE } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/maintenance',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_MAINTENANCE, key: (): string => 'AppMaintenance' },
      children: [
        {
          path: '/maintenance',
          name: 'AppMaintenance',
          beforeEnter: (to, from, next) => next({ name: 'AppMaintenance/Alerts' }),
        },
        {
          path: 'alerts',
          name: 'AppMaintenance/Alerts',
          component: component('views/app/alerts/AlertsView.vue'),
          meta: { app: APP_MAINTENANCE },
        },
        {
          path: 'gateways',
          name: 'AppMaintenance/Gateways',
          component: component('views/app/gateways/GatewaysView.vue'),
          meta: { app: APP_MAINTENANCE },
        },
      ],
    },
  ];
});
