

















































































import { DeviceRoleAggregationMetric } from '@/features/core/model';
import { Column } from '@/features/ui/table/model';
import { MetricResolutionAggregator } from '@/types/iot-portal';
import { ArrayProp, EnumProp, IntegerProp, ObjectProp, StringProp } from '@/util/prop-decorators';
import moment from 'moment';
import { Component, Mixins } from 'vue-property-decorator';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import { CoreSpotMetricsPanelControlSpotQuery } from './__generated__/CoreSpotMetricsPanelControlSpotQuery';
import { RangeInterval } from '@/types/hsc-types';
import { SupersetMetrics } from '@/hsc-api/queries/__generated__/SupersetMetrics';
import { MetricPoint } from './metric-point.entity';

type SuperSetMetricPoint = SupersetMetrics['supersetSpotMetrics']['items'][number]['points'][number];

@Component
export default class HeatingSystemMetricsPanelControlTable extends Mixins(DeviceRoleMapMixin) {
  @ObjectProp(true)
  private readonly spot!: CoreSpotMetricsPanelControlSpotQuery['spot'];

  @ArrayProp(() => [])
  private readonly selectedMetrics!: string[];

  @ArrayProp(() => [])
  private readonly selectableMetrics!: string[];

  @ArrayProp(() => [])
  private readonly metricRows!: MetricPoint[];

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  @StringProp()
  private readonly selectedHistoryMetric?: string;

  @StringProp()
  private readonly aggregationInterval?: Duration;

  @ObjectProp()
  private readonly history?: SupersetMetrics['supersetSpotMetrics'];

  @IntegerProp(0)
  private readonly historyPage!: number;

  @IntegerProp(0)
  private readonly historySize!: number;

  private readonly columns: Column[] = [
    { name: 'name', label: 'Metrik', verticalAlign: 'top', width: '35%' },
    { name: 'aggregator', label: 'Aggregationsfunktion', verticalAlign: 'top', width: '20%' },
    { name: 'value', label: 'Wert', align: 'right', verticalAlign: 'top', width: '15%' },
    { name: 'time', label: 'Letzter Datenpunkt', verticalAlign: 'top', width: '15%' },
    { name: 'history', label: 'Aktion', verticalAlign: 'top', width: '15%' },
  ];

  private get aggregationMetricMap(): Map<string, DeviceRoleAggregationMetric> {
    return new Map(
      this.deviceRoleMap[this.spot.first.role]?.aggregationMetricNames?.map((metric) => [metric.name, metric]),
    );
  }

  private get shouldAggregateValues(): boolean {
    return this.aggregationInterval !== undefined && this.aggregationMetricPoints !== undefined;
  }

  private get aggregationMetricSet(): Set<string> {
    return new Set(
      this.deviceRoleMap[this.spot?.first.role ?? '']?.aggregationMetricNames?.map(({ name }) => name) ?? [],
    );
  }

  private get aggregationMetricPoints(): MetricPoint[] | undefined {
    return [];
  }

  private get importantMetricNames(): string[] {
    return this.deviceRoleMap[this.spot?.first.role ?? '']?.importantMetricNames ?? [];
  }

  private getMetricsByName(metricName: string): SuperSetMetricPoint[] {
    return this.history?.items.find(({ name }) => name == metricName)?.points ?? [];
  }

  private getHistoryMetricPoints(metricName: string): MetricPoint[] {
    return this.translateMetricPoints(metricName, this.getMetricsByName(metricName));
  }

  private checkMoreHistoryMetricPoints(metricName: string): boolean {
    // The next button should only be active if there are more points then the page size
    return this.getMetricsByName(metricName).length > this.historySize;
  }

  private translateMetricPoints(metricName: string, points: SuperSetMetricPoint[]): MetricPoint[] {
    return points.map((point) => ({
      name: metricName,
      value: point.value,
      time: point.time,
    }));
  }

  private timestamp(row: SuperSetMetricPoint): string {
    console.log(new Date(row.time));
    console.log(`Parsed to local from UTC: ${moment.utc(new Date(row.time)).format('DD.MM.YYYY HH:00')}`);
    switch (this.aggregationInterval) {
      case RangeInterval.MONTH:
        return moment(new Date(row.time)).format('MM.YYYY');
      case RangeInterval.WEEK:
        return moment(new Date(row.time)).format('WW.YYYY');
      case RangeInterval.DAY:
        return moment(new Date(row.time)).format('DD.MM.YYYY');
      case RangeInterval.HOUR:
        return `${moment(new Date(row.time)).format('DD.MM.YYYY HH:00')} - ${moment(new Date(row.time))
          .add(1, 'hour')
          .format('HH:00')}`;
      default:
        return moment(new Date(row.time)).format('L LTS');
    }
  }

  private hasMetricAggregators(metricName: string): boolean {
    return (this.aggregationMetricMap.get(metricName)?.aggregators ?? []).length > 0;
  }

  private isAggregatorAllowed(metricName: string): boolean {
    return (
      this.aggregator !== undefined &&
      (this.aggregationMetricMap.get(metricName)?.aggregators.includes(this.aggregator) ?? false)
    );
  }

  private isHistoryAllowed(metricName: string): boolean {
    return this.aggregator === undefined || this.isAggregatorAllowed(metricName);
  }

  private isImportantMetric(name: string): boolean {
    return this.importantMetricNames.includes(name) || this.importantMetricNames.includes(name.replace(/\d+$/, ''));
  }

  private getMetricAggregatorExplanation(): string | undefined {
    switch (this.aggregator) {
      case MetricResolutionAggregator.FIRST:
        return undefined;
      case MetricResolutionAggregator.LAST:
        return undefined;
      case MetricResolutionAggregator.MAX:
        return 'Maximum der Werte';
      case MetricResolutionAggregator.MIN:
        return 'Minimum der Werte';
      case MetricResolutionAggregator.MEAN:
        return 'Durchschnittliche Werte';
      case MetricResolutionAggregator.SUM:
        return 'Kumulierte Werte';
      default:
        return undefined;
    }
  }
}
