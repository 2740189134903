import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { Route } from 'vue-router';
import { component } from '../component';
import { APP_EED } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/eed',
      component: component('views/app/AppView.vue'),
      name: 'AppEED',
      meta: { app: APP_EED, key: (): string => 'AppEED' },
    },
    {
      path: '/eed/:treeNodeId/:spotRole',
      component: component('views/widget/WidgetView.vue'),
      name: 'AppEED/Widget',
      props: true,
      meta: { app: APP_EED, key: ({ params }: Route): string => `AppEED/Widget?${params.spotId}` },
    },
  ];
});
