import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { Route } from 'vue-router';
import { component } from '../component';
import { APP_SMART_DEVICE } from './app.builder';

const uuidCheck = '([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';

export default option(ROUTES, async () => [
  {
    path: '/smart-device',
    name: 'AppSmartDevice',
    component: component('views/app/AppView.vue'),
    meta: { app: APP_SMART_DEVICE, key: (): string => 'AppSmartDevice' },
  },
  {
    path: `/smart-device/:treeNodeId${uuidCheck}`,
    component: component('views/tree-node/TreeNodeView.vue'),
    props: true,
    meta: {
      app: APP_SMART_DEVICE,
      key: (route: Route): string => `AppSmartDevice/TreeNode?${route.params.treeNodeId}`,
    },
    children: [
      {
        path: `/smart-device/:treeNodeId${uuidCheck}`,
        name: 'AppSmartDevice/TreeNode',
        component: component('views/tree-node/TreeNodeView.vue'),
        props: true,
        meta: { app: APP_SMART_DEVICE },
      },
      {
        path: `spots/:spotId${uuidCheck}`,
        name: 'AppSmartDevice/Spot',
        component: component('views/spot/SpotView.vue'),
        props: true,
        meta: { app: APP_SMART_DEVICE },
      },
    ],
  },
]);
