import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './motion-detector-lg.svg';
import md from './motion-detector-md.svg';
import sm from './motion-detector-sm.svg';
import component from './ModbusConverter.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.MODBUS_CONVERTER,
  label: 'Modbus-Konverter',
  shortLabel: 'Konverter',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: [],
  importantMetricNames: [],
}));
