import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_USER_ACCOUNT } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/user-account',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_USER_ACCOUNT, key: (): string => 'AppUserAccount' },
      children: [
        {
          path: '/user-account',
          name: 'AppUserAccount',
          component: component('views/app/general/GeneralView.vue'),
          meta: { app: APP_USER_ACCOUNT },
        },
        {
          path: 'notifications-settings',
          name: 'AppUserAccount/NotificationSettings',
          component: component('views/app/notification-settings/SettingsView.vue'),
          meta: { app: APP_USER_ACCOUNT },
        },
      ],
    },
  ];
});
