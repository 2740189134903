import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-admin.svg?vue';

export const APP_ADMIN = GroupRole.APP_ADMIN;

export default option(APPS, async () => {
  return {
    name: APP_ADMIN,
    label: 'Administration',
    link: {
      to: { name: 'AppAdmin' },
      icon,
    },
    hiddenForApps: [APP_EED],
    order: 4,
  };
});
