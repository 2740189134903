












import { floorShortLabel } from '@/features/domain-ui/property-floor/label';
import { ObjectProp } from '@/util/prop-decorators';
import { Component, Inject, Vue } from 'vue-property-decorator';
import { RawLocation } from 'vue-router';
import { CoreTreeNodeBarControlTreeNodeFragment } from './__generated__/CoreTreeNodeBarControlTreeNodeFragment';
import { TREE_NODE_BAR_CONTROL } from './model';
import TreeNodeBarControl from './TreeNodeBarControl.global.vue';
import TreeNodeList from './TreeNodeList.vue';

@Component
export default class TreeNode extends Vue {
  @ObjectProp(true)
  private readonly treeNode!: CoreTreeNodeBarControlTreeNodeFragment;

  // use as dynamic component to handle recursive component nesting
  private readonly treeNodeList = TreeNodeList;

  @Inject(TREE_NODE_BAR_CONTROL)
  private readonly control!: TreeNodeBarControl;

  private get label(): string {
    return this.treeNode.__typename === 'PropertySubdivision'
      ? `${this.treeNode.name} (${floorShortLabel(this.treeNode.floor)})`
      : this.treeNode.name;
  }

  private get active(): boolean {
    return this.control.isTreeNodeActive(this.treeNode);
  }

  private get open(): boolean {
    return this.control.isTreeNodeOpen(this.treeNode);
  }

  private get location(): RawLocation | undefined {
    return this.control.generateLocationOf(this.treeNode);
  }

  private get children(): CoreTreeNodeBarControlTreeNodeFragment[] {
    return this.control.childrenOf(this.treeNode);
  }

  private async toggle(): Promise<void> {
    let navigated = false;
    if (this.location !== undefined) {
      try {
        await this.$router.push(this.location);
        navigated = true;
      } catch (e) {
        // eslint-disable-next-line no-console -- dont swallow errors
        console.error(e);
      }
    }
    if (!navigated || !this.open) {
      this.control.toggle(this.treeNode);
    }
  }
}
