import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_SUITE_FIVE } from './app.builder';
export default option(ROUTES, async () => {
  return [
    {
      path: '/suite-five',
      name: 'AppSuiteFive',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_SUITE_FIVE },
    },
  ];
});
