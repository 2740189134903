














import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class UserToolbar extends Vue {
  @StringProp(true)
  private readonly name!: string;

  @StringProp(true)
  private readonly email!: string;

  private open = false;

  private onOutsideClick(event: Event): void {
    if (!this.open) {
      return;
    }

    this.open = false;

    event.stopImmediatePropagation();
    event.preventDefault();
  }

  private onLogoutClick(): void {
    this.open = false;
    this.$emit('logout-click');
  }

  private goToAppUserAccount(): void {
    this.open = false;
    this.$router.push({ name: 'AppUserAccount' });
  }
}
