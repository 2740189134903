import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { Route } from 'vue-router';
import { component } from '../component';
import { APP_CUSTOMER } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/customer/:customerId/vendor-apis/ener-iq-api/:vendorApiId',
      component: component('views/ener-iq-api/EnerIqApiView.vue'),
      props: true,
      meta: { app: APP_CUSTOMER, key: (): string => `AppCustomer/VendorApis/EnerIq` },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/ener-iq-api/:vendorApiId',
          name: 'AppCustomer/VendorApis/EnerIqApi',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/EnerIqApi/EconomicUnits', params }),
        },
        {
          path: 'economic-units',
          name: 'AppCustomer/VendorApis/EnerIqApi/EconomicUnits',
          component: component('views/ener-iq-api/economic-units/EconomicUnitsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'settings',
          name: 'AppCustomer/VendorApis/EnerIqApi/Settings',
          component: component('views/ener-iq-api/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
    {
      path: '/customer/:customerId/vendor-apis/ener-iq-api/:vendorApiId/connected-economic-unit/:connectedEconomicUnitId',
      component: component('views/ener-iq-connected-economic-unit/EnerIqConnectedEconomicUnitView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/EnerIqConnectedEconomicUnit?${params.connectedEconomicUnitId}`,
      },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/ener-iq-api/:vendorApiId/connected-economic-unit/:connectedEconomicUnitId',
          name: 'AppCustomer/EnerIqConnectedEconomicUnit',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/EnerIqConnectedEconomicUnit/Settings', params }),
        },
        {
          path: 'settings',
          name: 'AppCustomer/EnerIqConnectedEconomicUnit/Settings',
          component: component('views/ener-iq-connected-economic-unit/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
  ];
});
