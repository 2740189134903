import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './quad-temperature-lg.svg';
import md from './quad-temperature-md.svg';
import sm from './quad-temperature-sm.svg';
import component from './QuadTemperatureDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.QUAD_TEMPERATURE,
  label: 'Quad Temperatursensor',
  shortLabel: 'QuadTemp',
  icons: { sm, md, lg },
  defaultMetricNames: ['temperature1', 'temperature2', 'temperature3', 'temperature4', 'outsideTemperature'],
  importantMetricNames: ['temperature1', 'temperature2', 'temperature3', 'temperature4', 'outsideTemperature'],
  aggregationMetricNames: [
    {
      name: 'temperature1',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'temperature2',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'temperature3',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'temperature4',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'outsideTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  component,
}));
