









import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class SensorProperty extends Vue {
  @StringProp()
  public label?: string;
}
