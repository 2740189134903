import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-eed.svg?vue';

export const APP_EED = GroupRole.APP_EED;

export default option(APPS, async () => {
  return {
    name: APP_EED,
    label: 'EED',
    link: {
      to: { name: 'AppEED' },
      icon,
    },
    order: 1,
  };
});
