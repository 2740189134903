import { ChartPoint } from 'chart.js';

export interface Label {
  value: string | number | Date;
  label: string;
}

export enum LineChartType {
  CHARTJS = 'CHARTJS',
  ECHARTS = 'ECHARTS',
}

export enum ChartStyle {
  LINE = 'line',
  BAR = 'bar',
  STEP = 'step',
}

export const CHART_STYLE_OPTIONS = Object.freeze([
  { label: 'Line', value: ChartStyle.LINE },
  { label: 'Bar', value: ChartStyle.BAR },
  { label: 'Boolean Step', value: ChartStyle.STEP },
]);

export type ChartSet = { label: string; name: string; points: ChartPoint[]; color?: string };

export enum ChartAggregationInterval {
  MINUTE = 'minute',
  HOURLY = 'hour',
  DAILY = 'day',
  WEEKLY = 'week',
  MONTHLY = 'month',
  YEARLY = 'year',
}
