import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './heat-cost-allocator-lg.svg';
import md from './heat-cost-allocator-md.svg';
import sm from './heat-cost-allocator-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.HEAT_COST_ALLOCATOR,
  label: 'Heizkostenverteiler',
  shortLabel: 'Heizung',
  icons: { sm, md, lg },
  component: component('heat-cost-allocator/HeatCostAllocatorDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component('heat-cost-allocator/HeatCostAllocatorAggregationWidget.vue'),
  },
  defaultMetricNames: ['currentConsumption'],
  importantMetricNames: ['currentConsumption'],
  aggregationMetricNames: [
    {
      name: 'currentConsumption',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'flowTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.LAST],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  consumption: true,
}));
