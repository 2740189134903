








import { Component, Mixins } from 'vue-property-decorator';
import { StringProp, BooleanProp } from '@/util/prop-decorators';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import { DeviceRole } from '../../model';

@Component
export default class SpotMiniWidget extends Mixins(DeviceRoleMapMixin) {
  @BooleanProp()
  private readonly alert!: boolean;

  @StringProp()
  private readonly location!: string;

  @BooleanProp()
  private readonly selected!: boolean;

  @BooleanProp()
  private readonly noMetrics!: boolean;

  @StringProp(true)
  private readonly role!: string;

  private get deviceConfig(): DeviceRole | undefined {
    return this.deviceRoleMap[this.role];
  }
}
