import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { Route } from 'vue-router';
import { component } from '../component';
import { APP_SMOKE_DETECTOR } from './app.builder';

const uuidCheck = '([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';

export default option(ROUTES, async () => {
  return [
    {
      path: '/smoke-detector',
      name: 'AppSmokeDetector',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_SMOKE_DETECTOR },
    },
    {
      path: `/smoke-detector/:treeNodeId${uuidCheck}`,
      component: component('views/tree-node/TreeNodeView.vue'),
      props: true,
      meta: {
        app: APP_SMOKE_DETECTOR,
        key: (route: Route): string => `AppSmokeDetector/TreeNode?${route.params.treeNodeId}`,
      },
      children: [
        {
          path: `/smoke-detector/:treeNodeId${uuidCheck}`,
          name: 'AppSmokeDetector/TreeNode',
          component: component('views/tree-node/live/LiveView.vue'),
          props: true,
          meta: { app: APP_SMOKE_DETECTOR },
        },
        {
          path: 'protocols',
          name: 'AppSmokeDetector/TreeNode/Protocols',
          component: component('views/tree-node/protocols/ProtocolsView.vue'),
          props: true,
          meta: { app: APP_SMOKE_DETECTOR },
        },
        {
          path: 'spots',
          name: 'AppSmokeDetector/TreeNode/Spots',
          component: component('views/tree-node/spots/SpotsView.vue'),
          props: true,
          meta: { app: APP_SMOKE_DETECTOR },
        },
      ],
    },
  ];
});
