import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './motion-detector-lg.svg';
import md from './motion-detector-md.svg';
import sm from './motion-detector-sm.svg';
import component from './MotionDetectorDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.MOTION_DETECTOR,
  label: 'Bewegungsmelder',
  shortLabel: 'Bewegung',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['opened'],
  importantMetricNames: ['occupancy', 'batteryVoltage'],
}));
