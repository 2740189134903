






import { Debounce } from '@/util/debounce-decorator';
import { BooleanProp } from '@/util/prop-decorators';
import { Component, Vue, Model } from 'vue-property-decorator';

@Component
export default class Search extends Vue {
  @Model('update:query', { type: String, default: '' })
  private query!: string;

  @BooleanProp(false)
  private debounced!: boolean;

  private onUpdate(query: string): void {
    this.$emit('update:query', query);
  }

  @Debounce(500)
  private onUpdateDebounced(query: string): void {
    this.$emit('update:query', query);
  }

  private onUpdateMethod(query: string): void {
    return this.debounced ? this.onUpdateDebounced(query) : this.onUpdate(query);
  }
}
