
















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import glasbreakSensorLgBad from './glasbreak-sensor-lg-bad.svg';
import glasbreakSensorLgOk from './glasbreak-sensor-lg-ok.svg';

@Component
export default class GlasbreakSensorDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get broken(): boolean {
    return this.metrics?.discrete?.glasbreak?.value === '1';
  }

  private get backgroundImage(): string {
    if (!this.metrics?.discrete?.glasbreak) {
      return this.role?.icons?.lg || this.role?.icons?.md;
    }

    return this.broken ? glasbreakSensorLgBad : glasbreakSensorLgOk;
  }
}
