



























import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { LampCommandName } from '@/types/iot-portal';
import { Component, Mixins } from 'vue-property-decorator';
import {
  GatewayLightSwitchSpotWidgetSwitchLampMutation,
  GatewayLightSwitchSpotWidgetSwitchLampMutationVariables,
} from './__generated__/GatewayLightSwitchSpotWidgetSwitchLampMutation';
import switchLampMutation from './switch-lamp.gql';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';

@Component
export default class GatewayLightSwitchSpotWidget extends Mixins(SpotWidgetDataMixin) {
  private get gatewaySerial(): string | undefined {
    return this.spot.activeDeviceMount?.gateway?.serial;
  }

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private async switchLamp(data: unknown, local = false): Promise<void> {
    try {
      const serial = this.gatewaySerial;
      if (serial === undefined) {
        return;
      }

      await this.$apollo.mutate<
        GatewayLightSwitchSpotWidgetSwitchLampMutation,
        GatewayLightSwitchSpotWidgetSwitchLampMutationVariables
      >({
        mutation: switchLampMutation,
        variables: { input: { serial, command: LampCommandName.LIGHT_TRIGGER, local } },
      });

      if (local) {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: 'Einzelleuchte eingeschaltet', class: 'success' }],
        });
      } else {
        this.ADD_TOAST_MESSAGES({
          messages: [{ text: 'Licht eingeschaltet', class: 'success' }],
        });
      }
    } catch (e) {
      // eslint-disable-next-line no-console -- dont swallow errors
      console.error(e);

      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Schaltvorgang fehlgeschlagen', class: 'error' }],
      });
    }
  }
}
