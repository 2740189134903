



















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import closedImage from './window-contact-lg-closed.svg';

@Component
export default class WindowContactDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get backgroundImage(): string {
    if (this.metrics.discrete.opened?.value !== '1') {
      return closedImage;
    }

    return this.role.icons.lg || this.role.icons.md;
  }
}
