






import { Component, Vue } from 'vue-property-decorator';
import Cell from '@/features/ui/layout/Cell.global.vue';
import Flex from '@/features/ui/layout/Flex.global.vue';
import { StringProp } from '@/util/prop-decorators';

@Component({ components: { Cell, Flex }, inheritAttrs: false })
export default class LayoutDefault extends Vue {
  @StringProp()
  private readonly background?: string;
}
