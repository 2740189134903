import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './oilfox-lg.svg';
import md from './oilfox-md.svg';
import sm from './oilfox-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.OIL_FOX_TANK,
  label: 'Füllstandsanzeige Öltank',
  shortLabel: 'Öltankfüllstand',
  icons: { sm, md, lg },
  component: component('oilfox/OilfoxDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component('oilfox/OilfoxAggregationWidget.vue'),
  },
  defaultMetricNames: ['fillingLevelPercentage'],
  importantMetricNames: ['currentVolume'],
  aggregationMetricNames: [
    {
      name: 'currentVolume',
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
