import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './window-contact-lg.svg';
import md from './window-contact-md.svg';
import sm from './window-contact-sm.svg';
import component from './WindowContactDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.WINDOW_CONTACT,
  label: 'Fensterkontakt',
  shortLabel: 'Fenster',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['opened'],
  importantMetricNames: ['opened', 'tamper', 'batteryVoltage'],
  aggregationMetricNames: [
    {
      name: 'opened',
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
