import { AttributeConfigurationType } from '@/types/iot-portal';
import { CoreSpotMetricsPanelControlSpotQuery_spot_first_customer_attributeDefinitions_items } from '../components/spot-metrics-panel-control/__generated__/CoreSpotMetricsPanelControlSpotQuery';
import { MetricPointExtra } from './metrics';

export interface Calculation {
  metricName: string;
  metricValue: string;
  calculationType: string;
  calculationFactor: string;
  calculationType2?: string;
  calculationFactor2?: string;
  manufacturer: string;
  deviceRole: string;
  resultUnit: string;
  spotFilterIds: string[];
}

export type attributeDefinitionItem =
  CoreSpotMetricsPanelControlSpotQuery_spot_first_customer_attributeDefinitions_items;

export function calculatedMetrics(
  metrics: MetricPointExtra[],
  attributeDefinitions: attributeDefinitionItem[],
  objectIds: string[],
): MetricPointExtra[] {
  const result: MetricPointExtra[] = [];
  let calculations: JSONObject | null = null;

  const attributeDefinitionsWithCalculations = calculationAttributeDefinitions(objectIds, attributeDefinitions);

  if (attributeDefinitionsWithCalculations.length > 0) {
    const attribute = attributeDefinitionsWithCalculations[0];
    if (attribute.configuration.__typename === AttributeConfigurationType.CalculationAttributeConfiguration) {
      calculations = attribute.configuration.calculations;
    }
  }

  if (calculations) {
    metrics.forEach((metric) => {
      const calculatedMetric = calculatedMetricPoint(metric, calculations as JSONObject);
      if (calculatedMetric) {
        result.push(calculatedMetric);
      }
    });
  }

  return result;
}

// filter attribute definitions by objectId, spotId.. and which includes calculations.
export function calculationAttributeDefinitions(
  objectIds: string[],
  attributeDefinitions: attributeDefinitionItem[] | undefined,
): attributeDefinitionItem[] {
  const calculationAttributes = attributeDefinitions?.filter((item: attributeDefinitionItem) => {
    if (item.configuration.__typename === AttributeConfigurationType.CalculationAttributeConfiguration) {
      const spotFilterIds = item.configuration.calculations?.spotFilterIds as string[];
      // filter by object, or device/spot level.
      return spotFilterIds?.some((id) => objectIds.includes(id));
    }
  });
  return calculationAttributes ?? [];
}

export function calculateMetricValue(value: number | string, factor: number | string, operation: string): number {
  let result: number;
  value = typeof value === 'string' ? parseFloat(value) : value;
  factor = typeof factor === 'string' ? parseFloat(factor) : factor;
  switch (operation) {
    case '+':
      result = value + factor;
      break;
    case '-':
      result = value - factor;
      break;
    case '*':
      result = value * factor;
      break;
    case '/':
      result = value / factor;
      break;
    default:
      result = 0;
  }

  return Number(result.toFixed(2));
}

export function calculatedMetricPoint(metric: MetricPointExtra, calculations: JSONObject): MetricPointExtra | null {
  let metricPoint: MetricPointExtra | null = null;

  let calculatedValue = calculateMetricValue(
    metric.value || Number(metric.continuous),
    calculations.calculationFactor as number,
    calculations.calculationType as string,
  );
  const unit = calculations.resultUnit as string;

  // process secondary calculation if avaialble
  if (calculations.calculationFactor2 && calculations.calculationType2) {
    calculatedValue = calculateMetricValue(
      calculatedValue,
      calculations.calculationFactor2 as number,
      calculations.calculationType2 as string,
    );
  }

  if (metric.__typename === 'ContinuousMetricPoint') {
    metricPoint = {
      ...metric,
      name: `${metric.name}_calc`,
      value: calculatedValue,
      continuous: calculatedValue,
      unit,
    };
  } else if (metric.__typename === 'DiscreteMetricPoint') {
    metricPoint = {
      ...metric,
      name: `${metric.name}_calc`,
      value: calculatedValue.toString(),
      unit,
    };
  }

  return metricPoint;
}

export function checkMetricName(name: string): string {
  // remove appended "_calc" identifier on calculation metric name
  return name.includes('_calc') ? name.replace('_calc', '') : name;
}
