import { isFunction } from 'lodash';
import { VueConstructor, DirectiveOptions } from 'vue';

type Callback = (entry: ResizeObserverEntry) => unknown;
const emptySet = new Set<Callback>();

export default function registerResize(vue: VueConstructor, name = 'resize'): void {
  vue.directive(name, createDirective());
}

export function createDirective(): DirectiveOptions {
  const elementMap = new WeakMap<Element, Set<Callback>>();
  const observer = new ResizeObserver((entries) => {
    for (const entry of entries) {
      for (const callback of elementMap.get(entry.target) ?? emptySet) {
        callback(entry);
      }
    }
  });

  function addCallback(el: Element, value: unknown): void {
    if (!isFunction(value)) {
      return;
    }

    const callbackSet = elementMap.get(el) ?? (observer.observe(el), new Set());
    callbackSet.add(value);
    elementMap.set(el, callbackSet);
  }

  function removeCallback(el: Element, value: unknown): void {
    if (!isFunction(value)) {
      return;
    }

    const callbackSet = elementMap.get(el);
    if (callbackSet === undefined) {
      return;
    }

    callbackSet.delete(value);
    if (callbackSet.size > 0) {
      return;
    }

    elementMap.delete(el);
    observer.unobserve(el);
  }

  return {
    bind(el, { value }) {
      addCallback(el, value);
    },
    update(el, { value, oldValue }) {
      removeCallback(el, oldValue);
      addCallback(el, value);
    },
    unbind(el, { value }) {
      removeCallback(el, value);
    },
  };
}
