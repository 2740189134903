












import { ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { AsyncComponent, Component as ComponentInterface } from 'vue';
import { DeviceRole, TreeNodeIdFilter } from '../../model';
import DeviceRoleMapMixin from '../mixins/device-role-map';
import { CoreSpotRoleAggregationWidgetAdapterFragment } from './__generated__/CoreSpotRoleAggregationWidgetAdapterFragment';

@Component
export default class SpotRoleAggregationWidgetAdapter extends Mixins(DeviceRoleMapMixin) {
  @ObjectProp()
  private readonly aggregation?: CoreSpotRoleAggregationWidgetAdapterFragment;

  @ObjectProp()
  private readonly idFilter?: TreeNodeIdFilter;

  private get role(): DeviceRole | undefined {
    return this.deviceRoleMap[this.aggregation?.role ?? ''];
  }

  private get enabled(): boolean {
    return this.role?.aggregationWidget?.enabled === true;
  }

  private get component(): ComponentInterface | AsyncComponent | string {
    return this.role?.aggregationWidget?.component ?? 'domain-ui-spot-role-aggregation-widget';
  }

  private get props(): Record<string, unknown> {
    return this.role?.aggregationWidget?.props ?? {};
  }
}
