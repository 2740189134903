import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './door-contact-lg.svg';
import md from './door-contact-md.svg';
import sm from './door-contact-sm.svg';
import component from './DoorContactDevicePanelAdapter.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.DOOR_CONTACT,
  label: 'Türkontakt',
  shortLabel: 'Tür',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['opened', 'opened1', 'opened2'],
  importantMetricNames: ['opened', 'opened1', 'opened2'],
}));
