import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import component from '../temperature/TemperatureDevicePanel.vue';
import lg from './return-flow-lg.svg';
import md from './return-flow-md.svg';
import sm from './return-flow-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.THERMOCOUPLES_RETURN_FLOW,
  label: 'Rücklauftemperatur',
  shortLabel: 'Rücklauf',
  icons: { sm, md, lg },
  component,
  props: { temperatureColor: 'BLUE' },
}));
