import { Row, ProjectObjectListItem, Address, CustomerContact } from './models';
import { EnterpriseProjectBuildingStatus, TreeNodeClassification, TreeNodeType } from '@/types/iot-portal';

export default class ObjectListMapper {
  // eslint-disable-next-line complexity
  public mapToObjectList(rows: Row[]): ProjectObjectListItem[] {
    const objectList: ProjectObjectListItem[] = [];

    const directoryMap: Map<string, ProjectObjectListItem> = new Map();

    for (const row of rows) {
      const itemName = row.itemName;
      const buildingReferenceId = row.buildingReferenceId;

      const objectType = TreeNodeType.Property;

      const buildingType = this.determineBuildingType(row);

      if (!buildingType) {
        continue;
      }

      if (buildingType == TreeNodeClassification.Liegenschaft && !row.productUsageGroup) {
        // Liegenschaft need to specify this
        continue;
      }

      let address = this.determineAddress(row, buildingType);

      if (!address) {
        if (buildingType != TreeNodeClassification.Gebaude) {
          // Only Gebaude can have missing addresses
          continue;
        }

        const directoryForGBuilding = directoryMap.get(buildingReferenceId);

        if (!directoryForGBuilding) {
          // The Gebaude building has no address and no associated directory
          continue;
        }

        address = {
          streetAddress: directoryForGBuilding.streetAddress,
          houseAddress: directoryForGBuilding.houseAddress,
          postCodeAddress: directoryForGBuilding.postCodeAddress,
          cityAddress: directoryForGBuilding.cityAddress,
        };
      }

      const { streetAddress, houseAddress, postCodeAddress, cityAddress } = address;

      const customizedId: string | null = row.customizedId ?? null;
      const accessType: string | null = row.accessType ?? null;
      const productType: string | null = row.productType ?? null;
      const useCase: string | null = row.useCase ?? null;
      const numberOfApartments: number | null = this.parseNumberOfApartments(row.numberOfApartments);
      const customerContact: CustomerContact | undefined = this.parseCustomerContact(
        row.customerContactName,
        row.customerContactTelephoneNumber,
        row.customerContactEmailAddress,
      );

      objectList.push({
        itemName,
        buildingReferenceId,
        objectType,
        buildingType,
        streetAddress,
        houseAddress,
        postCodeAddress,
        cityAddress,
        customizedId,
        accessType,
        productType,
        useCase,
        numberOfApartments,
        customerContact,
      });

      if (buildingType == TreeNodeClassification.Liegenschaft && row.productUsageGroup) {
        // Also create directory based on the L building
        const directoryAddress = this.parseAddress(row.productUsageGroup);
        if (directoryAddress) {
          const directory: ProjectObjectListItem = {
            itemName: row.productUsageGroup,
            buildingReferenceId,
            objectType: TreeNodeType.Directory,
            buildingType: null,
            streetAddress: directoryAddress.streetAddress,
            houseAddress: directoryAddress.houseAddress,
            postCodeAddress: directoryAddress.postCodeAddress,
            cityAddress: directoryAddress.cityAddress,
            customizedId,
            accessType,
            productType,
            useCase,
            numberOfApartments,
          };

          directoryMap.set(directory.buildingReferenceId, directory);

          objectList.push(directory);
        }
      }
    }

    return objectList;
  }

  private determineAddress(row: Row, buildingType: TreeNodeClassification): Address | null {
    if (buildingType == TreeNodeClassification.Liegenschaft && row.mainBuildingAddress) {
      return this.parseAddress(row.mainBuildingAddress);
    } else if (buildingType == TreeNodeClassification.Gebaude && row.buildingAddress) {
      return this.parseAddress(row.buildingAddress);
    }

    return null;
  }

  private determineBuildingType(row: Row): TreeNodeClassification | null {
    if (row.mainBuildingAddress && row.productUsageGroup) {
      return TreeNodeClassification.Liegenschaft;
    } else if (row.buildingAddress) {
      return TreeNodeClassification.Gebaude;
    } else {
      return null;
    }
  }

  private parseAddress(address: string): Address | null {
    const splitAddressFields = address.split(',');

    if (splitAddressFields.length < 2) {
      return null;
    }

    const streetAddressAndHouseNumberPortion = splitAddressFields[0];
    const streetAddressAndHouseNumber = streetAddressAndHouseNumberPortion.split(' ');

    if (streetAddressAndHouseNumber.length < 2) {
      return null;
    }

    const houseAddressPortion = streetAddressAndHouseNumber[streetAddressAndHouseNumber.length - 1];
    const streetAddressCutOffIndex = streetAddressAndHouseNumberPortion.indexOf(houseAddressPortion);
    const houseAddress = houseAddressPortion.trim();
    const streetAddress = streetAddressAndHouseNumberPortion.substring(0, streetAddressCutOffIndex).trim();

    const postalAndCityAddress = splitAddressFields[1].trim().split(' ');

    if (postalAndCityAddress.length < 2) {
      return null;
    }

    const postCodeAddress = postalAndCityAddress[0];
    const cityAddress = postalAndCityAddress[1];

    return { streetAddress, houseAddress, postCodeAddress, cityAddress };
  }

  private parseNumberOfApartments(rawValue: string | undefined): number | null {
    if (!rawValue) {
      return null;
    }

    const parsedNumber = parseInt(rawValue);
    if (isNaN(parsedNumber) || !Number.isInteger(parsedNumber)) {
      return null;
    }

    return parsedNumber;
  }

  private parseCustomerContact(
    fullName: string | undefined,
    phoneNumber: string | undefined,
    email: string | undefined,
  ): CustomerContact | undefined {
    if (!fullName) {
      return undefined;
    }

    const nameParts = fullName.split(' ');
    let title: string | null;
    let firstName: string;
    let surname: string;

    if (nameParts.length > 2) {
      title = nameParts[0];
      firstName = nameParts[1];
      surname = nameParts[2];
    } else if (nameParts.length == 2) {
      // Assume title has been omitted
      title = null;
      firstName = nameParts[0];
      surname = nameParts[1];
    } else {
      // assume the entry is both first name and surname
      title = null;
      firstName = fullName;
      surname = fullName;
    }

    const contactPhoneNumber = phoneNumber || null;
    const contactEmailAddress = email || null;

    return { title, firstName, surname, phoneNumber: contactPhoneNumber, email: contactEmailAddress };
  }
}
