import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { APP_ENTERPRISE_PROJECT } from './app.builder';
import { component } from '../component';
import { Route } from 'vue-router';
const uuidCheck = '([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';

export default option(ROUTES, async () => {
  return [
    {
      path: '/enterprise-project',
      name: 'AppEnterpriseProject',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_ENTERPRISE_PROJECT },
      beforeEnter: (to, from, next) => {
        next({
          name: 'AppEnterpriseProject/EnterpriseProjects',
        });
      },
    },
    {
      path: `/enterprise-project/project-list`,
      name: 'AppEnterpriseProject/EnterpriseProjects',
      component: component('views/enterprise-project/EnterpriseProjectsView.vue'),
      props: true,
      meta: { app: APP_ENTERPRISE_PROJECT },
    },
    {
      path: `/enterprise-project/:projectReference/details`,
      name: 'AppEnterpriseProject/ProjectDetails',
      component: component('views/enterprise-project-details/EnterpriseProjectDetailsView.vue'),
      props: true,
      meta: { app: APP_ENTERPRISE_PROJECT },
    },
    {
      path: `/enterprise-project/create`,
      name: 'AppEnterpriseProject/ProjectCreate',
      component: component('views/enterprise-project-create/EnterpriseProjectCreate.vue'),
      props: true,
      meta: { app: APP_ENTERPRISE_PROJECT },
    },
    {
      path: `/enterprise-project/:projectReference/edit`,
      name: 'AppEnterpriseProject/ProjectDraftEdit',
      component: component('views/enterprise-project-create/EnterpriseProjectCreate.vue'),
      props: true,
      meta: { app: APP_ENTERPRISE_PROJECT },
    },
  ];
});
