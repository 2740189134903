import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './dual-water-lg.svg';
import md from './dual-water-md.svg';
import sm from './dual-water-sm.svg';
import component from './DualWaterDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.DUAL_WATER,
  label: 'Dualer Warmwassersensor',
  shortLabel: 'Warmwasser',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['temperature1'],
  importantMetricNames: ['temperature1', 'temperature2'],
}));
