import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './aufzughelden-device-lg.svg';
import md from './aufzughelden-device-md.svg';
import sm from './aufzughelden-device-sm.svg';
import { AufzugheldenMetricNames } from './constants';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.AUFZUGHELDEN_DEVICE,
  label: 'Aufzughelden Aufzug',
  shortLabel: 'Aufzug',
  icons: { sm, md, lg },
  component: component('aufzughelden-device/AufzugheldenDeviceSpotWidget.vue'),
  defaultMetricNames: [
    AufzugheldenMetricNames.DoorClosings,
    AufzugheldenMetricNames.DoorCycles,
    AufzugheldenMetricNames.DoorOpenings,
    AufzugheldenMetricNames.LiftRuns,
  ],
  importantMetricNames: [
    AufzugheldenMetricNames.LiftRuns,
    AufzugheldenMetricNames.DoorCycles,
    AufzugheldenMetricNames.DoorOpenings,
    AufzugheldenMetricNames.DoorClosings,
  ],
  aggregationMetricNames: [
    {
      name: AufzugheldenMetricNames.DoorClosings,
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: AufzugheldenMetricNames.DoorCycles,
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: AufzugheldenMetricNames.DoorOpenings,
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: AufzugheldenMetricNames.LiftRuns,
      aggregators: [MetricResolutionAggregator.SUM],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  graphWidget: {
    enabled: true,
    component: component('aufzughelden-device/AufzugheldenDeviceSpotGraphWidget.vue'),
  },
}));
