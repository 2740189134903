import { MetricDescriptorInput } from '@/types/iot-portal';
import Ticker from '@/util/ticker';
import { keyBy } from 'lodash';
import moment, { duration, Moment, unitOfTime } from 'moment';
import { Component, Vue } from 'vue-property-decorator';
import { MetricPoint, normalizeMetricPoint } from '../../util/metrics';
import query from './metric-time-frames.gql';
import { CoreMetricTimeFramesMixinMetricFragment } from './__generated__/CoreMetricTimeFramesMixinMetricFragment';
import {
  CoreMetricTimeFramesMixinQuery,
  CoreMetricTimeFramesMixinQueryVariables,
} from './__generated__/CoreMetricTimeFramesMixinQuery';

export interface TimeFrame {
  name: string;
  referenceDate: (now: Moment) => Moment;
  descriptor: (referenceDate: Moment) => MetricDescriptorInput;
  label: (referenceDate: Moment) => string;
}

function createTimeFrameFactory(
  name: string,
  startOfUnit: unitOfTime.StartOf,
  intervalLength: string,
  format: string,
): (metricName: string) => TimeFrame {
  const interval = duration(intervalLength);

  return (metricName) => ({
    name,
    referenceDate: (now) => now.clone().startOf(startOfUnit).subtract(interval),
    descriptor: (referenceDate) => ({
      name: metricName,
      resolution: { intervalLength },
      start: referenceDate.clone().subtract(interval).toISOString(),
      stop: referenceDate.clone().add(interval).toISOString(),
      take: 2,
    }),
    label: (referenceDate) => referenceDate.format(format),
  });
}

export const timeFrameYesterday = createTimeFrameFactory('YESTERDAY', 'day', 'P1D', '[Gestern] L');
export const timeFrameLastWeek = createTimeFrameFactory('LAST_WEEK', 'isoWeek', 'P1W', '[KW]WW/YYYY');
export const timeFrameLastMonth = createTimeFrameFactory('LAST_MONTH', 'month', 'P1M', 'MMMM YYYY');
export const timeFrameLastYear = createTimeFrameFactory('LAST_YEAR', 'year', 'P1Y', 'YYYY');

const ticker = new Ticker(600000, () => moment());

@Component({
  apollo: {
    metricTimeFrameMetrics: {
      query,
      skip(this: MetricTimeFramesMixin): boolean {
        return this.now === undefined || this.spotId === undefined || this.metricTimeFrameDescriptors.length === 0;
      },
      variables(this: MetricTimeFramesMixin): CoreMetricTimeFramesMixinQueryVariables {
        return {
          spotId: this.spotId ?? '',
          descriptors: this.metricTimeFrameDescriptors,
        };
      },
    },
  },
  data() {
    return { metricTimeFrameMetrics: undefined, now: undefined };
  },
})
export class MetricTimeFramesMixin extends Vue {
  protected readonly metricTimeFrameMetrics?: CoreMetricTimeFramesMixinQuery['metricTimeFrameMetrics'];

  private now?: Moment;

  protected get spotId(): string | undefined {
    return undefined;
  }

  protected get metricTimeFrames(): TimeFrame[] {
    return [];
  }

  protected get metricTimeFrameDescriptors(): MetricDescriptorInput[] {
    const now = this.now;

    return now === undefined
      ? []
      : this.metricTimeFrames.map(({ name, referenceDate, descriptor }) => ({
          ...descriptor(referenceDate(now)),
          reference: name,
        })) ?? [];
  }

  protected get metricTimeFramePoints(): { label: string; points: MetricPoint[] }[] {
    const now = this.now;
    if (now === undefined) {
      return [];
    }

    const metricsByName = keyBy(
      this.metricTimeFrameMetrics?.first.metrics,
      (metric) => metric.descriptor.reference,
    ) as Record<string, CoreMetricTimeFramesMixinMetricFragment>;

    return this.metricTimeFrames.map(({ name, referenceDate, label }) => {
      // @ts-expect-error TS limitation
      const points: MetricPoint[] = metricsByName[name]?.points.map((point) => normalizeMetricPoint(point));

      return { label: label(referenceDate(now)), points };
    });
  }

  private created(): void {
    let unschedule: undefined | (() => void);

    this.$watch(
      () => this.metricTimeFrames.length > 0,
      (live) => {
        if (!live) {
          if (unschedule) {
            this.$off('hook:beforeDestroy', unschedule);
            unschedule();
            unschedule = undefined;
          }

          return;
        }

        if (!this.now) {
          this.now = moment();
        }

        if (!unschedule) {
          unschedule = ticker.schedule((now) => (this.now = now));
          this.$once('hook:beforeDestroy', unschedule);
        }
      },
      { immediate: true },
    );
  }
}
