import {
  GroupRole,
  MetricResolutionAggregator,
  MetricResolutionTimeSource,
  SpotRole,
  TreeNodeType,
} from '@/types/iot-portal';
import { AsyncComponent, Component } from 'vue';
import { RawLocation, Route } from 'vue-router';
import { TreeNodeLocationGenerator } from './router/tree-node-location-generator';
import { CoreModelTreeNodeIdFilterVariables } from './__generated__/CoreModelTreeNodeIdFilter';

export class LoginRequired extends Error {}

export type AppName = GroupRole;

export interface App {
  name: AppName;
  label?: string;
  activatableApp?: boolean;
  link: {
    to: RawLocation;
    icon: string | Component;
    [key: string]: unknown;
  };
  hiddenForApps?: string[];
  shownForApps?: string[];
  types?: TreeNodeType[];
  treeNodeBarComponent?: Component | AsyncComponent;
  generateTreeNodeLocation?: TreeNodeLocationGenerator;
  getActiveTreeNodeId?: (route: Route) => string | undefined;
  order?: number;
}

export function appOrderCompare(a: App, b: App): number {
  return (a.order || 0) - (b.order || 0) || (a.label || a.name).localeCompare(b.label || b.name);
}

export interface DeviceRoleAggregationMetric {
  name: string;
  aggregators: MetricResolutionAggregator[];
  timeSource?: MetricResolutionTimeSource;
}

export interface DeviceRole {
  name: SpotRole;
  label: string;
  shortLabel: string;
  icons: {
    sm?: string;
    md: string;
    lg?: string;
  };
  importantMetricNames?: string[];
  aggregationMetricNames?: DeviceRoleAggregationMetric[];
  defaultMetricNames?: string[];
  /** true, if this is a consumption category role */
  consumption?: boolean;
  component: Component | AsyncComponent;
  props?: Record<string, unknown>;
  externalDetailsLink?: string;
  aggregationWidget?: {
    enabled: boolean;
    component?: Component | AsyncComponent;
    props?: Record<string, unknown>;
  };
  graphWidget?: {
    enabled: boolean;
    component?: Component | AsyncComponent;
    props?: Record<string, unknown>;
  };
  graphComponent?: {
    enabled: boolean;
    component?: Component | AsyncComponent;
    props?: Record<string, unknown>;
  };
  metricComponent?: {
    enabled: boolean;
    component?: Component | AsyncComponent;
    props?: Record<string, unknown>;
  };
}

export interface Manufacturer {
  name: string;
  label: string;
  image?: string;
}

export type TreeNodeIdFilter = CoreModelTreeNodeIdFilterVariables;
