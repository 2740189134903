










import { Vue, Component } from 'vue-property-decorator';
import { ToastMessage } from './model';
import { RootMutation, RootState } from '@/features/core/store';

@Component
export default class Toaster extends Vue {
  @RootState
  private readonly toastMessages!: ToastMessage[];

  @RootMutation
  private readonly REMOVE_TOAST_MESSAGES!: (messages: ToastMessage[]) => void;

  private close(message: ToastMessage): void {
    this.REMOVE_TOAST_MESSAGES([message]);
  }
}
