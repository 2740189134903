















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Component, Mixins } from 'vue-property-decorator';
import backgroundImage from './nuki-box-lg.svg';
import unlockNukiBoxMutation from './unlock-nuki-box.gql';
import {
  NukiBoxUnlockNukiBoxMutation,
  NukiBoxUnlockNukiBoxMutationVariables,
} from './__generated__/NukiBoxUnlockNukiBoxMutation';

@Component
export default class NukiBoxSpotWidgetControl extends Mixins(SpotWidgetDataMixin) {
  private readonly backgroundImage = backgroundImage;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private async unlock(): Promise<void> {
    await this.$apollo.mutate<NukiBoxUnlockNukiBoxMutation, NukiBoxUnlockNukiBoxMutationVariables>({
      mutation: unlockNukiBoxMutation,
      variables: { input: { spotId: this.spot.id } },
    });

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Geöffnet', class: 'success' }],
    });
  }
}
