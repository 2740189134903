








import { StringProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Iframe extends Vue {
  @StringProp()
  private src!: string;

  private loaded = false;
}
