import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './heat-meter-temperature-lg.svg';
import md from './heat-meter-temperature-md.svg';
import sm from './heat-meter-temperature-sm.svg';
import component from './HeatMeterTemperatureDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.HEAT_METER_TEMPERATURE,
  label: 'Vorlauf/Rücklauf',
  shortLabel: 'Vorlauf/Rücklauf',
  icons: { sm, md, lg },
  defaultMetricNames: ['currentVolume'],
  importantMetricNames: ['currentEnergy', 'currentFlow', 'lastMonthVolume'],
  aggregationMetricNames: [
    {
      name: 'currentEnergy',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'currentFlow',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'lastMonthVolume',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  component,
}));
