import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './occupancy-detector-lg.svg';
import md from './occupancy-detector-md.svg';
import sm from './occupancy-detector-sm.svg';
import component from './OccupancyDetectorDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.OCCUPANCY_DETECTOR,
  label: 'Belegung',
  shortLabel: 'Belegung',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['occupancy'],
  importantMetricNames: ['occupancy'],
}));
