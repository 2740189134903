









import { EnumProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class DeviceStateIndicator extends Vue {
  @EnumProp(true, 'RED', 'GREEN', 'GRAY')
  private readonly state!: 'RED' | 'GREEN' | 'GRAY';

  @BooleanProp()
  private readonly pushDown!: boolean;
}
