import { ArrayProp, BooleanProp, StringProp } from '@/util/prop-decorators';
import { Component, Model, Vue } from 'vue-property-decorator';
import Search from './Search.vue';
import TreeNodeList from './TreeNodeList.vue';
import { CoreTreeNodeBarControlTreeNodeFragment } from './__generated__/CoreTreeNodeBarControlTreeNodeFragment';

@Component({ components: { Search, TreeNodeList } })
export default class TreeNodeBarMixin extends Vue {
  @Model('update:searchQuery', { type: String, default: '' })
  private readonly searchQuery!: string;

  @ArrayProp(() => [])
  private readonly treeNodes!: CoreTreeNodeBarControlTreeNodeFragment[];

  @StringProp()
  private readonly emptyMessage?: string;

  @BooleanProp()
  private readonly loading?: boolean;
}
