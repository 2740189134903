import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './water-lg.svg';
import md from './water-md.svg';
import sm from './water-sm.svg';
import component from './WaterDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.WATER,
  label: 'Warmwasser',
  shortLabel: 'Warmwasser',
  icons: { sm, md, lg },
  component,
  props: { temperatureColor: 'RED' },
  defaultMetricNames: ['temperature'],
  importantMetricNames: ['temperature', 'batteryLow'],
}));
