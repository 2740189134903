import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-user-account.svg?vue';

export const APP_USER_ACCOUNT = GroupRole.APP_USER_ACCOUNT;

export default option(APPS, async () => {
  return {
    name: APP_USER_ACCOUNT,
    label: 'Persönliche Einstellungen',
    link: {
      to: { name: 'AppUserAccount' },
      icon,
    },
    order: Number.POSITIVE_INFINITY,
  };
});
