import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import AppView from '../views/app/AppView.vue';
import { APP_SUPPORT } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/support',
      name: 'AppSupport',
      component: AppView,
      meta: { app: APP_SUPPORT },
    },
  ];
});
