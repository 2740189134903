import { KeycloakInstance } from 'keycloak-js';
import { cloneDeep } from 'lodash';
import { RootStore, SET_ID_TOKEN, SET_REALM_ROLES } from './create-store';

export interface IdToken extends Keycloak.KeycloakTokenParsed {
  name: string;
  email: string;
  admin?: boolean;
  roles: string[];
}

export function setupIdTokenSync(store: RootStore, keycloak: KeycloakInstance): void {
  const setIdToken = (): void => {
    store.commit(
      SET_ID_TOKEN,
      keycloak.idTokenParsed ? { name: '', email: '', roles: [], ...cloneDeep(keycloak.idTokenParsed) } : undefined,
    );
  };

  keycloak.onAuthSuccess = setIdToken;
  keycloak.onAuthRefreshSuccess = setIdToken;

  setIdToken();
}

export function setupRealmRolesSync(store: RootStore, keycloak: KeycloakInstance): void {
  const setRealmRoles = (): void => {
    store.commit(SET_REALM_ROLES, keycloak.realmAccess?.roles);
  };

  setRealmRoles();
}
