import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './gateway-light-switch-lg.svg';
import md from './gateway-light-switch-md.svg';
import sm from './gateway-light-switch-sm.svg';
import component from './GatewayLightSwitchSpotWidget.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.GATEWAY_LIGHT_SWITCH,
  label: 'Lichtschalter (Gateway)',
  shortLabel: 'Licht',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['online'],
  importantMetricNames: ['online', 'occupancy'],
}));
