import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-help.svg?vue';

export const APP_HELP = GroupRole.APP_HELP;

export default option(APPS, async () => {
  return {
    name: APP_HELP,
    label: 'Help',
    link: {
      to: { name: 'AppHelp' },
      icon,
      class: 'push-down',
    },
    order: Number.POSITIVE_INFINITY,
  };
});
