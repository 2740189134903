































import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { ContinuousMetricPoint } from '@/features/core/util/metrics';
import { range } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import bgImage from './dual-water-lg.svg';

interface WaterMetric {
  index: number;
  name: string;
  point?: ContinuousMetricPoint;
  serial?: string;
  position: number;
}

@Component
export default class DualWaterDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private get waterMetrics(): WaterMetric[] {
    return range(1, 3)
      .map((index) => ({
        index,
        name: `water${index}`,
        point: this.metrics.continuous[`water${index}`],
        serial: this.metrics.discrete[`externalProbeSerialNumber${index}`]?.value,
        position:
          this.spot?.metricConfigurations.find(({ name }) => name === `water${index}`)?.position ?? Number.MAX_VALUE,
      }))
      .sort((a, b) => a.position - b.position || a.index - b.index);
  }

  private backgroundImage = bgImage;
}
