var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-flex',[_c('ui-cell',{staticClass:"header",attrs:{"basis":1}},[_vm._v("Daten & Zeiträume")]),_c('ui-cell',{staticClass:"subheader",attrs:{"basis":1}},[_vm._v("In diesem Bereich können Sie Daten eingrenzen und aggregieren.")]),_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-date',_vm._b({attrs:{"value":_vm.startDate,"monday-first":true,"format":function (date) { return (date.isSame(new Date(), 'day') ? 'Aktuell' : date.format('L')); },"placeholder":"Von"},on:{"update":function($event){return _vm.onUpdateStartDate($event)}}},'ui-input-date',_vm.datePickerStartAttrs,false))],1),_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-date',_vm._b({attrs:{"value":_vm.stopDate,"disabled":_vm.aggregationInterval === 'P1W',"monday-first":true,"format":function (date) { return (date.isSame(new Date(), 'day') ? 'Aktuell' : date.format('L')); },"placeholder":"Aktuell"},on:{"update":function($event){return _vm.onUpdateStopDate($event)}}},'ui-input-date',_vm.datePickerStopAttrs,false))],1),_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-select',{attrs:{"value":_vm.aggregator,"options":_vm.metricAggregatorOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Aggregationsfunktion"},on:{"update":function($event){return _vm.updateAggregator($event)}}})],1),_c('ui-cell',{attrs:{"basis":_vm.inputCellBasis,"min":250,"max":280}},[_c('ui-input-select',{ref:"aggregationIntervalRef",attrs:{"value":_vm.aggregationInterval,"options":_vm.aggregationIntervalOptions,"reduce":function (ref) {
	var value = ref.value;

	return value;
},"placeholder":"Aggregationsintervall"},on:{"update":function($event){return _vm.updateAggregationInterval($event)}}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }