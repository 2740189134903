var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ui-spot-widget',_vm._b({attrs:{"background-image":_vm.backgroundImage},scopedSlots:_vm._u([{key:"actions",fn:function(){return [_vm._t("actions")]},proxy:true},{key:"properties",fn:function(){return [_vm._t("properties")]},proxy:true},{key:"overlay",fn:function(){return [_vm._t("overlay")]},proxy:true},{key:"content",fn:function(){return [_c('div',{staticClass:"values"},_vm._l((_vm.temperatureMetrics),function(ref){
var index = ref.index;
var name = ref.name;
var point = ref.point;
return _c('div',{key:index,staticClass:"value"},[_c('domain-ui-metric-point',{attrs:{"point":point,"spot":_vm.spot,"format-options":{ decimalsRound: 0 },"inline":""},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var formattedValue = ref.formattedValue;
return [_c('div',{staticClass:"temperature"},[_vm._v(_vm._s(formattedValue))]),_c('domain-ui-metric',{staticClass:"place",attrs:{"name":name,"spot":_vm.spot},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var shortLabel = ref.shortLabel; if ( shortLabel === void 0 ) shortLabel = "T" + index;
return [_vm._v(_vm._s(shortLabel))]}}],null,true)})]}}],null,true)})],1)}),0)]},proxy:true},{key:"footer",fn:function(){return _vm._l((_vm.temperatureMetrics),function(ref){
var index = ref.index;
var name = ref.name;
var serial = ref.serial;
return _c('ui-spot-widget-data',{key:index},[_c('domain-ui-metric',{attrs:{"name":name,"spot":_vm.spot},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var name = ref.name;
var label = ref.label;
return [(serial === undefined)?[_vm._v("Keine Seriennummer für "+_vm._s(label || name)+" verfügbar")]:[_vm._v(_vm._s(label || name)+" Seriennummer: "+_vm._s(serial))]]}}],null,true)})],1)})},proxy:true}],null,true)},'ui-spot-widget',_vm.$attrs,false))}
var staticRenderFns = []

export { render, staticRenderFns }