






















import DevicePanel from '@/components/sensor/DevicePanel.vue';
import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { ContinuousMetricPoint } from '@/features/core/util/metrics';
import { Component, Mixins } from 'vue-property-decorator';

@Component({
  components: {
    DevicePanel,
  },
})
export default class HeatMeterTemperatureDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private formatTemperature(point?: ContinuousMetricPoint): string {
    return point ? point.value.toLocaleString(undefined, { maximumFractionDigits: 0 }) : 'XX';
  }
}
