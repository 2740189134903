





import { toLength } from '@/util/css-length';
import { BooleanProp, EnumProp, LengthProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';

@Component
export default class Flex extends Vue {
  @BooleanProp()
  private readonly root?: boolean;
  @BooleanProp()
  private readonly center?: boolean;
  @EnumProp(false, 'flex-start', 'flex-end', 'center', 'space-around', 'space-between')
  private readonly justify?: string;
  @EnumProp(false, 'flex-start', 'flex-end', 'center', 'space-around', 'space-between', 'stretch')
  private readonly align?: string;
  @EnumProp(false, 'flex-start', 'flex-end', 'center', 'baseline', 'stretch')
  private readonly items?: string;
  @EnumProp('row', 'column')
  private readonly direction!: string;
  @BooleanProp()
  private readonly stretch!: boolean;
  @BooleanProp()
  private readonly nowrap!: boolean;
  @LengthProp()
  public gap?: string | number;

  private get style(): Record<string, string | undefined> {
    const center = this.center ? 'center' : undefined;
    return {
      justifyContent: this.justify || center,
      alignContent: this.align,
      alignItems: this.items || center,
      margin: this.root ? '0' : undefined,
      flexDirection: this.direction,
      gap: toLength(this.gap),
    };
  }
}
