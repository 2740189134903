import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './act-aqua-pipe-lg.svg';
import md from './act-aqua-pipe-md.svg';
import sm from './act-aqua-pipe-sm.svg';
import component from './ActAquaPipeDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.ACT_AQUA_PIPE,
  label: 'Act Aqua Pipe',
  shortLabel: 'Act Aqua Pipe',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['meanReservoirTemperature'],
  importantMetricNames: ['meanCirculationTemperature', 'meanReservoirTemperature', 'systemFlow'],
  aggregationMetricNames: [
    {
      name: 'applianceSupplyTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'returnTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'systemFlow',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
