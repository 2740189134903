





























import { BooleanProp, OptionalProp, StringProp, ObjectProp } from '@/util/prop-decorators';
import {
  ConnectionStatus,
  ConnectionStatusProp,
  BatteryStatus,
  BatteryStatusProp,
} from '@/components/sensor/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import ArrowDown from './images/arrow-down.svg?vue';
import SensorBatteryStatus from './SensorBatteryStatus.vue';
import { Component as ComponentType } from 'vue';
import { isString } from '@/util/lang';

@Component({
  components: {
    ArrowDown,
    SensorBatteryStatus,
  },
})
export default class DevicePanel extends Vue {
  @ObjectProp()
  private imageProps?: Record<string, unknown>;

  @ConnectionStatusProp('NONE')
  public connectionStatus!: ConnectionStatus;

  @BatteryStatusProp('NONE')
  public batteryStatus!: BatteryStatus;

  @BooleanProp()
  public alarm!: boolean;

  @BooleanProp()
  public danger!: boolean;

  @StringProp(true)
  public headline!: string;

  @StringProp()
  public description?: string;

  @StringProp()
  public backgroundImageClass?: string;

  @OptionalProp()
  public backgroundImage?: string | ComponentType;

  @BooleanProp()
  public overlay!: boolean;

  public dropdown = false;

  public properties = false;

  public get background(): boolean {
    return this.backgroundImage !== undefined;
  }

  public get backgroundStyle(): boolean {
    return isString(this.backgroundImage);
  }

  public get backgroundImageStyle(): string | undefined {
    return this.properties || !isString(this.backgroundImage)
      ? undefined
      : `background-image: url(${this.backgroundImage});`;
  }

  public onOutsideClick(event: Event): void {
    if (!this.dropdown) {
      return;
    }

    this.dropdown = false;

    event.stopImmediatePropagation();
    event.preventDefault();
  }

  public onInsideClick(event: Event): void {
    const element = event.target as HTMLElement;
    if (element.className === 'backdrop') {
      this.dropdown = false;
    }
  }
}
