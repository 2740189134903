






















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { EnumProp } from '@/util/prop-decorators';
import { ApolloQueryResult } from 'apollo-client';
import { Component, Mixins } from 'vue-property-decorator';
import locationQuery from './location.gql';
import bgImage from './outside-temperature-lg.svg';
import {
  DeviceRolesOutsideTemperatureLocationquery,
  DeviceRolesOutsideTemperatureLocationqueryVariables,
  DeviceRolesOutsideTemperatureLocationquery_spots_first_path_first_Property,
} from './__generated__/DeviceRolesOutsideTemperatureLocationquery';

type Location = DeviceRolesOutsideTemperatureLocationquery_spots_first_path_first_Property['location'];

@Component({
  apollo: {
    spots: {
      query: locationQuery,
      variables(this: OutsideTemperatureDevicePanel): DeviceRolesOutsideTemperatureLocationqueryVariables {
        return { id: this.spot.id };
      },
      manual: true,
      result(
        this: OutsideTemperatureDevicePanel,
        { data }: ApolloQueryResult<DeviceRolesOutsideTemperatureLocationquery>,
      ): void {
        this.location =
          data.spots.first.path.first.__typename === 'Property' ? data.spots.first.path.first.location : undefined;
      },
    },
  },
  data() {
    return {
      location: undefined,
    };
  },
})
export default class OutsideTemperatureDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private backgroundImage = bgImage;

  private location?: Location;

  @EnumProp('DEFAULT', 'RED', 'BLUE')
  private readonly temperatureColor!: string;
}
