














import { Component, Mixins } from 'vue-property-decorator';
import TreeNode from './TreeNode.vue';

@Component
export default class EmphasizedTreeNode extends Mixins(TreeNode) {}
