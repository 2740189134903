import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import AppView from '../views/app/AppView.vue';
import { APP_SALES } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/sales',
      name: 'AppSales',
      component: AppView,
      meta: { app: APP_SALES },
    },
  ];
});
