import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { component } from '../component';
import { APP_NOTIFICATION } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: `/notifications`,
      component: component('views/app/AppView.vue'),
      meta: { app: APP_NOTIFICATION, key: (): string => 'AppNotification' },
      children: [
        {
          path: `/notifications`,
          name: 'AppNotification',
          component: component('views/app/notifications/NotificationsView.vue'),
          meta: { app: APP_NOTIFICATION },
        },
      ],
    },
  ];
});
