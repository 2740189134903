



















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { Component, Mixins } from 'vue-property-decorator';
import lockNukiSmartlockMutation from './lock-nuki-smartlock.gql';
import backgroundImage from './nuki-smartlock-lg.svg';
import unlockNukiSmartlockMutation from './unlock-nuki-smartlock.gql';
import {
  NukiSmartlockLockNukiSmartlockMutation,
  NukiSmartlockLockNukiSmartlockMutationVariables,
} from './__generated__/NukiSmartlockLockNukiSmartlockMutation';
import {
  NukiSmartlockUnlockNukiSmartlockMutation,
  NukiSmartlockUnlockNukiSmartlockMutationVariables,
} from './__generated__/NukiSmartlockUnlockNukiSmartlockMutation';

@Component
export default class NukiSmartlockSpotWidgetControl extends Mixins(SpotWidgetDataMixin) {
  private readonly backgroundImage = backgroundImage;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private async unlock(): Promise<void> {
    await this.$apollo.mutate<
      NukiSmartlockUnlockNukiSmartlockMutation,
      NukiSmartlockUnlockNukiSmartlockMutationVariables
    >({
      mutation: unlockNukiSmartlockMutation,
      variables: { input: { spotId: this.spot.id } },
    });

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Geöffnet', class: 'success' }],
    });
  }

  private async lock(): Promise<void> {
    await this.$apollo.mutate<NukiSmartlockLockNukiSmartlockMutation, NukiSmartlockLockNukiSmartlockMutationVariables>({
      mutation: lockNukiSmartlockMutation,
      variables: { input: { spotId: this.spot.id } },
    });

    this.ADD_TOAST_MESSAGES({
      messages: [{ text: 'Abgeschlossen', class: 'success' }],
    });
  }
}
