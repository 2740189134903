import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import component from './FroniusInverterDevicePanel.vue';
import lg from './fronius-inverter-lg.svg';
import md from './fronius-inverter-md.svg';
import sm from './fronius-inverter-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.FRONIUS_INVERTER,
  label: 'Fronius Wechselrichter',
  shortLabel: 'Fronius Wechselrichter',
  icons: { sm, md, lg },
  component,
}));
