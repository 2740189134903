import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './dormakaba-component-lg.svg';
import md from './dormakaba-component-md.svg';
import sm from './dormakaba-component-sm.svg';
import component from './DormakabaComponentSpotWidgetControl.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.DORMAKABA_COMPONENT,
  label: 'dormakaba Tür',
  shortLabel: 'Tür',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['ready'],
  importantMetricNames: ['ready'],
}));
