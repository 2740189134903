import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-support.svg?vue';

export const APP_SUPPORT = GroupRole.APP_SUPPORT;

export default option(APPS, async () => {
  return {
    name: APP_SUPPORT,
    label: 'Hilfe & Problembehandlung',
    link: {
      to: { name: 'AppSupport' },
      icon,
    },
    order: 5,
  };
});
