import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './air-quality-lg.svg';
import md from './air-quality-md.svg';
import sm from './air-quality-sm.svg';
import component from './AirQualityDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.AIR_QUALITY,
  label: 'Air Quality',
  shortLabel: 'Air Quality',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['humidity'],
  importantMetricNames: ['voc', 'temperature', 'humidity'],
  aggregationMetricNames: [
    {
      name: 'voc',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'humidity',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
