import { VueConstructor, DirectiveOptions } from 'vue';

export default function registerFocus(vue: VueConstructor, name = 'focus'): void {
  vue.directive(name, createDirective());
}

export function createDirective(): DirectiveOptions {
  return {
    inserted: function (el) {
      // Focus on the element
      el.focus();
    },
  };
}
