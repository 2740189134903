import { API_HEATING_SYSTEM_COLLECTOR_CLIENT, KEYCLOAK } from '.';
import { provide } from '@/util/container';
import { setContext } from 'apollo-link-context';
import { LoginRequired } from '../model';
import { createUploadLink } from 'apollo-upload-client';
import { API_HEATING_SYSTEM_COLLECTOR_ENDPOINT } from '@/env';
import { from } from 'apollo-link';
import { InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { withScalars } from 'apollo-link-scalars';
import { IntrospectionQuery, buildClientSchema } from 'graphql';
import { Moment } from 'moment';
import ApolloClient from 'apollo-client';
import introspection from '@/types/hsc-api.generated.json';
import introspectionQueryResultData from '@/types/hsc-api-fragment-matcher.generated.json';

export default provide(API_HEATING_SYSTEM_COLLECTOR_CLIENT, async (get) => {
  // Retrieve Keycloak instance for authentication
  const keycloak: Keycloak.KeycloakInstance = await get(KEYCLOAK);

  // Construct API endpoint URL
  const endpoint = new URL('', API_HEATING_SYSTEM_COLLECTOR_ENDPOINT);

  // Set up authentication link to attach Keycloak token to request headers
  const authLink = setContext(async (_, { headers, ...rest }) => {
    try {
      // Ensure Keycloak token is up-to-date
      await keycloak.updateToken(10);
    } catch (e) {
      // Log any token update errors
      // eslint-disable-next-line no-console -- dont swallow errors
      console.error(e);
    }

    // If token is undefined, throw LoginRequired error
    if (keycloak.token === undefined) {
      throw new LoginRequired();
    }

    // Return headers with Authorization header containing Keycloak token
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return -- apollo-link-context types limitation
    return { headers: { ...headers, Authorization: `Bearer ${keycloak.token}` }, ...rest };
  });

  // Create Apollo Link for handling file uploads
  const uploadLink = createUploadLink({ uri: endpoint.toString() });

  // Combine authentication and upload links into a single HTTP Link
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const httpLink = from([authLink, uploadLink as any]);

  // Set up fragment matcher for Apollo Client cache
  const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });

  // Initialize Apollo Client cache with fragment matcher
  const cache = new InMemoryCache({ fragmentMatcher });

  // Configure scalar types serialization and parsing for Apollo Client
  const scalarsLink = withScalars({
    schema: buildClientSchema(introspection as unknown as IntrospectionQuery),
    typesMap: {
      DateTime: {
        // Serialize Date or Moment to ISO string
        serialize: (parsed: Date | Moment) => parsed.toISOString(),
        // Parse ISO string to Date
        parseValue: (raw: string | null): Date | null => (raw === null ? null : new Date(raw)),
      },
    },
  });

  // Combine HTTP Link and Scalars Link into a single Apollo Link chain
  const link = from([httpLink, scalarsLink]);

  // Create and return Apollo Client instance with combined link and cache
  return new ApolloClient({ link, cache });
});
