import { PropertyFloorType } from '@/types/iot-portal';
import { DomainUiPropertyFloorFragment } from './__generated__/DomainUiPropertyFloorFragment';

export function floorLabel({ level, type, name }: DomainUiPropertyFloorFragment): string {
  if (name !== null) {
    return name;
  }

  if (type === PropertyFloorType.ROOF) {
    return 'Dachgeschoss';
  }

  if (level > 0) {
    return `${level}.\u00a0Obergeschoss`;
  }

  if (level < 0) {
    return `${-level}.\u00a0Untergeschoss`;
  }

  return 'Erdgeschoss';
}

export function floorShortLabel({ level, type, name }: DomainUiPropertyFloorFragment): string {
  if (name !== null) {
    return name;
  }

  if (type === PropertyFloorType.ROOF) {
    return 'DG';
  }

  if (level > 0) {
    return `${level}.\u00a0OG`;
  }

  if (level < 0) {
    return `${-level}.\u00a0UG`;
  }

  return 'EG';
}
