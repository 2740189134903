import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './nuki-box-lg.svg';
import md from './nuki-box-md.svg';
import sm from './nuki-box-sm.svg';
import component from './NukiBoxSpotWidgetControl.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.NUKI_BOX,
  label: 'Nuki Box',
  shortLabel: 'Nuki Box',
  icons: { sm, md, lg },
  component,
}));
