import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import { component } from '../component';
import lg from './gas-meter-lg.svg';
import md from './gas-meter-md.svg';
import sm from './gas-meter-sm.svg';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.GAS_METER,
  label: 'Gaszähler',
  shortLabel: 'Gas',
  icons: { sm, md, lg },
  component: component('gas-meter/GasMeterDevicePanel.vue'),
  aggregationWidget: {
    enabled: true,
    component: component('gas-meter/GasMeterAggregationWidget.vue'),
  },
  defaultMetricNames: ['currentVolume'],
  importantMetricNames: ['currentVolume'],
  aggregationMetricNames: [
    {
      name: 'currentVolume',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
  consumption: true,
}));
