import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import NotAllowedView from '../views/NotAllowedView.vue';

export default option(ROUTES, async () => {
  return [
    {
      path: '/not-allowed',
      name: 'NotAllowed',
      component: NotAllowedView,
    },
  ];
});
