



















import { StringProp } from '@/util/prop-decorators';
import { first, identity, isNumber, nth } from 'lodash';
import { Component, Mixins } from 'vue-property-decorator';
import { MetricPoint } from '../../util/metrics';
import { getMetricUnit, formatMetricName, formatMetricValue, getConverter } from '../../util/metric-formatters';
import {
  MetricTimeFramesMixin,
  timeFrameYesterday,
  TimeFrame,
  timeFrameLastMonth,
  timeFrameLastWeek,
  timeFrameLastYear,
} from '../mixins/metric-time-frames';
import { SpotWidgetDataMixin } from '../spot-widget-control/data-mixin';

interface CounterData {
  label: string;
  point?: MetricPoint;
  previousPoint?: MetricPoint;
}

@Component
export default class SpotWidgetCounterControl extends Mixins(MetricTimeFramesMixin, SpotWidgetDataMixin) {
  @StringProp()
  private readonly metricName?: string;

  private selectedCounterDataIndex = 0;

  protected get spotId(): string {
    return this.spot.id;
  }

  protected get metricTimeFrames(): TimeFrame[] {
    if (this.metricName === undefined) {
      return [];
    }

    return [
      timeFrameLastYear(this.metricName),
      timeFrameLastMonth(this.metricName),
      timeFrameLastWeek(this.metricName),
      timeFrameYesterday(this.metricName),
    ];
  }

  private get counterData(): CounterData[] {
    if (this.metricName === undefined) {
      return [];
    }

    const name =
      this.spot?.metricConfigurations?.find(({ name }) => name === this.metricName)?.label ??
      formatMetricName(this.metricName);
    const unit = getMetricUnit(this.metricName);

    return [
      {
        label: unit === '' ? `${name} Aktuell` : `${name} Aktuell (${unit})`,
        point: this.metrics.continuous[this.metricName],
        previousPoint: undefined,
      },
      ...this.metricTimeFramePoints.map(({ label, points }) => ({
        label: unit === '' ? `${name} ${label}` : `${name} ${label} (${unit})`,
        point: first(points),
        previousPoint: nth(points, 1),
      })),
    ];
  }

  private get selectedCounterData(): CounterData {
    return this.counterData[this.selectedCounterDataIndex];
  }

  private get counterLabel(): string {
    return this.selectedCounterData.label;
  }

  private get counterValue(): number | undefined {
    const value = this.selectedCounterData.point?.value;
    const convert = getConverter(this.selectedCounterData.point?.name ?? '') ?? identity;

    return isNumber(value) ? convert(value) : undefined;
  }

  private get counterDiff(): string | undefined {
    if (this.metricName === undefined || this.selectedCounterDataIndex === 0) {
      return undefined;
    }

    const previousValue = this.selectedCounterData.previousPoint?.value;
    if (this.counterValue === undefined || !isNumber(previousValue)) {
      return '\u00a0';
    }

    const absolute = formatMetricValue(this.metricName, this.counterValue - previousValue);
    const relative = formatMetricValue('relativeChange', this.counterValue / previousValue - 1);

    return `${absolute} (${relative})`;
  }

  private get counterTime(): string | undefined {
    return this.selectedCounterDataIndex === 0 ? this.selectedCounterData.point?.time : undefined;
  }

  private changeSelectedCounterDataIndex(diff: number): void {
    this.selectedCounterDataIndex =
      (this.selectedCounterDataIndex + this.counterData.length + diff) % this.counterData.length;
  }
}
