import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './dual-heating-lg.svg';
import md from './dual-heating-md.svg';
import sm from './dual-heating-sm.svg';
import component from './DualHeatingDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.DUAL_HEATING,
  label: 'Dualer Heizungssensor',
  shortLabel: 'Heizung',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['temperature1'],
  importantMetricNames: ['temperature1', 'temperature2'],
}));
