





import { Component, Mixins } from 'vue-property-decorator';
import { App, appOrderCompare } from '../../model';
import { RootGetter } from '../../store';
import GroupsMixin from '../mixins/groups';
import AppBar from './AppBar.vue';
import AppLink from './AppLink.vue';

@Component({ components: { AppBar, AppLink } })
export default class AppBarControl extends Mixins(GroupsMixin) {
  @RootGetter
  private readonly appMap!: Record<string, App>;

  private get apps(): App[] {
    if (this.groups === undefined) {
      return [];
    }

    const groupRoles = Array.from(new Set(this.groups.flatMap(({ roles }) => roles)));

    return Object.values(this.appMap)
      .filter(({ name }) => groupRoles.includes(name))
      .filter(({ hiddenForApps, shownForApps }) => {
        const appIsShown = groupRoles.find((role) => shownForApps?.includes(role)) !== undefined;

        if (hiddenForApps?.length) {
          const appIsHidden = groupRoles.find((role) => hiddenForApps?.includes(role)) !== undefined;

          if (shownForApps?.length) {
            return !appIsHidden && appIsShown;
          }
          return !appIsHidden;
        }

        if (shownForApps?.length) {
          return appIsShown;
        }

        return true;
      })
      .sort(appOrderCompare);
  }
}
