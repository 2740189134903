import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS } from '@/features/core/container';
import { GroupRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import icon from '../assets/app-home.svg?vue';

export const APP_HOME = GroupRole.APP_HOME;

export default option(APPS, async () => {
  return {
    name: APP_HOME,
    label: 'Home',
    link: {
      to: { name: 'AppHome' },
      icon,
      exact: true,
    },
    hiddenForApps: [APP_EED],
    order: Number.MIN_SAFE_INTEGER,
  };
});
