import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './glasbreak-sensor-lg.svg';
import md from './glasbreak-sensor-md.svg';
import sm from './glasbreak-sensor-sm.svg';
import component from './GlasbreakSensorDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.GLASBREAK_SENSOR,
  label: 'Glasbruch',
  shortLabel: 'Glasbruch',
  icons: { sm, md, lg },
  component,
}));
