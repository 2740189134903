











import { Component, Mixins } from 'vue-property-decorator';
import { KEYCLOAK } from '../../container';
import { IdToken, RootState } from '../../store';
import ContainerMixin from '../mixins/container';
import TopBar from './TopBar.vue';
import UserToolbar from './UserToolbar.vue';

@Component({ components: { TopBar, UserToolbar } })
export default class TopBarControl extends Mixins(ContainerMixin) {
  @RootState
  private readonly idToken?: IdToken;

  private onLogoClick(): void {
    this.$router.push({ name: 'AppHome' });
  }

  private onLogoutClick(): void {
    const keycloak = this.container(KEYCLOAK);
    const redirectUri = keycloak.createLoginUrl();
    keycloak.logout({ redirectUri });
  }
}
