import { get } from 'lodash';
import VueRouter from 'vue-router';
import { App } from '../model';
import { RootStore } from '../store';

export function setupAllowedAppChecker(router: VueRouter, store: RootStore): void {
  router.beforeEach(async (to, from, next) => {
    const app = get(to, 'meta.app', undefined) as string | undefined;
    if (app === undefined) {
      return next();
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access -- vuex limitation
    const appMap = store.getters.appMap as Record<string, App>;
    if (appMap[app]) {
      return next();
    }

    next(false);
  });
}
