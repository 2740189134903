import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './leakage-detector-lg.svg';
import md from './leakage-detector-md.svg';
import sm from './leakage-detector-sm.svg';
import component from './LeakageDetectorDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.LEAKAGE_DETECTOR,
  label: 'Leckagemelder',
  shortLabel: 'Leckage',
  icons: { sm, md, lg },
  component,
  defaultMetricNames: ['leakage'],
  importantMetricNames: ['leakage', 'temperature'],
  aggregationMetricNames: [
    {
      name: 'temperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
