





import { TreeNodeType } from '@/types/iot-portal';
import { ArrayProp, BooleanProp } from '@/util/prop-decorators';
import { Component, Vue } from 'vue-property-decorator';
import { CoreTreeNodeBarControlTreeNodeFragment } from './__generated__/CoreTreeNodeBarControlTreeNodeFragment';
import EmphasizedTreeNode from './EmphasizedTreeNode.vue';
import TreeNode from './TreeNode.vue';

type TreeNodeComponent = typeof EmphasizedTreeNode | typeof TreeNode;

const emphasizedTreeNodeTypes: TreeNodeType[] = [
  TreeNodeType.RootDirectory,
  TreeNodeType.Directory,
  TreeNodeType.PropertyGroup,
];

@Component
export default class TreeNodeList extends Vue {
  @ArrayProp(true)
  private readonly treeNodes!: CoreTreeNodeBarControlTreeNodeFragment[];

  @BooleanProp()
  private readonly indent!: boolean;

  private componentFor({ __typename }: CoreTreeNodeBarControlTreeNodeFragment): TreeNodeComponent {
    return emphasizedTreeNodeTypes.includes(TreeNodeType[__typename]) ? EmphasizedTreeNode : TreeNode;
  }
}
