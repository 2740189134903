

















import DeviceStateIndicator from '@/components/sensor/device-state/DeviceStateIndicator.vue';
import DeviceStateIndicators from '@/components/sensor/device-state/DeviceStateIndicators.vue';
import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import bgImage from './boiler-plant-lg.svg';

@Component({ components: { DeviceStateIndicator, DeviceStateIndicators } })
export default class BoilerPlantDevicePanel extends Mixins(SpotWidgetDataMixin) {
  private backgroundImage = bgImage;
}
