import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { Route } from 'vue-router';
import { component } from '../component';
import { APP_MANAGER } from './app.builder';

const uuidCheck = '([0-9a-fA-F]{8}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{4}\\-[0-9a-fA-F]{12})';

export default option(ROUTES, async () => {
  return [
    {
      path: `/manager/:treeNodeId${uuidCheck}`,
      component: component('views/tree-node/TreeNodeView.vue'),
      props: true,
      meta: { app: APP_MANAGER, key: (route: Route): string => `AppManager/TreeNode?${route.params.treeNodeId}` },
      children: [
        {
          path: `/manager/:treeNodeId${uuidCheck}`,
          name: 'AppManager/TreeNode',
          component: component('views/tree-node/default/DefaultView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
          children: [
            {
              path: `spots/:spotId${uuidCheck}`,
              name: 'AppManager/TreeNode/Spots/Spot',
              component: component('views/tree-node/spot-details/spot/SpotView.vue'),
              props: true,
              meta: { app: APP_MANAGER },
            },
          ],
        },
        {
          path: 'apartments',
          name: 'AppManager/TreeNode/Apartments',
          component: component('views/tree-node/apartments/ApartmentsView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'gateways',
          name: 'AppManager/TreeNode/Gateways',
          component: component('views/tree-node/gateways/GatewaysView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: `gateways/configuration-preset`,
          name: 'AppManager/TreeNode/Gateways/GatewayConfigurationPreset',
          component: component('views/tree-node/gateway-configuration-preset/GatewayConfigurationPresetView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: `gateways/:gatewayId${uuidCheck}/configuration-preset`,
          name: 'AppManager/TreeNode/Gateways/Details/GatewayConfigurationPreset',
          component: component('views/tree-node/gateway-configuration-preset/GatewayConfigurationPresetView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: `gateways/:gatewayId${uuidCheck}`,
          name: 'AppManager/TreeNode/Gateways/Details',
          component: component('views/tree-node/gateway-details/GatewayDetailsView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: `gateways-new/:gatewayId${uuidCheck}`,
          name: 'AppManager/TreeNode/Gateways/NewDetails',
          component: component('views/tree-node/gateway-new-details/GatewayDetailsView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'inventory',
          name: 'AppManager/TreeNode/Inventory',
          component: component('views/tree-node/inventory/InventoryView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'alerts',
          name: 'AppManager/TreeNode/Alerts',
          component: component('views/tree-node/alerts/AlertsView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'properties',
          name: 'AppManager/TreeNode/Properties',
          component: component('views/tree-node/properties/PropertiesView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'spots',
          name: 'AppManager/TreeNode/Spots',
          component: component('views/tree-node/spots/SpotsView'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'ground-plan',
          name: 'AppManager/TreeNode/GroundPlan',
          component: component('views/tree-node/ground-plan/GroundPlanView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'activity-log-entries',
          name: 'AppManager/TreeNode/ActivityLogEntries',
          component: component('views/tree-node/activity-log-entries/ActivityLogEntriesView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'files',
          name: 'AppManager/TreeNode/Files',
          component: component('views/tree-node/files/FilesView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'attributes',
          name: 'AppManager/TreeNode/Attributes',
          component: component('views/tree-node/attributes/AttributesView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
      ],
    },
    {
      path: `/manager/:treeNodeId${uuidCheck}/activity-log-entries/:activityLogEntryId${uuidCheck}`,
      component: component('views/activity-log-entry/ActivityLogEntryView.vue'),
      props: true,
      meta: { app: APP_MANAGER, key: (): string => 'AppManager/ActivityLogEntry' },
      children: [
        {
          path: '',
          name: 'AppManager/ActivityLogEntry',
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppManager/ActivityLogEntry/Attributes', params, replace: true }),
          meta: { app: APP_MANAGER },
        },
        {
          path: 'attributes',
          name: 'AppManager/ActivityLogEntry/Attributes',
          component: component('views/activity-log-entry/attributes/AttributesView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'form-submissions',
          name: 'AppManager/ActivityLogEntry/FormSubmissions',
          component: component('views/activity-log-entry/form-submissions/FormSubmissionsView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
      ],
    },
    {
      path: '/manager',
      component: component('views/app/AppView.vue'),
      meta: { app: APP_MANAGER, key: (): string => 'AppManager' },
      children: [
        {
          path: '/manager',
          name: 'AppManager',
          component: component('views/app/properties/PropertiesView.vue'),
          meta: { app: APP_MANAGER },
          beforeEnter: (to, from, next) => {
            if (from.params && from.params.treeNodeId) {
              next({
                name: 'AppManager/TreeNode',
                params: {
                  treeNodeId: from.params.treeNodeId,
                },
              });
            } else {
              next();
            }
          },
        },
        {
          path: 'apartments',
          name: 'AppManager/Apartments',
          component: component('views/app/apartments/ApartmentsView.vue'),
          meta: { app: APP_MANAGER },
        },
        {
          path: 'gateways',
          name: 'AppManager/Gateways',
          component: component('views/app/gateways/GatewaysView.vue'),
          meta: { app: APP_MANAGER },
        },
        {
          path: 'spots',
          name: 'AppManager/Spots',
          component: component('views/app/spots/SpotsView'),
          props: true,
          meta: { app: APP_MANAGER },
        },
        {
          path: 'alerts',
          name: 'AppManager/Alerts',
          component: component('views/app/alerts/AlertsView.vue'),
          props: true,
          meta: { app: APP_MANAGER },
        },
      ],
    },
  ];
});
