import { ROUTES } from '@/features/core/container';
import { option } from '@/util/container';
import { Route } from 'vue-router';
import { component } from '../component';
import { APP_CUSTOMER } from './app.builder';

export default option(ROUTES, async () => {
  return [
    {
      path: '/customer',
      component: component('views/app/AppView.vue'),
      name: 'AppCustomer',
      meta: { app: APP_CUSTOMER },
    },
    {
      path: '/customer/:customerId',
      component: component('views/customer/CustomerView.vue'),
      props: true,
      meta: { app: APP_CUSTOMER, key: ({ params }: Route): string => `AppCustomer/Customer?${params.customerId}` },
      children: [
        {
          path: '/customer/:customerId',
          name: 'AppCustomer/Customer',
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/Customer/Activities', params, replace: true }),
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'activities',
          name: 'AppCustomer/Customer/Activities',
          component: component('views/customer/activities/ActivitiesView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'attribute-definitions',
          name: 'AppCustomer/Customer/AttributeDefinitions',
          component: component('views/customer/attribute-definitions/AttributeDefinitionsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'audit-logs',
          name: 'AppCustomer/Customer/AuditLogs',
          component: component('views/customer/audit-logs/AuditLogsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'alert-rule-deployments',
          name: 'AppCustomer/Customer/AlertRuleDeployments',
          component: component('views/customer/alert-rule-deployments/AlertRuleDeploymentsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'vendor-apis',
          name: 'AppCustomer/Customer/VendorApis',
          component: component('views/customer/vendor-apis/VendorApisView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'activity-tree-node-bookmarks',
          name: 'AppCustomer/Customer/ActivityTreeNodeBookmarks',
          component: component('views/customer/activity-tree-node-bookmarks/ActivityTreeNodeBookmarksView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'forms',
          name: 'AppCustomer/Customer/Forms',
          component: component('views/customer/forms/FormsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'service-providers',
          name: 'AppCustomer/Customer/ServiceProviders',
          component: component('views/customer/service-providers/ServiceProvidersView.vue'),
          props: true,
          meta: {
            app: APP_CUSTOMER,
          },
        },
      ],
    },
    {
      path: '/customer/activities/:activityId',
      component: component('views/activity/ActivityView.vue'),
      props: true,
      meta: { app: APP_CUSTOMER, key: ({ params }: Route): string => `AppCustomer/Activity?${params.activityId}` },
      children: [
        {
          path: '/customer/activities/:activityId',
          name: 'AppCustomer/Activity',
          component: component('views/activity/general/GeneralView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'activity-pins',
          name: 'AppCustomer/Activity/ActivityPins',
          component: component('views/activity/activity-pins/ActivityPinsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'activity-tree-node-bookmarks',
          name: 'AppCustomer/Activity/ActivityTreeNodeBookmarks',
          component: component('views/activity/activity-tree-node-bookmarks/ActivityTreeNodeBookmarksView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'activity-check-in-schedules',
          name: 'AppCustomer/Activity/ActivityCheckInSchedules',
          component: component('views/activity/activity-check-in-schedules/ActivityCheckInSchedulesView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'activity-forms',
          name: 'AppCustomer/Activity/ActivityForms',
          component: component('views/activity/activity-forms/ActivityFormsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'activity-service-providers',
          name: 'AppCustomer/Activity/ActivityServiceProviders',
          component: component('views/activity/activity-service-providers/ActivityServiceProvidersView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
    {
      path: '/customer/:customerId/alert-rule-deployments/:alertRuleDeploymentId',
      name: 'AppCustomer/AlertRuleDeployment',
      component: component('views/alert-rule-deployment/AlertRuleDeploymentView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/AlertRuleDeployment?${params.alertRuleDeploymentId}`,
      },
    },
    {
      path: '/customer/activity-pins/:activityPinId',
      name: 'AppCustomer/ActivityPin',
      component: component('views/activity-pin/ActivityPinView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/ActivityPin?${params.activityPinId}`,
      },
    },
    {
      path: '/customer/attribute-definitions/:attributeDefinitionId',
      name: 'AppCustomer/AttributeDefinition',
      component: component('views/attribute-definition/AttributeDefinitionView.vue'),
      props: true,
      meta: { app: APP_CUSTOMER, key: (): string => 'AppCustomer/AttributeDefinition' },
    },
    {
      path: '/customer/:customerId/vendor-apis/aufzughelden-apis/:vendorApiId',
      component: component('views/aufzughelden-api/AufzugheldenApiView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/VendorApis/AufzugheldenApi?${params.vendorApiId}`,
      },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/aufzughelden-apis/:vendorApiId',
          name: 'AppCustomer/VendorApis/AufzugheldenApi',
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/AufzugheldenApi/ConnectedDevices', params }),
        },
        {
          path: 'connected-devices',
          name: 'AppCustomer/VendorApis/AufzugheldenApi/ConnectedDevices',
          component: component('views/aufzughelden-api/connected-devices/ConnectedDevicesView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'available-devices',
          name: 'AppCustomer/VendorApis/AufzugheldenApi/AvailableDevices',
          component: component('views/aufzughelden-api/available-devices/AvailableDevicesView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'credentials',
          name: 'AppCustomer/VendorApis/AufzugheldenApi/Credentials',
          component: component('views/aufzughelden-api/credentials/CredentialsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
    {
      path: '/customer/:customerId/vendor-apis/comgy-apis/:vendorApiId',
      component: component('views/comgy-api/ComgyApiView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/VendorApis/ComgyApi?${params.vendorApiId}`,
      },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/comgy-apis/:vendorApiId',
          name: 'AppCustomer/VendorApis/ComgyApi',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/ComgyApi/Credentials', params }),
        },
        {
          path: 'connect-devices',
          name: 'AppCustomer/VendorApis/ComgyApi/ConnectDevices',
          component: component('views/comgy-api/connect-devices/ConnectDevicesView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'credentials',
          name: 'AppCustomer/VendorApis/ComgyApi/Credentials',
          component: component('views/comgy-api/credentials/CredentialsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
    {
      path: '/customer/:customerId/vendor-apis/viessmann-apis/:vendorApiId/auth-callback',
      name: 'AppCustomer/ViessmannAuthCallback',
      component: component('views/viessmann-auth-callback/ViessmannAuthCallbackView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
      },
    },
    {
      path: '/customer/:customerId/vendor-apis/viessmann-apis/:vendorApiId',
      component: component('views/viessmann-api/ViessmannApiView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/VendorApis/ViessmannApi?${params.vendorApiId}`,
      },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/viessmann-apis/:vendorApiId',
          name: 'AppCustomer/VendorApis/ViessmannApi',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/ViessmannApi/AvailableInstallations', params }),
        },
        {
          path: 'connected-installations',
          name: 'AppCustomer/VendorApis/ViessmannApi/ConnectedInstallations',
          component: component('views/viessmann-api/connected-installations/ConnectedInstallationsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'available-installations',
          name: 'AppCustomer/VendorApis/ViessmannApi/AvailableInstallations',
          component: component('views/viessmann-api/available-installations/AvailableInstallationsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'credentials',
          name: 'AppCustomer/VendorApis/ViessmannApi/Credentials',
          component: component('views/viessmann-api/credentials/CredentialsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
    {
      path: '/customer/:customerId/vendor-apis/nuki-apis/:vendorApiId',
      component: component('views/nuki-api/NukiApiView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/VendorApis/NukiApi?${params.vendorApiId}`,
      },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/nuki-apis/:vendorApiId',
          name: 'AppCustomer/VendorApis/NukiApi',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/VendorApis/NukiApi/AvailableSmartlocks', params }),
        },
        {
          path: 'connected-smartlocks',
          name: 'AppCustomer/VendorApis/NukiApi/ConnectedSmartlocks',
          component: component('views/nuki-api/connected-smartlocks/ConnectedSmartlocksView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'available-smartlocks',
          name: 'AppCustomer/VendorApis/NukiApi/AvailableSmartlocks',
          component: component('views/nuki-api/available-smartlocks/AvailableSmartlocksView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'credentials',
          name: 'AppCustomer/VendorApis/NukiApi/Credentials',
          component: component('views/nuki-api/credentials/CredentialsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
    {
      path: '/customer/:customerId/vendor-apis/viessmann-apis/:vendorApiId/connected-installations/:installationId',
      component: component('views/viessmann-connected-installation/ViessmannConnectedInstallationView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/ViessmannConnectedInstallation?${params.installationId}`,
      },
      children: [
        {
          path: '/customer/:customerId/vendor-apis/viessmann-apis/:vendorApiId/connected-installations/:installationId',
          name: 'AppCustomer/ViessmannConnectedInstallation',
          meta: { app: APP_CUSTOMER },
          beforeEnter: ({ params }, from, next) =>
            void next({ name: 'AppCustomer/ViessmannConnectedInstallation/Devices', params }),
        },
        {
          path: 'devices',
          name: 'AppCustomer/ViessmannConnectedInstallation/Devices',
          component: component('views/viessmann-connected-installation/devices/DevicesView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
        {
          path: 'settings',
          name: 'AppCustomer/ViessmannConnectedInstallation/Settings',
          component: component('views/viessmann-connected-installation/settings/SettingsView.vue'),
          props: true,
          meta: { app: APP_CUSTOMER },
        },
      ],
    },
    {
      path: '/customer/activities/:activityId/activity-check-in-schedules/:activityCheckInScheduleId',
      name: 'AppCustomer/Activity/ActivityCheckInSchedule',
      component: component('views/activity-check-in-schedule/ActivityCheckInScheduleView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string =>
          `AppCustomer/Activity/ActivityCheckInSchedule?${params.activityCheckInScheduleId}`,
      },
    },
    {
      path: '/customer/:customerId/forms/:formId',
      name: 'AppCustomer/Form',
      component: component('views/form/FormView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/Form?${params.formId}`,
      },
    },
    {
      path: '/customer/:customerId/service-providers/:serviceProviderId',
      name: 'AppCustomer/ServiceProvider',
      component: component('views/service-provider/ServiceProviderView.vue'),
      props: true,
      meta: {
        app: APP_CUSTOMER,
        key: ({ params }: Route): string => `AppCustomer/ServiceProvider?${params.serviceProviderId}`,
      },
    },
  ];
});
