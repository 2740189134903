import { API_ENDPOINT } from '@/env';
import introspectionQueryResultData from '@/types/portal-api-fragment-matcher.generated.json';
import introspection from '@/types/portal-api.generated.json';
import { provide } from '@/util/container';
import { defaultDataIdFromObject, InMemoryCache, IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import { ApolloClient } from 'apollo-client';
import { from, split } from 'apollo-link';
import { setContext } from 'apollo-link-context';
import { withScalars } from 'apollo-link-scalars';
import { WebSocketLink } from 'apollo-link-ws';
import { createUploadLink } from 'apollo-upload-client';
import { buildClientSchema, IntrospectionQuery, OperationDefinitionNode } from 'graphql';
import { Moment } from 'moment';
import { API_CLIENT, KEYCLOAK } from '.';
import { LoginRequired } from '../model';

export default provide(API_CLIENT, async (get) => {
  const keycloak = await get(KEYCLOAK);

  const endpoint = new URL(API_ENDPOINT, window.location.href);
  endpoint.username = '';
  endpoint.password = '';

  // link(-> scalars -> split(-> ws, -> http(-> auth -> upload)))

  const authLink = setContext(async (_, { headers, ...rest }) => {
    try {
      await keycloak.updateToken(10);
    } catch (e) {
      // eslint-disable-next-line no-console -- dont swallow errors
      console.error(e);
    }

    if (keycloak.token === undefined) {
      throw new LoginRequired();
    }

    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment, @typescript-eslint/no-unsafe-return -- apollo-link-context types limitation
    return { headers: { ...headers, Authorization: `Bearer ${keycloak.token}` }, ...rest };
  });
  const uploadLink = createUploadLink({ uri: endpoint.toString() });
  // eslint-disable-next-line @typescript-eslint/no-explicit-any -- apollo-upload-client uses apollo v3 types already
  const httpLink = from([authLink, uploadLink as any]);

  const subscriptionEndpoint = new URL(endpoint.toString());
  subscriptionEndpoint.protocol = endpoint.protocol === 'https:' ? 'wss:' : 'ws:';

  const wsLink = new WebSocketLink({
    uri: subscriptionEndpoint.toString(),
    options: {
      lazy: true,
      reconnect: true,
      connectionParams: async () => {
        try {
          await keycloak.updateToken(10);
        } catch (e) {
          // eslint-disable-next-line no-console -- dont swallow errors
          console.error(e);
        }

        if (keycloak.token === undefined) {
          throw new LoginRequired();
        }

        return { authorization: `Bearer ${keycloak.token}` };
      },
    },
  });

  const splitLink = split(
    // split operations of kind "subscription" to ws link
    ({ query }) => {
      return (
        query.definitions.find(
          (definition): definition is OperationDefinitionNode => definition.kind === 'OperationDefinition',
        )?.operation === 'subscription'
      );
    },
    wsLink,
    httpLink,
  );

  const fragmentMatcher = new IntrospectionFragmentMatcher({ introspectionQueryResultData });
  //dataIdFromObject is deprecated in Apollo Client 3 by the friendlier 'typePolicies'
  const cache = new InMemoryCache({
    fragmentMatcher,
    // eslint-disable-next-line complexity
    dataIdFromObject: (responseObject) => {
      switch (responseObject.__typename) {
        case 'GroundPlanPositionedSpotPlacement':
        case 'GroundPlanUnpositionedSpotPlacement':
          return responseObject.id === undefined ? undefined : `GroundPlanSpotPlacement:${responseObject.id}`;

        case 'AufzugheldenConnectedDevice':
        case 'AufzugheldenConnectableDevice': {
          const { deviceId } = responseObject as { deviceId?: string };
          return deviceId === undefined ? undefined : `AufzugheldenDevice:${deviceId}`;
        }
        case 'AufzugheldenUnconnectableDevice':
          return undefined;

        case 'ViessmannConnectedInstallation':
        case 'ViessmannConnectableInstallation':
          return responseObject.id === undefined ? undefined : `ViessmannInstallation:${responseObject.id}`;
        case 'ViessmannUnconnectableInstallation':
          return undefined;

        case 'ViessmannConnectedDevice':
        case 'ViessmannConnectableDevice': {
          return responseObject.id === undefined ? undefined : `ViessmannDevice:${responseObject.id}`;
        }

        case 'NukiConnectedSmartlock':
        case 'NukiConnectableSmartlock': {
          return responseObject.id === undefined ? undefined : `NukiSmartlock:${responseObject.id}`;
        }
        case 'NukiUnconnectableSmartlock':
          return undefined;

        case 'EnerIqConnectedEconomicUnit':
        case 'EnerIqConnectableEconomicUnit': {
          return responseObject.id === undefined ? undefined : `EnerIqEconomicUnit:${responseObject.id}`;
        }
        case 'ActivityTreeNodeBookmark': {
          const { token } = responseObject as { token?: string };
          return token === undefined ? undefined : `ActivityTreeNodeBookmark:${token}`;
        }

        default:
          return defaultDataIdFromObject(responseObject);
      }
    },
  });

  const scalarsLink = withScalars({
    schema: buildClientSchema(introspection as unknown as IntrospectionQuery),
    typesMap: {
      DateTime: {
        serialize: (parsed: Date | Moment) => parsed.toISOString(),
        parseValue: (raw: string | null): Date | null => (raw === null ? null : new Date(raw)),
      },
    },
  });

  const link = from([scalarsLink, splitLink]);

  return new ApolloClient({ link, cache });
});
