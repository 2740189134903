import { ObjectProp } from '@/util/prop-decorators';
import { Component, Mixins } from 'vue-property-decorator';
import { DeviceRole } from '../../model';
import { MetricsMixin } from '../../util/metrics';
import { CoreSpotWidgetControlSpotFragment } from './__generated__/CoreSpotWidgetControlSpotFragment';

@Component
export class SpotWidgetDataMixin extends Mixins(MetricsMixin) {
  @ObjectProp(true)
  protected readonly spot!: CoreSpotWidgetControlSpotFragment;

  @ObjectProp(true)
  protected readonly role!: DeviceRole;
}
