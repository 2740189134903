import { APP_EED } from '@/features/app-eed/container/app.builder';
import { APPS, ROUTER } from '@/features/core/container';
import { GroupRole, TreeNodeType } from '@/types/iot-portal';
import { option } from '@/util/container';
import { omit } from 'lodash';
import icon from '../assets/app-manager.svg?vue';

export const APP_MANAGER = GroupRole.APP_MANAGER;
const nodeRouteRegExp = /^AppManager\/TreeNode\/[^/]+/;

export default option(APPS, async (get) => {
  const router = await get(ROUTER);
  return {
    name: APP_MANAGER,
    label: 'Gebäude- & Gerätemanager',
    link: {
      to: { name: 'AppManager' },
      icon,
    },
    types: [
      TreeNodeType.RootDirectory,
      TreeNodeType.Directory,
      TreeNodeType.PropertyGroup,
      TreeNodeType.Property,
      TreeNodeType.PropertySubdivision,
    ],
    generateTreeNodeLocation({ id }, params) {
      const currentName = router.currentRoute.name ?? '';
      const [name] = nodeRouteRegExp.exec(currentName) ?? ['AppManager/TreeNode'];
      const query =
        name !== 'AppManager/TreeNode' && currentName === name ? omit(router.currentRoute.query, 'page') : {};

      return { name, params: { ...params, treeNodeId: id }, query };
    },
    getActiveTreeNodeId({ params }) {
      return params.treeNodeId;
    },
    hiddenForApps: [APP_EED],
    order: 1,
  };
});
