






























import DeviceRoleMapMixin from '@/features/core/components/mixins/device-role-map';
import { MetricResolutionAggregator } from '@/types/iot-portal';
import { Component, Mixins } from 'vue-property-decorator';
import { DateProp, EnumProp, StringProp, ArrayProp } from '@/util/prop-decorators';

@Component
export default class SpotMetricsPanelControl extends Mixins(DeviceRoleMapMixin) {
  @ArrayProp(() => [])
  private selectedMetrics!: string[];

  @ArrayProp(() => [])
  private availableOnGraphMetrics!: string[];

  @ArrayProp(() => [])
  private readonly selectableMetrics!: string[];

  @DateProp()
  private readonly startDate?: Date;

  @DateProp()
  private readonly stopDate?: Date;

  @StringProp()
  private readonly spotId!: string;

  @StringProp()
  private readonly aggregationInterval?: Duration;

  @EnumProp(false, ...Object.keys(MetricResolutionAggregator))
  private readonly aggregator?: MetricResolutionAggregator;

  private onUpdateStartDate(startDate: Date): void {
    this.$emit('update:start-date', startDate);
  }

  private onUpdateStopDate(stopDate: Date): void {
    this.$emit('update:stop-date', stopDate);
  }

  private onUpdateAggregator(aggregator: MetricResolutionAggregator): void {
    this.$emit('update:aggregator', aggregator);
  }

  private onUpdateAggregationInterval(aggregationInterval: Duration): void {
    this.$emit('update:aggregation-interval', aggregationInterval);
  }

  private onUpdateSelectedMetrics(selectedMetrics: string[]): void {
    this.$emit('update:selected-metrics', selectedMetrics);
  }

  private onUpdateAvailableOnGraphMetrics(availableOnGraphMetrics: string[]): void {
    this.$emit('update:available-on-graph-metrics', availableOnGraphMetrics);
  }
}
