import { DEVICE_ROLES } from '@/features/core/container';
import { SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './nuki-smartlock-lg.svg';
import md from './nuki-smartlock-md.svg';
import sm from './nuki-smartlock-sm.svg';
import component from './NukiSmartlockSpotWidgetControl.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.NUKI_SMARTLOCK,
  label: 'Nuki-Schließanlage',
  shortLabel: 'Schließanlage',
  icons: { sm, md, lg },
  component,
}));
