



























import { Action, RootAction } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';
import { TreeNodeType } from '@/types/iot-portal';
import { BooleanProp } from '@/util/prop-decorators';
import { Stat } from '@he-tree/tree-utils';
import '@he-tree/vue/style/default.css';
import { dragContext, Draggable } from '@he-tree/vue/vue2';
import { ApolloQueryResult } from 'apollo-client';
import { differenceWith, flatten, groupBy, uniqBy } from 'lodash';
import { NIL } from 'uuid';
import { Component, Inject, Vue } from 'vue-property-decorator';
import {
  TreeNodeListRootsRefetch,
  TREE_NODE_LIST_ROOTS_REFETCH,
} from '../../../core/components/tree-node-bar-control/model';
import addTreeNode from './add-tree-node.gql';
import editTreeNode from './edit-tree-node.gql';
import EditTreeNodeWizardControl from './EditTreeNodeWizardControl.vue';
import { SidebarTreeNode, TreeNode } from './model';
import NodeMenu from './NodeMenu.vue';
import removeFromTreeNodeGroup from './remove-from-tree-node-group.gql';
import moveTreeNodeGroup from './move-tree-node-group.gql';
import moveToTreeNodeGroup from './move-to-tree-node-group.gql';
import rootsQuery from './roots.gql';
import {
  AppSmartDeviceTreeNodeBarAddTreeNodeMutation,
  AppSmartDeviceTreeNodeBarAddTreeNodeMutationVariables,
} from './__generated__/AppSmartDeviceTreeNodeBarAddTreeNodeMutation';
import {
  AppSmartDeviceTreeNodeBarEditTreeNodeMutation,
  AppSmartDeviceTreeNodeBarEditTreeNodeMutationVariables,
} from './__generated__/AppSmartDeviceTreeNodeBarEditTreeNodeMutation';
import {
  AppSmartDeviceTreeNodeBarMoveToTreeNodeGroupMutation,
  AppSmartDeviceTreeNodeBarMoveToTreeNodeGroupMutationVariables,
} from './__generated__/AppSmartDeviceTreeNodeBarMoveToTreeNodeGroupMutation';
import {
  AppSmartDeviceTreeNodeBarMoveTreeNodeGroupMutation,
  AppSmartDeviceTreeNodeBarMoveTreeNodeGroupMutationVariables,
} from './__generated__/AppSmartDeviceTreeNodeBarMoveTreeNodeGroupMutation';
import {
  AppSmartDeviceTreeNodeBarRemoveFromTreeNodeGroupMutation,
  AppSmartDeviceTreeNodeBarRemoveFromTreeNodeGroupMutationVariables,
} from './__generated__/AppSmartDeviceTreeNodeBarRemoveFromTreeNodeGroupMutation';
import {
  AppSmartDeviceTreeNodeBarRootsQuery,
  AppSmartDeviceTreeNodeBarRootsQueryVariables,
} from './__generated__/AppSmartDeviceTreeNodeBarRootsQuery';
import { AppSmartDeviceTreeNodeBarSpotFragment } from './__generated__/AppSmartDeviceTreeNodeBarSpotFragment';

@Component({
  components: { Draggable, NodeMenu, EditTreeNodeWizardControl },
  apollo: {
    view: {
      query: rootsQuery,
      fetchPolicy: 'no-cache',
      variables(this: TreeNodeList): AppSmartDeviceTreeNodeBarRootsQueryVariables {
        return { take: 1000 };
      },
      manual: true,
      result(this: TreeNodeList, { data }: ApolloQueryResult<AppSmartDeviceTreeNodeBarRootsQuery>): void {
        this.rootTreeNodes = data.treeNodes.items;
      },
    },
  },
  data() {
    return { rootTreeNodes: [], selectedTreeNode: undefined };
  },
})
export default class TreeNodeList extends Vue {
  @BooleanProp()
  private readonly indent!: boolean;

  @Inject(TREE_NODE_LIST_ROOTS_REFETCH)
  private treeNodeListRootsRefetch!: TreeNodeListRootsRefetch;

  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  private rootTreeNodes!: AppSmartDeviceTreeNodeBarRootsQuery['treeNodes']['items'];

  private selectedTreeNode?: SidebarTreeNode;

  private async onItemClick(params: { index: number; node: SidebarTreeNode; item: string }): Promise<void> {
    switch (params.item) {
      case 'Add Group':
        //add
        await this.addTreeNodeGroup(params.node, 'Group', 0);
        await this.treeNodeListRootsRefetch();
        break;
      case 'Edit':
        //edit tree node group
        this.selectedTreeNode = params.node;
        break;
      default:
        break;
    }
  }

  private async onSelectedTreeNodeEdit(): Promise<void> {
    this.selectedTreeNode = undefined;
    await this.treeNodeListRootsRefetch();
  }

  private async afterChange(): Promise<void> {
    const sourceNode = dragContext.targetInfo.dragNode as Stat<SidebarTreeNode>;
    const targetNode = dragContext.targetInfo.parent as Stat<SidebarTreeNode>;

    if (sourceNode.data.rootParentId !== targetNode.data.rootParentId) {
      this.ADD_TOAST_MESSAGES({
        messages: [{ text: 'Node cannot be moved to another customer', class: 'error' }],
      });
      await this.treeNodeListRootsRefetch();
      throw new Error('Node cannot be moved to another customer');
    }

    //mutate order
    const orderedNodes = targetNode.children.map(({ data }, index: number) => ({
      ...data,
      order: index,
      parentId: targetNode.data.id,
    }));

    await Promise.all(orderedNodes.map(this.editTreeNodeOrder));

    //mutate source to target
    if (sourceNode.data.__typename === 'Spot' && targetNode.data.__typename === 'RootDirectory') {
      // remove spot from group and leave it on root
      await this.$apollo.mutate<
        AppSmartDeviceTreeNodeBarRemoveFromTreeNodeGroupMutation,
        AppSmartDeviceTreeNodeBarRemoveFromTreeNodeGroupMutationVariables
      >({
        mutation: removeFromTreeNodeGroup,
        variables: {
          input: {
            id: sourceNode.data.id,
          },
        },
      });
    }

    if (sourceNode.data.__typename === 'Spot' && targetNode.data.__typename === 'TreeNodeGroup') {
      // move spot to tree node group
      await this.$apollo.mutate<
        AppSmartDeviceTreeNodeBarMoveToTreeNodeGroupMutation,
        AppSmartDeviceTreeNodeBarMoveToTreeNodeGroupMutationVariables
      >({
        mutation: moveToTreeNodeGroup,
        variables: {
          input: {
            id: sourceNode.data.id,
            treeNodeGroupId: targetNode.data.id,
          },
        },
      });
    }

    if (
      sourceNode.data.__typename === 'TreeNodeGroup' &&
      (targetNode.data.__typename === 'TreeNodeGroup' || targetNode.data.__typename === 'RootDirectory')
    ) {
      // move tree node group to tree node group or root directory
      await this.$apollo.mutate<
        AppSmartDeviceTreeNodeBarMoveTreeNodeGroupMutation,
        AppSmartDeviceTreeNodeBarMoveTreeNodeGroupMutationVariables
      >({
        mutation: moveTreeNodeGroup,
        variables: {
          input: {
            id: sourceNode.data.id,
            parentId: targetNode.data.id,
          },
        },
      });
    }

    this.treeNodeListRootsRefetch();
  }

  private async addTreeNodeGroup(node: SidebarTreeNode, name: string, order: number): Promise<void> {
    await this.$apollo.mutate<
      AppSmartDeviceTreeNodeBarAddTreeNodeMutation,
      AppSmartDeviceTreeNodeBarAddTreeNodeMutationVariables
    >({
      mutation: addTreeNode,
      variables: { input: { parentId: node.id, treeNode: { type: TreeNodeType.TreeNodeGroup, name, order } } },
    });
  }

  private getNodeItems({ __typename }: SidebarTreeNode): string[] {
    if (__typename === 'TreeNodeGroup') {
      return ['Edit'];
    }

    if (__typename === 'RootDirectory') {
      return ['Add Group'];
    }

    return [];
  }

  private async editTreeNodeOrder({ id, order, name }: SidebarTreeNode): Promise<void> {
    await this.$apollo.mutate<
      AppSmartDeviceTreeNodeBarEditTreeNodeMutation,
      AppSmartDeviceTreeNodeBarEditTreeNodeMutationVariables
    >({
      mutation: editTreeNode,
      variables: { input: { id, order, name } },
    });
  }

  private isDraggable(node: Stat<SidebarTreeNode>): boolean {
    if (node.data.__typename === 'Spot' || node.data.__typename === 'TreeNodeGroup') {
      return true;
    }

    return false;
  }

  private isDroppable(node: Stat<SidebarTreeNode>): boolean {
    return (node.data.__typename === 'TreeNodeGroup' || node.data.__typename === 'RootDirectory') && node.level <= 3;
  }

  private get rootSpotsMap(): Map<string, AppSmartDeviceTreeNodeBarSpotFragment[]> {
    const spots = [] as AppSmartDeviceTreeNodeBarSpotFragment[];

    for (const treeNode of this.rootTreeNodes) {
      if (treeNode.__typename === 'RootDirectory') {
        spots.push(...treeNode.spots.items);
      }
    }

    const uniqueSpots = uniqBy(spots, ({ id }) => id);

    const spotsWithGroup = uniqBy(flatten(Array.from(this.spotsWithGroupMap.values())), ({ id }) => id);

    const diffSpots = differenceWith(uniqueSpots, spotsWithGroup, (a, b) => a.id === b.id);

    const grouppedSpots = groupBy(diffSpots, ({ path }) => path.items[0].id);

    return new Map(Object.entries(grouppedSpots).map(([id, spots]) => [id, spots]));
  }

  private get spotsWithGroupMap(): Map<string, AppSmartDeviceTreeNodeBarSpotFragment[]> {
    const spots = [] as AppSmartDeviceTreeNodeBarSpotFragment[];

    for (const rootTreeNode of this.rootTreeNodes) {
      if (rootTreeNode.__typename === 'RootDirectory' && rootTreeNode.spots.items.length) {
        spots.push(...rootTreeNode.spots.items);
      }
    }

    const groupedSpots = spots.filter(({ treeNodeGroup }) => treeNodeGroup !== null);

    const uniqueSpots = uniqBy(groupedSpots, ({ id }) => id);

    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    const grouppedSpots = groupBy(uniqueSpots, ({ treeNodeGroup }) => treeNodeGroup!.id);

    return new Map(Object.entries(grouppedSpots).map(([id, spots]) => [id, spots]));
  }

  private get mappedTreeNodes(): SidebarTreeNode[] {
    return this.rootTreeNodes.map(this.mapTreeNode);
  }

  private mapTreeNode(treeNode: TreeNode): SidebarTreeNode {
    const children = [] as SidebarTreeNode[];

    if (treeNode.__typename === 'RootDirectory') {
      const spots =
        this.rootSpotsMap.get(treeNode.id)?.map(({ __typename, id, name, order, path, parent }) => ({
          __typename,
          id,
          name,
          order,
          parentId: parent?.id ?? NIL,
          rootParentId: path.items[0].id,
        })) ?? [];

      children.push(...spots);
    }

    if (treeNode.__typename === 'TreeNodeGroup') {
      const spots =
        this.spotsWithGroupMap.get(treeNode.id)?.map(({ __typename, id, name, order, path, parent }) => ({
          __typename,
          id,
          name,
          order,
          parentId: parent?.id ?? NIL,
          rootParentId: path.items[0].id,
        })) ?? [];

      children.push(...spots);
    }

    if (treeNode.children?.items) {
      const treeNodeChildren = treeNode.children.items.map((child) => this.mapTreeNode(child as TreeNode));

      children.push(...treeNodeChildren);
    }

    return {
      __typename: treeNode.__typename,
      id: treeNode.id,
      name: treeNode.name,
      order: treeNode.order,
      children,
      parentId: treeNode.parent?.id ?? NIL,
      rootParentId: treeNode.path?.items[0]?.id,
    };
  }
}
