


import { Vue, Component, Watch } from 'vue-property-decorator';
import { ArrayProp, BooleanProp, StringProp, NumberProp } from '@/util/prop-decorators';
import { RootAction, Action } from '@/features/core/store';
import { AddToastMessageParams } from '@/features/core/store/toast';

@Component
export default class Toast extends Vue {
  @RootAction
  private readonly ADD_TOAST_MESSAGES!: Action<AddToastMessageParams, void>;

  @StringProp()
  private readonly headline?: string;

  @StringProp()
  private readonly message?: string;

  @ArrayProp(() => [])
  private readonly messages!: string[];

  @BooleanProp()
  private readonly error!: boolean;

  @BooleanProp()
  private readonly warning!: boolean;

  @BooleanProp()
  private readonly success!: boolean;

  @NumberProp(5000)
  private duration!: number;

  private get normalizedMessages(): string[] {
    return this.message === undefined ? this.messages : [this.message, ...this.messages];
  }

  private mounted(): void {
    this.setupMessages();
  }

  @Watch('message')
  @Watch('messages')
  private setupMessages(): void {
    const messages = this.normalizedMessages.map((message) => ({
      text: message,
      headline: this.headline,
      class: this.error ? 'error' : this.warning ? 'warning' : this.success ? 'success' : 'info',
    }));
    this.ADD_TOAST_MESSAGES({ messages, duration: this.duration });
  }
}
