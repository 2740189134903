




























import TreeNodeLocationGeneratorMixin from '@/features/core/components/mixins/tree-node-location-generator';
import { isString } from '@/util/lang';
import { Component, Mixins } from 'vue-property-decorator';
import { AppSupportAppViewQuery, AppSupportAppViewQueryVariables } from './__generated__/AppSupportAppViewQuery';
import query from './view.gql';

@Component({
  apollo: {
    searchTreeNodes: {
      query,
      skip(this: AppView): boolean {
        return this.searchQuery === '';
      },
      variables(this: AppView): AppSupportAppViewQueryVariables {
        return { searchQuery: this.searchQuery };
      },
    },
  },
})
export default class AppView extends Mixins(TreeNodeLocationGeneratorMixin) {
  private readonly searchTreeNodes?: AppSupportAppViewQuery['searchTreeNodes'];

  private get searchQuery(): string {
    return isString(this.$route.query.q) ? this.$route.query.q : '';
  }

  private set searchQuery(value: string) {
    this.$router.replace({ query: { q: value === '' ? undefined : value } });
  }

  private get items(): AppSupportAppViewQuery['searchTreeNodes']['items'] {
    return this.searchTreeNodes?.items ?? [];
  }
}
