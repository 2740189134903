









import { Component, Mixins } from 'vue-property-decorator';
import TreeNodeBarMixin from './TreeNodeBarMixin';

@Component
export default class TreeNodeBar extends Mixins(TreeNodeBarMixin) {
  private onSearch(query: string): void {
    (query.length >= 3 || query.length === 0) && this.$emit('update:searchQuery', query);
  }
}
