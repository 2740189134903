



















import { SpotWidgetDataMixin } from '@/features/core/components/spot-widget-control/data-mixin';
import { Component, Mixins } from 'vue-property-decorator';
import doorLockedImage from './door-contact-lg-locked.svg';
import doorUnlockedImage from './door-contact-lg-unlocked.svg';
import doorOpenImage from './door-contact-lg-open.svg';

@Component
export default class DoorContactDevicePanelAdapter extends Mixins(SpotWidgetDataMixin) {
  private get backgroundImage(): string {
    if (this.metrics.discrete.opened?.value !== '1') {
      return this.metrics.discrete.opened2?.value !== '1' ? doorLockedImage : doorUnlockedImage;
    }

    return doorOpenImage;
  }
}
