import { DEVICE_ROLES } from '@/features/core/container';
import { MetricResolutionAggregator, MetricResolutionTimeSource, SpotRole } from '@/types/iot-portal';
import { option } from '@/util/container';
import lg from './boiler-plant-lg.svg';
import md from './boiler-plant-md.svg';
import sm from './boiler-plant-sm.svg';
import component from './BoilerPlantDevicePanel.vue';

export default option(DEVICE_ROLES, async () => ({
  name: SpotRole.BOILER_PLANT,
  label: 'Boiler',
  shortLabel: 'Boiler',
  icons: { sm, md, lg },
  component,
  importantMetricNames: ['meanCirculationTemperature', 'meanReservoirTemperature'],
  aggregationMetricNames: [
    {
      name: 'meanCirculationTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
    {
      name: 'meanReservoirTemperature',
      aggregators: [MetricResolutionAggregator.MEAN],
      timeSource: MetricResolutionTimeSource.START,
    },
  ],
}));
