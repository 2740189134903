import { builder } from '@/util/container';
import Vue from 'vue';
import VueApollo from 'vue-apollo';
import {
  API_CLIENT,
  API_HEATING_SYSTEM_COLLECTOR_CLIENT,
  CONSUMPTION_API_REST_CLIENT,
  CONTAINER,
  GENERIC_NOTIFICATION_API_REST_CLIENT,
  ROOT_COMPONENT,
  ROUTER,
  STORE,
  VUE,
  XLS_IMPORTER,
  OBJECT_LIST_MAPPER,
} from '.';

import App from '../components/App.vue';
import { APOLLO_CLIENT } from './model';

export default builder(({ provide }) => {
  provide(VUE, async () => Vue);

  provide(ROOT_COMPONENT, async (get) => {
    const vue = await get(VUE);
    const router = await get(ROUTER);
    const store = await get(STORE);

    vue.use(VueApollo);

    const PORTAL_CLIENT = await get(API_CLIENT);
    const HEATING_SYSTEM_COLLECTOR_CLIENT = await get(API_HEATING_SYSTEM_COLLECTOR_CLIENT);
    const consumptionApiRestClient = await get(CONSUMPTION_API_REST_CLIENT);
    const genericNotificationApiRestClient = await get(GENERIC_NOTIFICATION_API_REST_CLIENT);

    const clients = {
      [APOLLO_CLIENT.PORTAL_CLIENT]: PORTAL_CLIENT,
      [APOLLO_CLIENT.HEATING_SYSTEM_COLLECTOR_CLIENT]: HEATING_SYSTEM_COLLECTOR_CLIENT,
    };

    const xlsxImporter = await get(XLS_IMPORTER);
    const objectListMapper = await get(OBJECT_LIST_MAPPER);

    const apolloProvider = new VueApollo({
      defaultClient: PORTAL_CLIENT,
      clients,
    });
    return vue.extend({
      name: 'Root',
      props: { container: { required: true } },
      router,
      store,
      apolloProvider,
      provide() {
        return {
          [CONTAINER]: this.container,
          [CONSUMPTION_API_REST_CLIENT]: consumptionApiRestClient,
          [GENERIC_NOTIFICATION_API_REST_CLIENT]: genericNotificationApiRestClient,
          GENERIC_NOTIFICATION_API_REST_CLIENT: genericNotificationApiRestClient,
          xlsxImporter: xlsxImporter,
          objectListMapper: objectListMapper,
        };
      },
      render(h) {
        return h(App);
      },
    });
  });
});
